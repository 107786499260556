import { ChartActionID, ChartActionType } from "shared/types";

import Checkbox from "features/ui/Checkbox";
import DropdownSelect from "features/ui/DropdownSelect";
import { Option, SelectOption } from "features/ui/Select";

import { ChartActionAttributeNameValue } from "./ChartActionAttributeNameValue";
import { getSelectedChartActionOptionName } from "./utils";

export interface ChartActionOption<OptionId = Option>
  extends SelectOption<OptionId> {
  valueFormatter?: (val: any) => {};
  tooltipValueFormatter?: (val: any) => {};
}

interface SecondaryChartOptions {
  id: string;
  values: string[];
}

export interface ChartAction<OptionId = Option> {
  id: ChartActionID;
  title: string;
  type: ChartActionType;
  options?: ChartActionOption<OptionId>[];
  secondaryOptions?: SecondaryChartOptions[];
  defaultOptionId?: string;
  onUpdate?: (selectedName: string, selectedValues: string[]) => void;
}

export interface SelectedChartOptions<OptionId = Option> {
  id: string;
  optionId: OptionId;
}

interface Props {
  title?: string;
  actions: ChartAction[];
  selectedOptions: SelectedChartOptions[];
  onOptionChange: (selectedOptions: SelectedChartOptions[]) => void;
}

export const TITLE = "Chart Settings";

const ChartActions = ({
  title = TITLE,
  actions,
  selectedOptions,
  onOptionChange,
}: Props) => {
  const updateSelectedDropdownOptions = (
    id: string,
    { id: selectedOptionId }: SelectOption
  ) => {
    const newSelectedActions = selectedOptions.map((obj) =>
      obj.id === id ? { id, optionId: selectedOptionId as string } : obj
    );
    onOptionChange(newSelectedActions);
  };

  const updateSelectedBooleanOptions = (id: string, checked: boolean) => {
    const newSelectedActions = selectedOptions.map((obj) =>
      obj.id === id ? { id, optionId: checked ? "true" : "false" } : obj
    );
    onOptionChange(newSelectedActions);
  };

  return (
    <>
      {title && (
        <div className="mb-4 leading-none" data-testid="chart-actions-title">
          {title}
        </div>
      )}
      <div className="space-y-2">
        {actions.map((action) => {
          const {
            id,
            title: optionTitle,
            options,
            secondaryOptions,
            type,
            onUpdate,
          } = action;
          const selectedActionName = getSelectedChartActionOptionName(
            selectedOptions,
            action
          );

          if (type === "dropdownSelect") {
            return (
              <div
                key={id + optionTitle}
                className="flex items-center space-x-2 space-between"
              >
                <span className="flex-1 text-nowrap">{optionTitle}:</span>
                <DropdownSelect
                  label={
                    selectedActionName
                      ? selectedActionName.toString()
                      : undefined
                  }
                  options={options}
                  onSelect={(selectedOption) =>
                    updateSelectedDropdownOptions(id, selectedOption)
                  }
                  testId={`chart-action-dropdown-${id}`}
                />
              </div>
            );
          }
          if (type === "label") {
            return (
              <div
                className="text-sm text-gray-400"
                data-testid={`chart-action-label-${id}`}
                key={id + optionTitle}
              >
                {optionTitle}
              </div>
            );
          }
          if (type === "attrNameValue") {
            return (
              <ChartActionAttributeNameValue
                attributeNames={options ? options : []}
                attributeValues={secondaryOptions ? secondaryOptions : []}
                key={optionTitle}
                onUpdate={onUpdate}
              />
            );
          }
          if (type === "boolean") {
            return (
              <Checkbox
                checked={
                  selectedOptions?.find((o) => o.id === id)?.optionId === "true"
                }
                onChange={(checked) =>
                  updateSelectedBooleanOptions(id, checked)
                }
                label={optionTitle}
              />
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default ChartActions;
