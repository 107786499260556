import { useGetIssueRepairEfficacyReoccurrenceRateByClaimAttribute } from "shared/api/issues/hooks";
import { useCustomLocalStorageState } from "shared/hooks";
import {
  IssueChart,
  IssueChartGroupBy,
  IssueChartType,
  IssueTypes,
  ReoccurrenceProps,
} from "shared/types";

import GroupByChart from "pages/Issues/Charts/GroupByChart";
import { getChartOptionsKey, getPopulationString } from "pages/Issues/utils";

import Card from "features/ui/Card";
import ChartActions, {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import {
  getAxisValue,
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";

interface Props extends ReoccurrenceProps {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
  actions: ChartAction[];
}

const AT_RISK_TITLE = `Signal Reoccurrence : ${getPopulationString("atRisk")}`;
const COMPARISON_TITLE = `Signal Reoccurrence : ${getPopulationString(
  "comparison"
)}`;

const CHART_NAME: IssueChart = "RepairEfficacy_ReoccurrenceByAttribute";
const CHART_TYPE: IssueChartType = "DaysSinceClaim";
const GROUP_BY_FIELD: IssueChartGroupBy = "groupByAttributeValue";

const ReoccurrenceByAttributeChart = ({
  issue,
  withComparisonPopulation,
  actions,
  seOccurrenceWithin,
  seReoccurrenceStartingFrom,
  seReoccurrenceUpTo,
}: Props) => {
  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID);
  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const groupByValue = getAxisValue(
    actions,
    "legend",
    getSelectedOptionId(selectedOptions, "legend")
  );

  const dataLoadHookParams = {
    signalEventsTimeWindow: seOccurrenceWithin,
    signalEventsReoccurrenceTimeWindowFrom: seReoccurrenceStartingFrom,
    signalEventsReoccurrenceTimeWindowTo: seReoccurrenceUpTo,
  };

  const commonChartProps = {
    issue,
    chartType: CHART_TYPE,
    chart: CHART_NAME,
    dataLoadHook: useGetIssueRepairEfficacyReoccurrenceRateByClaimAttribute,
    dataLoadHookParams,
    selectedOptions,
    groupByField: GROUP_BY_FIELD,
    actions,
    syncId: CHART_NAME,
  };

  return (
    <Card>
      <div className="space-y-5">
        <div>
          <ChartActionsWrap
            id={CHART_NAME}
            chartTitle={AT_RISK_TITLE}
            subTitle={groupByValue}
          >
            <ChartActions
              actions={actions}
              selectedOptions={selectedOptions}
              onOptionChange={setSelectedOptions}
            />
          </ChartActionsWrap>
          <GroupByChart {...commonChartProps} population="atRisk" />
        </div>
        {withComparisonPopulation && (
          <div>
            <ChartActionsWrap
              id={CHART_NAME}
              chartTitle={COMPARISON_TITLE}
              subTitle={groupByValue}
              showActions={false}
            />
            <GroupByChart {...commonChartProps} population="comparison" />
          </div>
        )}
      </div>
    </Card>
  );
};

export default ReoccurrenceByAttributeChart;
