import { useState } from "react";
import { Autocomplete, Box, Chip, TextField } from "@mui/material";

import DropdownSelect from "features/ui/DropdownSelect";
import { SelectOption } from "features/ui/Select";

interface AttributeNameValues {
  id: string;
  values: string[];
}

interface Props {
  attributeNames: SelectOption[];
  attributeValues: AttributeNameValues[];
  onUpdate?: (selectedAttrName: string, selectedAttrValues: string[]) => void;
}

export const ChartActionAttributeNameValue = ({
  attributeNames,
  attributeValues,
  onUpdate,
}: Props) => {
  const defaultAttributeName =
    (attributeNames && attributeNames.length > 0 && attributeNames[0].id) || "";

  const [selectedAttributeName, setSelectedAttributeName] =
    useState(defaultAttributeName);
  const [selectedAttributeValues, setSelectedAttributeValues] = useState(
    [] as string[]
  );

  const onAttributeNameSelect = (selectedOption: SelectOption) => {
    // when attribute name is changed, reset selected values
    setSelectedAttributeName(selectedOption.id);
    setSelectedAttributeValues([]);

    onUpdate && onUpdate(selectedOption.id as string, []);
  };

  const onAttributeValuesClose = (selectedValues: SelectOption[]) => {
    const selectedValuesOnly = selectedValues.map(
      ({ value }) => value as string
    );
    setSelectedAttributeValues(selectedValuesOnly);

    onUpdate && onUpdate(selectedAttributeName as string, selectedValuesOnly);
  };

  const selectedAttributeValue = attributeNames.find(
    (x) => x.id === selectedAttributeName
  )?.value as string;

  const options: SelectOption[] =
    attributeValues
      .find((x) => x.id === selectedAttributeName)
      ?.values.map((x) => {
        return {
          id: x,
          value: x,
        };
      }) || [];

  return (
    <>
      <DropdownSelect
        label={`Select Attribute: ${selectedAttributeValue}`}
        options={attributeNames}
        onSelect={onAttributeNameSelect}
        key={`attributeName-${selectedAttributeName}`}
      />
      <Autocomplete
        multiple={true}
        disableCloseOnSelect={true}
        size="small"
        id="chart-action-autocomplete"
        data-testid="chart-action-autocomplete"
        options={options}
        limitTags={2}
        value={selectedAttributeValues.map((x) => {
          return { id: x, value: x };
        })}
        isOptionEqualToValue={(current, selected) => current.id === selected.id}
        filterSelectedOptions={true}
        getOptionLabel={({ value }) => (value ? value.toString() : "")}
        noOptionsText="No results"
        fullWidth={true}
        clearOnBlur={false}
        onChange={(_1, value, _2) => {
          onAttributeValuesClose(value);
        }}
        renderTags={(value: readonly SelectOption[], getTagProps) => (
          <Box
            sx={{ maxWidth: "100%" }}
            data-testid="chartactions-autocomplete-selected"
          >
            {value.map((option: SelectOption, index: number) => (
              <Chip
                variant="outlined"
                label={option.value}
                size="small"
                id={`chart-actions-autocomplete-selected-${index}`}
                data-testid={`chart-actions-autocomplete-selected-${index}`}
                {...getTagProps({ index })}
                className="autocomplete-selected-item"
                title={option.value.toString()}
                key={`chart-actions-autocomplete-selected-${index}`}
              />
            ))}
          </Box>
        )}
        renderOption={(props, option) => (
          <span {...props} title={option.value.toString()}>
            {option.value}
          </span>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search"
            variant="outlined"
            size="small"
          />
        )}
      />
    </>
  );
};
