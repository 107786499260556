import { useFlags } from "launchdarkly-react-client-sdk";

import { useCustomLocalStorageState } from "shared/hooks";

import { ClaimsFiltersProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import {
  useByVehicleAgeBirthdayOptions,
  useByVehicleAgeOptions,
  useClaimByVehicleAgeExposureOptions,
} from "pages/hooks";
import { getByVehicleAgeChartOptions } from "pages/utils";

import { cumulativeCPV, cumulativeIPTV } from "features/ui/charts/actions";
import { SelectedChartOptions } from "features/ui/charts/ChartActions";
import { getDefaultActions } from "features/ui/charts/utils";
import { SelectOption } from "features/ui/Select";

import ByVehicleAgeChart from "./ByVehicleAgeChart";
import ByVehicleAgeTable from "./ByVehicleAgeTable";

const CHART_KEY = "claimAnalyticsByVehicleAgeChartOptions";

const ByVehicleAge = ({
  claimsFilters,
  vehiclesFilters,
  onBadRequest,
}: ClaimsFiltersProps) => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();

  const yAxisOptions: SelectOption[] = [
    cumulativeIPTV,
    warrantyClaimsCostFF && cumulativeCPV,
  ].filter(Boolean) as SelectOption[];

  const actions = getByVehicleAgeChartOptions(
    yAxisOptions,
    useByVehicleAgeBirthdayOptions(),
    useClaimByVehicleAgeExposureOptions()
  );

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_KEY, { defaultValue: getDefaultActions(actions) });

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        claimsFilters={claimsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        actions={actions}
        byVehicleAgeData={byVehicleAgeData}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <ByVehicleAgeTable
        claimsFilters={claimsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        byVehicleAgeData={byVehicleAgeData}
      />
    </div>
  );
};

export default ByVehicleAge;
