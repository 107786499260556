import { useFlags } from "launchdarkly-react-client-sdk";

import { CustomSignalEventDefinition } from "shared/api/customSignalEvents/api";

import { getCustomSignalEventState } from "pages/CustomSignalEvents/utils";

import StatefulTabs, { Tab } from "features/ui/StatefulTabs";
import Title from "features/ui/Title";

import { PAGE_TITLE } from "./constants";
import SignalEventDefinition from "./Form/SignalEventDefinition";
import SignalEventOverview from "./Form/SignalEventOverview";
import SignalEventPreview from "./Form/SignalEventPreview";
import SignalEventPreviewOccurrences from "./Form/SignalEventPreviewOccurrences";
import { usePlaygroundFormState } from "./hooks";

interface Props {
  pageTitle?: string;
  customSignalEventDefinition?: CustomSignalEventDefinition;
  requestKey?: string;
}

const SignalEventStudio = ({
  pageTitle,
  customSignalEventDefinition,
  requestKey,
}: Props) => {
  const signalEventStudioState = usePlaygroundFormState(
    customSignalEventDefinition
  );
  const { editMode, isValidForm } = signalEventStudioState;

  const { customSignalEvents } = useFlags();

  const {
    actionsState: { canEditDefinition },
  } = getCustomSignalEventState(customSignalEventDefinition?.status, editMode);

  const tabsItems: (Tab | undefined | boolean)[] = [
    {
      key: "custom_signal_events_definition_tab",
      title: "Definition",
      content: (
        <SignalEventDefinition
          signalEventStudioState={signalEventStudioState}
          canEditDefinition={canEditDefinition}
        />
      ),
    },
    customSignalEvents && {
      key: "custom_signal_events_preview_tab",
      title: "Preview Occurrences In Vehicles",
      content: (
        <SignalEventPreviewOccurrences
          signalEventStudioState={signalEventStudioState}
        />
      ),
      disabled: !isValidForm,
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return (
    <>
      <div className="relative">
        <Title text={pageTitle || PAGE_TITLE} />
        {customSignalEvents && (
          <SignalEventOverview
            signalEventStudioState={signalEventStudioState}
            customSignalEventDefinition={customSignalEventDefinition}
            requestKey={requestKey}
          />
        )}
        <div className="text-gray-600 text-sm mb-4 max-w-4xl">
          Your custom signal event can be based on any event type. It is defined
          by the combination of filters on base event type attributes, and
          filters on derived attributes you create. You see how your custom
          signal performs by viewing it in the vehicle timeline for any vehicle
          you choose.
        </div>
      </div>
      <StatefulTabs tabs={tabs} disableUsingQuery={true} />

      {!customSignalEvents && (
        <SignalEventPreview signalEventStudioState={signalEventStudioState} />
      )}
    </>
  );
};
export default SignalEventStudio;
