import { useFlags } from "launchdarkly-react-client-sdk";

import { useFilterSortState } from "features/ui/Filters/hooks";

import {
  DEFAULT_SUGGESTED_ISSUES_FILTER,
  DEFAULT_SUGGESTED_ISSUES_SORT,
  SUGGESTED_ISSUES_PAGE_KEY,
} from "./constants";
import SuggestedIssuesFilters from "./SuggestedIssues/SuggestedIssuesFilters";
import SuggestedIssuesTable from "./SuggestedIssues/SuggestedIssuesTable";
import SuggestedIssuesDashboard from "./SuggestedIssuesDashboard";

const SuggestedIssuesPage = () => {
  const { issueDashboards } = useFlags();

  const suggestedIssuesFilterSortState = useFilterSortState({
    pageKey: SUGGESTED_ISSUES_PAGE_KEY,
    defaultSort: DEFAULT_SUGGESTED_ISSUES_SORT,
    defaultFilterValues: DEFAULT_SUGGESTED_ISSUES_FILTER,
  });

  return (
    <>
      <SuggestedIssuesFilters
        filterSortState={suggestedIssuesFilterSortState}
      />
      {issueDashboards && (
        <SuggestedIssuesDashboard
          filterSortState={suggestedIssuesFilterSortState}
        />
      )}
      <SuggestedIssuesTable
        topFilterSortState={suggestedIssuesFilterSortState}
      />
    </>
  );
};

export default SuggestedIssuesPage;
