import CustomAttributesSelect, {
  CustomAttribute,
} from "pages/SignalEventStudio/Form/CustomAttributes/CustomAttributeSelect";
import FormSection from "pages/SignalEventStudio/Form/FormSection";

import { SchemaEntry } from "features/ui/Table";

interface Props {
  customAttributes: CustomAttribute[];
  onChange: (customAttributes: CustomAttribute[]) => void;
  onCustomAttributeRemoved: (id: string) => void;
  baseEventType: string;
  baseEventTypeSchema: SchemaEntry<string>[] | undefined;
  disabled?: boolean;
}

const CustomAttributeDefintionsSection = ({
  customAttributes,
  onChange,
  onCustomAttributeRemoved,
  baseEventType,
  baseEventTypeSchema: schema,
  disabled,
}: Props) => (
  <FormSection
    title="Custom Attribute Definitions"
    text={`Define custom attributes (including any needed "intermediate
          calculations") to filter on in the next section. All custom attributes
          appear as columns in the Test VIN Events table below`}
  >
    <CustomAttributesSelect
      customAttributes={customAttributes}
      onChange={onChange}
      baseEventType={baseEventType}
      baseEventTypeSchema={schema}
      onCustomAttributeRemoved={onCustomAttributeRemoved}
      disabled={disabled}
    />
  </FormSection>
);

export default CustomAttributeDefintionsSection;
