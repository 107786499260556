import { useClaimsSchema } from "shared/schemas/claimsSchema";

import { ChartActionOption } from "features/ui/charts/ChartActions";
import { Option } from "features/ui/Select";

export const useOptionsForAccessors = (
  accessors: string[]
): ChartActionOption<Option>[] => {
  const { getSchemaForAccessor } = useClaimsSchema();
  return accessors
    .map((accessor) => {
      const schemaEntry = getSchemaForAccessor(accessor);
      if (!schemaEntry) {
        return undefined;
      }
      return {
        id: accessor,
        value: schemaEntry.label,
        label: schemaEntry.label,
      };
    })
    .filter(Boolean) as ChartActionOption<Option>[];
};
