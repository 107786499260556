import { useCustomLocalStorageState } from "shared/hooks";

import {
  useByVehicleAgeBirthdayOptions,
  useByVehicleAgeOptions,
  useSEByVehicleAgeExposureOptions,
} from "pages/hooks";
import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";
import { getByVehicleAgeChartOptions } from "pages/utils";

import { cumulativeDPTV, cumulativeDPTVDV } from "features/ui/charts/actions";
import { SelectedChartOptions } from "features/ui/charts/ChartActions";
import { getDefaultActions } from "features/ui/charts/utils";

import ByVehicleAgeChart from "./ByVehicleAgeChart";
import ByVehicleAgeTable from "./ByVehicleAgeTable";

const CHART_KEY = "signalEventAnalyticsByVehicleAgeChartOptions";

const ByVehicleAge = ({
  signalEventsFilters,
  vehiclesFilters,
  onBadRequest,
}: SignalEventsFiltersProps) => {
  const actions = getByVehicleAgeChartOptions(
    [cumulativeDPTV, cumulativeDPTVDV],
    useByVehicleAgeBirthdayOptions(),
    useSEByVehicleAgeExposureOptions()
  );

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_KEY, { defaultValue: getDefaultActions(actions) });

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        signalEventsFilters={signalEventsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        actions={actions}
        byVehicleAgeData={byVehicleAgeData}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
      />
      <ByVehicleAgeTable
        signalEventsFilters={signalEventsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
        byVehicleAgeData={byVehicleAgeData}
      />
    </div>
  );
};

export default ByVehicleAge;
