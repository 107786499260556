import { useMemo, useState } from "react";
import qs from "qs";
import { useNavigate } from "react-router-dom";

import { SignalEventsAssociatedClaimsRequest } from "shared/api/signalEvents/api";
import { useListSignalEventsAssociatedClaims } from "shared/api/signalEvents/hooks";
import { getSortFilter } from "shared/api/utils";
import { GENERIC_FILTER_WITHOUT_SELECT } from "shared/filterDefinitions";
import {
  useClaimsFiltersSchema,
  useCustomLocalStorageState,
} from "shared/hooks";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import {
  useGroupBySelectOptions,
  useSchemaEntryForAttribute,
} from "shared/schemas/hooks";
import { SortBy } from "shared/types";
import { cloneObject, pluralize } from "shared/utils";

import {
  CLAIMS_FILTER_LABEL,
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import DescriptionColumn from "pages/ClaimAnalytics/tabPages/TopContributors/DescriptionColumn";
import { canShowDescription } from "pages/ClaimAnalytics/tabPages/TopContributors/utils";
import { SignalEventsAnalyticsTabsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import APIError from "features/ui/APIError";
import { getCheckboxCheckedProps } from "features/ui/Checkbox/utils";
import DropdownSelect from "features/ui/DropdownSelect";
import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { OPERATORS_MAP } from "features/ui/Filters/constants";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
  getTopLevelRowFromFilterGroupState,
  updateOrAddRowFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import {
  DEFAULT_RELATES_FILTER,
  SIGNAL_EVENT_ID_FIELD_NAME,
} from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import RelatesFilterActions from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesFilterActions";
import RelatesTimeWindowForm, {
  WINDOW_DIRECTION_OPTION_AFTER,
} from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/RelatesTimeWindowForm";
import SelectedRowsActions from "features/ui/Filters/FilterTypes/RelatesFilter/RelatesFilterForm/SelectedRowsActions";
import { addRelatesFilterToSignalEventFilter } from "features/ui/Filters/FilterTypes/RelatesFilter/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator, RelatesFilterState } from "features/ui/Filters/types";
import { getFilterLabel, getFiltersKey } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";
import Table, { OnSortParams, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCellWithCheckbox from "features/ui/Table/TableCellWithCheckbox";

import { routes } from "services/routes";
import * as config from "config/config";

const PAGE_KEY = "signalEventsAnalytics-associated-claims";
const DEFAULT_SORT: SortBy = { associationStrength: "desc" };
const LIMIT = 500;
const TABLE_HEIGHT_PX = 500;

const DEFAULT_GROUP_BY_ATTRIBUTE = "laborCode";
const DEFAULT_GROUP_BY_ATTRIBUTE_LABEL = "Labor Code";
const DEFAULT_GROUP_BY_SELECT_OPTION: SelectOption = {
  id: DEFAULT_GROUP_BY_ATTRIBUTE,
  value: DEFAULT_GROUP_BY_ATTRIBUTE_LABEL,
};
const GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY =
  "signalEventsAnalyticsAssociatedClaimsGroupByAttribute";
const SE_CLAIMS_TIME_PERIOD_KEY = "signalEventsAnalyticsSignalEventsWindowSize";
const SE_ASSOCIATED_CLAIMS_KEY = "signalEventsAnalyticsAssociatedClaims";
const DEFAULT_WINDOW_SIZE = 30;

const SE_ASSOCIATED_CLAIMS_KEY_PENDING = getPendingFiltersKey(PAGE_KEY);

const AssociatedClaims = ({
  signalEventsFiltersFilterSortState,
  vehiclesFilters,
}: SignalEventsAnalyticsTabsProps) => {
  const navigate = useNavigate();
  const {
    pages: { claimAnalytics, signalEventsAnalytics },
  } = config.get();

  const claimAnalyticsFilterKey = getFiltersKey(CLAIMS_PAGE_KEY);
  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);

  const [appliedWindowSize, setAppliedWindowSize] = useCustomLocalStorageState(
    SE_CLAIMS_TIME_PERIOD_KEY,
    { defaultValue: DEFAULT_WINDOW_SIZE }
  );

  const [windowSize, setWindowSize] = useState(appliedWindowSize);

  const signalEventsFilters = signalEventsFiltersFilterSortState?.filters;

  const defaultClaimFilters = filterStateToFilterGroupState(
    claimAnalytics?.defaultClaimFilters
  );

  const groupBySelectOptions = useGroupBySelectOptions("claim", true);

  const { getDisplayLabel } = useClaimsSchema();

  const defaultGroupBy = signalEventsAnalytics?.defaultClaimGroupByAttribute
    ? {
        id: signalEventsAnalytics.defaultClaimGroupByAttribute?.id,
        value: getDisplayLabel(
          signalEventsAnalytics.defaultClaimGroupByAttribute?.id,
          signalEventsAnalytics?.defaultClaimGroupByAttribute?.defaultLabel
        ),
      }
    : DEFAULT_GROUP_BY_SELECT_OPTION;

  const [selectedGroupByAttribute, setSelectedGroupByAttribute] =
    useCustomLocalStorageState<SelectOption>(
      GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY,
      {
        defaultValue: defaultGroupBy,
      }
    );

  const [selectedValues, setSelectedValues] = useState(new Set<string>());

  const firstColumn = useSchemaEntryForAttribute(
    selectedGroupByAttribute.id as string,
    "claim"
  );

  const descriptionColumns: SchemaEntry[] = canShowDescription(
    selectedGroupByAttribute.id.toString()
  )
    ? [
        {
          label: "Description",
          accessor: "description",
          dataType: DataType.JSX,
        },
      ]
    : [];

  const claimsFiltersSchema = useClaimsFiltersSchema();

  const claimsFilterSortState = useFilterSortState({
    pageKey: SE_ASSOCIATED_CLAIMS_KEY,
    defaultFilterValues: defaultClaimFilters,
  });

  const claimFilters = claimsFilterSortState.filters;

  const claimsFilterLabel = getFilterLabel(
    "Claim Filters",
    claimsFilterSortState.filters
  );

  const selectedPeriod = `${appliedWindowSize} ${pluralize(
    "day",
    appliedWindowSize
  )}`;

  const defaultSchema: SchemaEntry[] = [
    {
      label: "Claim rate",
      accessor: "IPTV",
      dataType: DataType.NUMBER,
      sortable: true,
      description: `Claim rate per 1000 vehicles experiencing this signal event within ${selectedPeriod} of its occurrence`,
    },
    {
      label: "Association strength",
      accessor: "associationStrength",
      dataType: DataType.NUMBER,
      sortable: true,
      description:
        "A measure of the association between the signal events and the set of claims. In particular, the association strength shows how many times more likely a vehicle which experiences the signal event is to go on to eventually have a claim than the average vehicle in the population (for example, association strength higher than 1 indicates that vehicles are more likely to experience the failure if they’ve experienced the signal event). A higher association strength indicates a higher likelihood of a non-random relationship between the signal event and  the set of claims. Empty cell indicates a failure without sufficient evidence of correlation.",
    },
    {
      label: "Claims w/ preceding SE",
      accessor: "percentClaimsWithPreceding30DaysSignalEvent",
      dataType: DataType.PERCENTAGE,
      description: `Percent of claims with preceding signal event within ${selectedPeriod}`,
      sortable: true,
    },
    {
      label: "Total signal event occurrences",
      accessor: "totalEventOccurrences",
      dataType: DataType.NUMBER,
      description: `Total number of signal event occurrences within ${selectedPeriod} prior to the defined claim set`,
      sortable: true,
      filter: GENERIC_FILTER_WITHOUT_SELECT({
        label: "Total signal event occurrences",
        fieldName: "totalEventOccurrences",
        filterType: "number",
        onlyAllowPositiveIntegers: true,
      }),
    },
    {
      label: "Associated Claims",
      accessor: "numAssociatedClaims",
      dataType: DataType.NUMBER,
      description: `The total number of claims that occur up to ${selectedPeriod} after a signal event`,
      sortable: true,
      filter: GENERIC_FILTER_WITHOUT_SELECT({
        label: "Associated Claims",
        fieldName: "numAssociatedClaims",
        filterType: "number",
        onlyAllowPositiveIntegers: true,
      }),
    },
    {
      label: "Associated Vehicles",
      accessor: "numAssociatedVehicles",
      dataType: DataType.NUMBER,
      description: `The total number of unique vehicles with at least one claim occurring up to ${selectedPeriod} after a signal event.`,
      sortable: true,
      filter: GENERIC_FILTER_WITHOUT_SELECT({
        label: "Associated Vehicles",
        fieldName: "numAssociatedVehicles",
        filterType: "number",
        onlyAllowPositiveIntegers: true,
      }),
    },
  ];

  const {
    manageOnFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const requestParams: SignalEventsAssociatedClaimsRequest = {
    sort: getSortFilter(sort),
    filter: getFiltersQuery(filters),
    signalEventOccurrencesFilter: getFiltersQuery(signalEventsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    claimFilter: getFiltersQuery(claimFilters),
    limit: LIMIT,
    groupBy: selectedGroupByAttribute.id as string,
    signalEventsTimeWindow: appliedWindowSize,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListSignalEventsAssociatedClaims(requestParams);

  const allSelectableValues =
    data?.map(({ groupByAttributeValue }) => groupByAttributeValue as string) ||
    [];
  const { allChecked, indeterminateChecked } = getCheckboxCheckedProps(
    selectedValues,
    allSelectableValues
  );

  const toggleSelectedValues = () => {
    if (allChecked) {
      setSelectedValues(new Set<string>());
      return;
    }
    setSelectedValues(new Set<string>(allSelectableValues));
  };

  const schema: SchemaEntry[] = firstColumn
    ? [
        {
          ...firstColumn,
          dataType: DataType.JSX,
          selectable: {
            onClick: toggleSelectedValues,
            checked: allChecked,
            indeterminate: indeterminateChecked,
          },
        },
        ...descriptionColumns,
        ...defaultSchema,
      ]
    : defaultSchema;

  const formattedData = useMemo(
    () =>
      data &&
      data?.map((row) => {
        const { groupByAttributeValue } = row;
        return {
          ...row,
          groupByAttributeValue: (
            <TableCellWithCheckbox
              value={groupByAttributeValue || ""}
              selectedValues={selectedValues}
              setSelectedValues={setSelectedValues}
              testId="checkbox-associated-claim"
            />
          ),
          description: groupByAttributeValue && (
            <DescriptionColumn
              fieldName={selectedGroupByAttribute.id.toString()}
              fieldValue={groupByAttributeValue}
            />
          ),
        };
      }),
    [data, selectedGroupByAttribute.id, selectedValues]
  );

  const onGroupByChange = (value: SelectOption) => {
    setSelectedGroupByAttribute(value);
    setSelectedValues(new Set<string>());
  };

  const onUpdateRelatesFilter = (row: RelatesFilterState) => {
    setWindowSize(parseInt(row.options.windowSize.toString()));
  };

  const onCancel = () => {
    setWindowSize(DEFAULT_WINDOW_SIZE);
    setAppliedWindowSize(DEFAULT_WINDOW_SIZE);
  };

  const onApply = () => {
    setAppliedWindowSize(windowSize);
  };

  const defaultOptions = DEFAULT_RELATES_FILTER.options;

  const relatesFilter: RelatesFilterState = {
    ...DEFAULT_RELATES_FILTER,
    options: {
      ...defaultOptions,
      windowSize: windowSize,
    },
  };

  const appliedRelatesFilter: RelatesFilterState = {
    ...DEFAULT_RELATES_FILTER,
    options: {
      ...defaultOptions,
      windowSize: appliedWindowSize,
    },
  };

  const navigateToClaimAnalytics = () => {
    const signalEventIDFilter = getTopLevelRowFromFilterGroupState(
      "signalEventID",
      signalEventsFiltersFilterSortState?.filters
    );

    let newClaimFilters = cloneObject(claimFilters);

    if (signalEventIDFilter) {
      // Convert top level signal event ID filter to related signal event filter, but only if you can
      const operator = signalEventIDFilter.operator;
      if (OPERATORS_MAP.relates.flat().includes(operator)) {
        const newRelatesFilter: RelatesFilterState = {
          operator: "occurs",
          options: relatesFilter.options,
          filters: filterStateToFilterGroupState({
            [SIGNAL_EVENT_ID_FIELD_NAME]: {
              operator,
              values: signalEventIDFilter.values,
              relates: signalEventIDFilter.relates,
              extra: signalEventIDFilter.extra,
            },
          }),
        };

        const newRelatedSignalEventOccurrencesFilter =
          addRelatesFilterToSignalEventFilter(undefined, newRelatesFilter);

        newClaimFilters = updateOrAddRowFilterGroupState(claimFilters, {
          id: "relatedSignalEventOccurrences",
          type: "row",
          attribute: "relatedSignalEventOccurrences",
          operator: newRelatedSignalEventOccurrencesFilter.operator,
          values: newRelatedSignalEventOccurrencesFilter.values,
          relates: newRelatedSignalEventOccurrencesFilter.relates,
          extra: newRelatedSignalEventOccurrencesFilter.extra,
        });
      }
    }

    // Add selected values as filter
    newClaimFilters = updateOrAddRowFilterGroupState(newClaimFilters, {
      id: "groupBy",
      type: "row",
      attribute: selectedGroupByAttribute.id as string,
      operator: FilterOperator.IN,
      values: Array.from(selectedValues),
    });

    navigate({
      pathname: routes.claimAnalytics,
      search: qs.stringify({
        [vehicleFilterKey]: getFiltersQuery(vehiclesFilters),
        [claimAnalyticsFilterKey]: getFiltersQuery(newClaimFilters),
      }),
    });
  };

  return (
    <>
      <div className="mt-4 space-y-2">
        <div className="flex flex-wrap leading-10 space-x-2 items-end">
          <span>Claim Defined By</span>
          <DropdownSelect
            testId="claims-filters-dropdown"
            label={claimsFilterLabel}
            buttonClass="mt-6 h-[38px] mr-4"
            content={
              <FilterSelector
                schema={claimsFiltersSchema}
                filterSortState={claimsFilterSortState}
                defaultFilters={defaultClaimFilters}
                title={CLAIMS_FILTER_LABEL}
                testId="claims-filters"
                pendingFiltersKey={SE_ASSOCIATED_CLAIMS_KEY_PENDING}
              />
            }
          />
          <RelatesTimeWindowForm
            relatesState={relatesFilter}
            onUpdate={onUpdateRelatesFilter}
            baseEntityText="Base Signal Event"
            inFilterSelector={false}
            windowDirectionOptions={[WINDOW_DIRECTION_OPTION_AFTER]}
          />
          <RelatesFilterActions
            relatesState={relatesFilter}
            onApply={onApply}
            onCancel={onCancel}
            appliedRelatesFilter={appliedRelatesFilter}
          />
        </div>
        {groupBySelectOptions.length > 0 && (
          <DropdownWithSearch
            className="!mt-5"
            options={groupBySelectOptions}
            selectedOption={selectedGroupByAttribute}
            label="Select a dimension"
            onSelectedOptionChange={onGroupByChange}
            testId="signal-events-associated-claims-dimension"
          />
        )}
      </div>
      <div className="flex items-center my-3">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
      </div>
      <SelectedRowsActions
        relatesFilter={appliedRelatesFilter}
        selectedSignalEvents={selectedValues}
        onExploreInClaimAnalyticsActionClick={navigateToClaimAnalytics}
      />
      {!error && (
        <Table
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={LIMIT}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageOnFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
          scrollHeight={TABLE_HEIGHT_PX}
          testId="associated-claims-table"
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && !isLoading && !data?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default AssociatedClaims;
