import { useEffect, useState } from "react";

import { useCustomLocalStorageState } from "shared/hooks";
import { useGroupBySelectOptions } from "shared/schemas/hooks";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";

import { useExposures } from "pages/hooks";
import { SignalEventsAnalyticsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";
import { useSETopContributorsExposureOptions } from "pages/SignalEventsAnalytics/tabPages/TopContributors/hooks";
import {
  buildNewTopContributorsSelectedOptions,
  getDefaultTopContributorChartActions,
  getTopContributorsChartActions,
} from "pages/utils";

import { SelectedChartOptions } from "features/ui/charts/ChartActions";
import { getDefaultActions } from "features/ui/charts/utils";
import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { SelectOption } from "features/ui/Select";

import { topContributorsChartYAxisOptions } from "./ChartActions";
import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";

const DEFAULT_GROUP_BY_ATTRIBUTE = "signalEventID";
const DEFAULT_GROUP_BY_ATTRIBUTE_LABEL = "ID";
const DEFAULT_GROUP_BY_SELECT_OPTION: SelectOption = {
  id: DEFAULT_GROUP_BY_ATTRIBUTE,
  value: DEFAULT_GROUP_BY_ATTRIBUTE_LABEL,
};
const GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY =
  "signalEventsAnalyticsTopContributorsGroupByAttribute";

const CHART_KEY = `signalEventAnalyticsTopContributorsChartOptions`;

const TopContributors = ({
  signalEventsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: SignalEventsAnalyticsProps) => {
  const { attributes } = useSignalEventOccurrencesSchema();

  const exposures = useSETopContributorsExposureOptions();

  const defaultActions = getDefaultTopContributorChartActions(
    attributes,
    CHART_KEY,
    topContributorsChartYAxisOptions,
    exposures
  );

  const [actions, setActions] = useState(defaultActions);

  const signalEventsFilters = signalEventsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_KEY, {
    defaultValue: getDefaultActions(actions),
  });

  const groupBySelectOptions = useGroupBySelectOptions("signalEventOccurrence");
  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const [selectedGroupByAttribute, setSelectedGroupByAttribute] =
    useCustomLocalStorageState<SelectOption>(
      GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY,
      {
        defaultValue: DEFAULT_GROUP_BY_SELECT_OPTION,
      }
    );

  const {
    previousExposure,
    currentExposure,
    currentExposureBuckets,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
  } = useExposures(attributes, selectedOptions);

  useEffect(
    () => {
      if (previousExposure.current !== currentExposure) {
        previousExposure.current = currentExposure;

        setActions(
          getTopContributorsChartActions(
            topContributorsChartYAxisOptions,
            exposures,
            currentExposureBuckets,
            currentExposure
          )
        );

        setSelectedOptions(
          buildNewTopContributorsSelectedOptions(
            selectedOptions,
            currentExposureBuckets
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedOptions]
  );

  const resetState = () => {
    setSelectedGroupByAttribute(DEFAULT_GROUP_BY_SELECT_OPTION);
    signalEventsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <div className="mt-4">
        {groupBySelectOptions.length > 0 && (
          <div className="mb-5">
            <div className="text-sm mb-1">
              Select a dimension to update the x-axis of the chart and first
              column of the table:
            </div>
            {selectedGroupByAttribute && (
              <DropdownWithSearch
                options={groupBySelectOptions}
                selectedOption={selectedGroupByAttribute}
                label="Group by attribute"
                onSelectedOptionChange={setSelectedGroupByAttribute}
                className="mt-4"
                testId="signal-events-top-contributors-group-by"
              />
            )}
          </div>
        )}
      </div>
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        signalEventsFilters={signalEventsFilters}
        filters={generalFilters}
        onSignalEventsFiltersChange={
          signalEventsFiltersFilterSortState?.manageOnFilterChange
        }
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageOnFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        signalEventsFilters={signalEventsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
