import { createContext } from "react";

import { EventType } from "shared/api/api";
import { useEventRegistry } from "shared/api/hooks";

export const EventRegistryContext = createContext<EventType[] | undefined>(
  undefined
);

const EventRegistryContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useEventRegistry();

  return (
    <EventRegistryContext.Provider value={data}>
      {children}
    </EventRegistryContext.Provider>
  );
};

export default EventRegistryContextWrapper;
