import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";

export type YAxisVal =
  | "IPTV"
  | "CPV"
  | "totalCost"
  | "numClaims"
  | "numVehicles";

export const getTopContributorsChartYAxisOptions = (
  showCosts: boolean
): DropdownSelectOption<YAxisVal>[] => {
  return [
    {
      id: "IPTV",
      value: "Incidents per thousand vehicles",
      label: "Incidents per thousand vehicles",
    },
    showCosts && {
      id: "CPV",
      value: "Cost per vehicle",
      label: "Cost per vehicle",
    },
    showCosts && {
      id: "totalCost",
      value: "Total Cost",
      label: "Total Cost",
    },
    {
      id: "numClaims",
      value: "Total Claims",
      label: "Total number of Claims",
    },
    {
      id: "numVehicles",
      value: "Affected Vehicles",
      label: "Number of affected vehicles",
    },
  ].filter(Boolean) as DropdownSelectOption<YAxisVal>[];
};
