import { useState } from "react";
import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { APISuccessResponse } from "shared/api/api";
import { Claim, getClaim } from "shared/api/claims/api";
import { getInspection, Inspection } from "shared/api/inspections/api";
import { getRepair, Repair } from "shared/api/repairs/api";
import {
  getEntityWithExternalIDTitle,
  getTenantMileageUnit,
} from "shared/utils";

interface Props {
  match: BreadcrumbMatch;
  entity?: keyof EntityMap;
}

interface EntityMap {
  Inspection: Inspection;
  Repair: Repair;
  Claim: Claim;
}

const ENTITY_MAPPINGS: Record<
  keyof EntityMap,
  {
    get: (
      params: any
    ) => Promise<APISuccessResponse<EntityMap[keyof EntityMap]>>;
    otherGetProps?: Record<string, any>;
  }
> = {
  Inspection: {
    get: getInspection,
  },
  Repair: {
    get: getRepair,
  },
  Claim: {
    get: getClaim,
    otherGetProps: {
      mileageUnit: getTenantMileageUnit(),
    },
  },
};

const BreadcrumbWithIDParam = ({ match, entity }: Props) => {
  if (!entity) {
    // This will throw error if we forget to add entity when using BreadcrumbWithIDParam.
    // Ideally we could avoid this error here and impose correct entity via TS in breadcrumbs.ts already!!
    throw new Error(`Invalid entity: ${entity}`);
  }

  const entityCallback = ENTITY_MAPPINGS[entity].get;
  const entityCallbackOtherProps = ENTITY_MAPPINGS[entity].otherGetProps;

  const [data, setData] = useState<EntityMap[typeof entity]>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  if (isLoading) {
    return "...";
  }

  if (data?.externalID)
    return getEntityWithExternalIDTitle(data.externalID, entity);

  if (error && match.params.id) {
    return match.params.id;
  }

  entityCallback({
    ID: match.params.id as string,
    ...entityCallbackOtherProps,
  } as any)
    .then(({ data }) => {
      setData(data);
    })
    .catch(() => setError(true))
    .finally(() => setIsLoading(false));

  return <></>;
};

export default BreadcrumbWithIDParam;
