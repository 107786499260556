import { CustomSignalEventState } from "shared/api/customSignalEvents/api";

import { EDITABLE_STATUSES, PUBLISHABLE_STATUSES } from "./constants";
import { CustomSignalEventStatus } from "./types";

export const getCustomSignalEventState = (
  status?: CustomSignalEventStatus,
  editMode: boolean = true
): CustomSignalEventState => {
  if (!status) {
    // missing status indicates unsaved custom signal event
    return {
      actionsState: {
        canEditID: true,
        canEditDefinition: true,
        canPublish: false,
        canDelete: false,
        canNavigateToDetailsPage: false,
      },
    };
  }

  const canEdit = EDITABLE_STATUSES.includes(status);
  const canEditDefinition = editMode && canEdit;

  const canPublish = editMode && PUBLISHABLE_STATUSES.includes(status);

  const canDelete = editMode && !["ready_for_deletion"].includes(status);

  const canNavigateToDetailsPage = !["ready_for_deletion"].includes(status);

  return {
    actionsState: {
      // ID cannot be updated at any time once custom signal event definition exist in database
      canEditID: false,
      canEditDefinition,
      canPublish,
      canDelete,
      canNavigateToDetailsPage,
    },
  };
};
