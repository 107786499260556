import React, { useState } from "react";
import {
  Bar,
  BarChart as BC,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/types";

import {
  AXIS_TEXT_COLOR,
  AXIS_TOOLTIP_FONT_SIZE,
  BAR_COLOR_SELECTED,
  BAR_COLOR_SELECTED_HOVER,
  BAR_COLOR_UNSELECTED,
  BAR_COLOR_UNSELECTED_HOVER,
  DARK_PASTEL_COLOR_PALETTE,
  LABEL_COLOR,
} from "features/ui/charts/constants";
import { DataElement } from "features/ui/charts/types";
import { getPaletteColor } from "features/ui/charts/utils";

export interface BarChartProps {
  data: DataElement[];
  yAxisKey?: string;
  yAxisBars?: { [key: string]: string };
  xAxisKey: string;
  height?: string | number;
  width?: string | number;
  yAxisLabel?: string;
  xAxisLabel?: string;
  xAxisProps?: XAxisProps;
  yAxisProps?: YAxisProps;
  xAxisLabelDy?: number;
  yAxisWidth?: number;
  onBarClick?: (
    row: DataElement,
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => void;
  customTick?: React.ReactElement<SVGElement, string>;
  tooltipProps?: TooltipProps<any, any>;
  selectedBars?: string[];
  onBarRightClick?: (
    _x: DataElement,
    _y: number,
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => void;
  onChartClick?: (
    chartState: CategoricalChartState,
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => void;
}

const legendFormatter = (value: string) => {
  return <span className="text-gray-400 text-xs">{value}</span>;
};

const TOOLTIP_HEIGHT_OFFSET_PX = 100;

const BarChart = ({
  data,
  yAxisKey,
  yAxisBars,
  xAxisKey,
  height = 300,
  width = "100%",
  yAxisLabel,
  xAxisLabel,
  xAxisProps,
  yAxisProps,
  xAxisLabelDy = 15,
  yAxisWidth = 40,
  onBarClick,
  customTick,
  tooltipProps,
  selectedBars,
  onBarRightClick,
  onChartClick,
}: BarChartProps) => {
  const [hoverBarIndex, setHoverBarIndex] = useState<number>();
  const [tooltipPosition, setTooltipPosition] = useState<
    TooltipProps<any, any>["position"]
  >({ x: 0, y: 0 });

  const onMouseEnter = ({ x, y }: MouseEvent, index: number) => {
    setHoverBarIndex(index);
    // TODO: we would need this SAME functionality also when hovering ABOVE each bar, not just OVER each bar ...
    setTooltipPosition({ x, y: y - TOOLTIP_HEIGHT_OFFSET_PX });
  };

  const onMouseLeave = (_: any, __: number) => {
    setHoverBarIndex(undefined);
    setTooltipPosition({ x: undefined, y: tooltipPosition?.y });
  };

  return (
    <ResponsiveContainer height={height} width={width}>
      <BC
        data={data}
        margin={{ top: 5, right: 0, bottom: 10, left: 0 }}
        onClick={(chartState: CategoricalChartState, event) =>
          onChartClick && onChartClick(chartState, event)
        }
        onMouseMove={({
          activeTooltipIndex,
          activeCoordinate,
        }: CategoricalChartState) => {
          setHoverBarIndex(activeTooltipIndex);
        }}
      >
        <CartesianGrid stroke="#eee" vertical={false} strokeDasharray="5 5" />
        <Tooltip
          position={tooltipPosition}
          allowEscapeViewBox={{ y: true }}
          contentStyle={{
            fontSize: AXIS_TOOLTIP_FONT_SIZE,
            background: "white",
          }}
          wrapperStyle={{ zIndex: 1000 }}
          labelStyle={{
            fontSize: AXIS_TOOLTIP_FONT_SIZE,
          }}
          // when using multiple-bar chart we don't use the same color for all bars
          // without setting itemStyle colors are automatically mapped
          itemStyle={yAxisKey ? { color: BAR_COLOR_SELECTED } : undefined}
          cursor={false}
          {...tooltipProps}
        />
        {(yAxisLabel || yAxisBars) && (
          <Legend
            formatter={legendFormatter}
            align="left"
            verticalAlign="top"
            wrapperStyle={{ top: 0, paddingBottom: 5 }}
          />
        )}
        {yAxisKey && !yAxisBars && (
          <Bar
            name={yAxisLabel}
            dataKey={yAxisKey}
            fill={BAR_COLOR_SELECTED}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={(data, _i, event) => onBarClick && onBarClick(data, event)}
            className={onBarClick && "cursor-pointer"}
            // ideally we d have this on BarChart above so everything can be right-clicked to get to this,
            // but it does not support onContextMenu for some reason .. -.-
            onContextMenu={onBarRightClick}
          >
            {data.map((entry, index) => {
              const isSelected =
                selectedBars && selectedBars.includes(entry[xAxisKey]);

              const anySelected = selectedBars && selectedBars?.length > 0;

              const color =
                isSelected || !anySelected
                  ? BAR_COLOR_SELECTED
                  : BAR_COLOR_UNSELECTED;

              const colorHover =
                isSelected || !anySelected
                  ? BAR_COLOR_SELECTED_HOVER
                  : BAR_COLOR_UNSELECTED_HOVER;

              return (
                <Cell
                  key={index}
                  fill={hoverBarIndex === index ? colorHover : color}
                />
              );
            })}
          </Bar>
        )}
        {yAxisBars &&
          !yAxisKey &&
          Object.entries(yAxisBars).map(([key, label], ix) => (
            <Bar
              name={label}
              dataKey={key}
              fill={getPaletteColor(DARK_PASTEL_COLOR_PALETTE, ix)}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={(data, _i, event) =>
                onBarClick && onBarClick(data, event)
              }
              className={onBarClick && "cursor-pointer"}
              onContextMenu={onBarRightClick}
            >
              {data.map((entry, index) => {
                const isSelected =
                  selectedBars && selectedBars.includes(entry[xAxisKey]);

                const anySelected = selectedBars && selectedBars?.length > 0;

                const color =
                  isSelected || !anySelected
                    ? getPaletteColor(DARK_PASTEL_COLOR_PALETTE, ix)
                    : getPaletteColor(DARK_PASTEL_COLOR_PALETTE, ix, 0.2);

                const colorHover =
                  isSelected || !anySelected
                    ? getPaletteColor(DARK_PASTEL_COLOR_PALETTE, ix, 0.8)
                    : getPaletteColor(DARK_PASTEL_COLOR_PALETTE, ix, 0.4);

                return (
                  <Cell
                    key={index}
                    fill={hoverBarIndex === index ? colorHover : color}
                  />
                );
              })}
            </Bar>
          ))}
        <YAxis
          width={yAxisWidth}
          tick={{ fill: AXIS_TEXT_COLOR, fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          {...yAxisProps}
        />
        <XAxis
          dataKey={xAxisKey}
          label={{
            value: xAxisLabel,
            position: "middle",
            dy: xAxisLabelDy,
            fill: LABEL_COLOR,
          }}
          tick={
            customTick ?? {
              fill: AXIS_TEXT_COLOR,
              fontSize: AXIS_TOOLTIP_FONT_SIZE,
            }
          }
          {...xAxisProps}
        />
      </BC>
    </ResponsiveContainer>
  );
};

export default BarChart;
