import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useInspectionAttributes } from "shared/api/inspections/hooks";

export const InspectionAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const InspectionAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useInspectionAttributes({});

  return (
    <InspectionAttributeContext.Provider value={data}>
      {children}
    </InspectionAttributeContext.Provider>
  );
};

export default InspectionAttributesContextWrapper;
