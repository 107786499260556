import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useSuggestedIssuesAttributes } from "shared/api/suggestedIssues/hooks";

export const SuggestedIssueAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const SuggestedIssueAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useSuggestedIssuesAttributes({});

  return (
    <SuggestedIssueAttributeContext.Provider value={data}>
      {children}
    </SuggestedIssueAttributeContext.Provider>
  );
};

export default SuggestedIssueAttributesContextWrapper;
