import { Link } from "react-router-dom";

import { Repair } from "shared/api/repairs/api";
import { useRepairsSchema } from "shared/schemas/repairsSchema";

import CardHeader from "pages/Issues/Details/CardHeader";
import { VINEventTimelineDateLink } from "pages/VINView/ServiceRecords/VINEventTimelineDateLink";

import Card from "features/ui/Card";
import LabelValuePairs from "features/ui/LabelValuePairs";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  data: Repair;
}

const CARD_TITLE = "Repair Details";

const DISPLAY_FIELDS: string[] = [
  "VIN",
  "date",
  "externalID",
  "notes",
  "status",
  "updatedAt",
  "createdAt",
];

const CardRepairDetails = ({ data }: Props) => {
  const { schema } = useRepairsSchema();

  const formatRow = (row: Repair) => {
    const { VIN, date, externalID, externalURL } = row;

    return {
      ...row,
      externalID: externalURL ? (
        <Link
          target="_blank"
          to={externalURL}
          className="text-metabase-blue hover:underline"
        >
          {externalID}
        </Link>
      ) : (
        externalID
      ),
      VIN: <VINEventTimelineDateLink VIN={VIN} date={date} />,
    };
  };

  const formattedData = formatRow(data);

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <LabelValuePairs
        schema={schema as SchemaEntry<keyof Repair>[]}
        data={formattedData}
        fields={DISPLAY_FIELDS}
      />
    </Card>
  );
};

export default CardRepairDetails;
