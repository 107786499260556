import Skeleton from "react-loading-skeleton";

import { FailureModeEventType } from "shared/api/api";
import { useFailureModeEventsCount } from "shared/api/hooks";
import { formatNumber, toTitleCase } from "shared/utils";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";

interface Props {
  failureModeID: string;
  eventType: FailureModeEventType;
  parentFilters: FilterGroupState;
}

const FailureModeEventsCount = ({
  failureModeID,
  eventType,
  parentFilters,
}: Props) => {
  const combinedFilters: FilterGroupState = mergeFilterGroupStates(
    filterStateToFilterGroupState({
      eventType: { values: [eventType], operator: FilterOperator.IN },
    }),
    parentFilters
  );

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useFailureModeEventsCount({
    id: failureModeID,
    filter: getFiltersQuery(combinedFilters),
  });

  if (countIsLoading) {
    return <Skeleton containerClassName="flex-1" height={104} />;
  }

  return (
    <div className="flex-1 flex flex-col justify-center items-center shadow px-4 py-3 text-center rounded">
      <div
        className="text-3xl font-semibold"
        data-testid={`failure-mode-${eventType}-count`}
      >
        {!countError && countData && formatNumber(countData.count)}
      </div>
      <div className="text-gray-500">{toTitleCase(eventType)} Count</div>
    </div>
  );
};

export default FailureModeEventsCount;
