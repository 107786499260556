import Skeleton from "react-loading-skeleton";

import { MetabaseDashboardFilterConfig } from "config/config";

import { useDashboardEmbedURL } from "./hooks";
import MetabaseEmbedding from "./MetabaseEmbedding";
import { getHideFilterParametersOption } from "./utils";

interface Props {
  dashboardId: string;
  filtersConfig?: MetabaseDashboardFilterConfig;
  title?: string;
  usesLegacyUrlEmbeedingService?: boolean;
}

// MetabaseDashboard wraps the MetabaseEmbedding component and hydrates its parameters from the URL query
// Note: we don't actually use parameters from the URL in our metabase dashboards any longer, this is deprecated code
// that we maintain as we do still use basic Metabase dashboards to show to our customers
const MetabaseDashboard = ({
  dashboardId,
  filtersConfig,
  title,
  usesLegacyUrlEmbeedingService = true,
}: Props) => {
  const options = {
    hide_parameters: getHideFilterParametersOption(filtersConfig),
  };
  const embedURL = useDashboardEmbedURL(
    dashboardId,
    undefined,
    options,
    usesLegacyUrlEmbeedingService
  );

  if (!embedURL) return <Skeleton height={400} />;

  return <MetabaseEmbedding url={embedURL} title={title} />;
};
export default MetabaseDashboard;
