import { createContext } from "react";

import { ECU, EntityAttribute } from "shared/api/api";
import { useListECUs, useVehicleECUsAttributes } from "shared/api/hooks";

const MAX_LIMIT_ECUS = 2000;

export const VehicleECUsAttributeContext = createContext<{
  attributes: EntityAttribute[] | undefined;
  ECUs: ECU[] | undefined;
}>({ attributes: undefined, ECUs: undefined });

const VehicleECUsAttributeContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data: attributes } = useVehicleECUsAttributes({});
  const { data: ECUs } = useListECUs({ limit: MAX_LIMIT_ECUS });

  return (
    <VehicleECUsAttributeContext.Provider value={{ attributes, ECUs }}>
      {children}
    </VehicleECUsAttributeContext.Provider>
  );
};

export default VehicleECUsAttributeContextWrapper;
