import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router";

import { useCustomSignalEventDefinition } from "shared/api/customSignalEvents/hooks";

import SignalEventStudio from "pages/SignalEventStudio";

import APIError from "features/ui/APIError";

import { CustomSignalEventParams } from "./types";

const CustomSignalEvent = () => {
  const { id } = useParams<CustomSignalEventParams>();

  const { data, isLoading, error, requestKey } = useCustomSignalEventDefinition(
    {
      id: id as string,
    }
  );

  if (isLoading) {
    return <Skeleton height={300} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  return (
    <SignalEventStudio
      pageTitle={data?.name}
      customSignalEventDefinition={data}
      requestKey={requestKey}
    />
  );
};

export default CustomSignalEvent;
