import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useSensorReadingsAttributes } from "shared/api/sensors/hooks";

export const SensorReadingAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const SensorReadingAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useSensorReadingsAttributes({});

  return (
    <SensorReadingAttributeContext.Provider value={data}>
      {children}
    </SensorReadingAttributeContext.Provider>
  );
};

export default SensorReadingAttributesContextWrapper;
