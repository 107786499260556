import { Granularity } from "shared/api/constants";
import { SignalEventVehicleAgeTimeline } from "shared/api/signalEvents/api";

import { RowData, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

/**
 * Most of the below utils are the same as for Claim Analytics By Vehicle Age except for the types..
 * - try to consolidate into a more generic helpers (TS generics) once API changes stabilize
 */

export const prepareVehiclesByAgeTableSchemaAndData = (
  data: SignalEventVehicleAgeTimeline[],
  granularity: Granularity,
  buildDateLabel: string
) => {
  const newData: RowData[] = [];
  const ExposureList: number[] = [];

  // rewrite the below using reduce to make it nicer..
  data.forEach(
    ({ birthdayBucket, cumulativeIPTV, numVehicles, exposureBucket }) => {
      ExposureList.push(exposureBucket);

      const currentRow = newData.find(
        (row) => row.birthdayBucket === birthdayBucket
      );

      if (currentRow) {
        currentRow[`IPTV_${exposureBucket}`] = cumulativeIPTV;
        currentRow[`numVehicles_${exposureBucket}`] = numVehicles;
      } else {
        newData.push({
          birthdayBucket,
          [`IPTV_${exposureBucket}`]: cumulativeIPTV,
          [`numVehicles_${exposureBucket}`]: numVehicles,
        });
      }
    }
  );

  const uniqueExposureValues = new Set(ExposureList);
  const exposureValuesArray = Array.from(uniqueExposureValues).sort(
    (a, b) => a - b
  );

  const ExposureSchemaEntries = exposureValuesArray
    .map((exposureBucket) => {
      return [
        {
          accessor: `IPTV_${exposureBucket}`,
          label: `IPTV`,
          dataType: DataType.NUMBER,
        },
        {
          accessor: `numVehicles_${exposureBucket}`,
          label: `Vehicles`,
          dataType: DataType.NUMBER,
        },
      ];
    })
    .flat() as SchemaEntry[];

  const schema: SchemaEntry[] = [
    {
      accessor: "birthdayBucket",
      label: buildDateLabel,
      dataType:
        granularity === Granularity.MONTH
          ? DataType.DATE_YEAR_MONTH
          : DataType.DATE_YEAR_MONTH_DAY,
    },
    ...ExposureSchemaEntries,
  ];

  return {
    data: newData,
    schema,
    uniqueExposureValues,
  };
};
