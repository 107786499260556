import { createContext } from "react";

import { useListAlertDefinitionsAttributes } from "shared/api/alertDefinitions/hooks";
import { EntityAttribute } from "shared/api/api";

export const AlertDefinitionAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const AlertDefinitionAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useListAlertDefinitionsAttributes({});
  return (
    <AlertDefinitionAttributeContext.Provider value={data}>
      {children}
    </AlertDefinitionAttributeContext.Provider>
  );
};

export default AlertDefinitionAttributesContextWrapper;
