import { useCallback, useMemo } from "react";

import {
  getAssociatedVehiclesExport,
  RepairAssociatedVehicle,
  RepairAssociatedVehiclesRequest,
} from "shared/api/repairs/api";
import {
  useCountRepairsAssociatedVehicles,
  useListRepairsAssociatedVehicles,
} from "shared/api/repairs/hooks";
import { getSortFilter } from "shared/api/utils";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { SortBy } from "shared/types";

import { RepairsFiltersProps } from "pages/RepairAnalytics/RepairAnalyticsTabs";
import { getColumnsToShow } from "pages/Vehicles/utils";
import { VINEventTimelineDateLink } from "pages/VINView/ServiceRecords/VINEventTimelineDateLink";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import StringList from "features/ui/StringList";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";

const PAGE_KEY = "repairAn-associated-vehicles";
const DEFAULT_SORT: SortBy = { numRelatedRepairOccurrences: "desc" };
const ROWS_PER_PAGE = 20;

const COLUMNS = [
  "VIN",
  "vehicleMake",
  "vehicleModel",
  "vehicleModelYear",
  "vehicleManufacturedAt",
  "engineModel",
  "tags",
  "manufacturePlantName",
];

const AssociatedVehicles = ({
  repairsFilters,
  vehiclesFilters,
  onBadRequest,
}: RepairsFiltersProps) => {
  const { schema, getDisplayLabel } = useVehiclesSchema();

  const columnsSchema: SchemaEntry[] = schema.filter((schemaEntry) =>
    COLUMNS.includes(schemaEntry.accessor)
  );

  const columnsToShow = getColumnsToShow(schema);

  const filteredSchema = columnsSchema.filter(
    ({ accessor, filter }: SchemaEntry) =>
      columnsToShow.includes(accessor) && Boolean(filter)
  );

  // manually add related repairs to second column
  filteredSchema.splice(1, 0, {
    dataType: DataType.NUMBER,
    accessor: "numRelatedRepairOccurrences",
    label: "Related Repairs",
    sortable: true,
  });

  const {
    manageOnFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const vehiclesFilterString = getFiltersQuery(
    mergeFilterGroupStates(filters, vehiclesFilters)
  );

  const requestParams: RepairAssociatedVehiclesRequest = {
    sort: getSortFilter(sort),
    filter: vehiclesFilterString,
    repairsFilter: getFiltersQuery(repairsFilters),
    limit: ROWS_PER_PAGE,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListRepairsAssociatedVehicles(requestParams);

  const formatRow = useCallback(
    (vehicle: RepairAssociatedVehicle) => {
      const {
        VIN,
        tags,
        firstRelatedRepairOccurrenceAt,
        lastRelatedRepairOccurrenceAt,
      } = vehicle;

      return {
        ...vehicle,
        VIN: (
          <VINEventTimelineDateLink
            VIN={VIN}
            date={firstRelatedRepairOccurrenceAt}
            dateTo={lastRelatedRepairOccurrenceAt}
          />
        ),
        tags: tags && (
          <StringList title={getDisplayLabel("tags", "Tags")} values={tags} />
        ),
      };
    },
    [getDisplayLabel]
  );

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data, formatRow]);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useCountRepairsAssociatedVehicles({
    filter: vehiclesFilterString,
    repairsFilter: getFiltersQuery(repairsFilters),
  });

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <>
      <div className="flex items-center my-1">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto"
          count={countData?.count as number}
          entityName="vehicle"
          isLoading={countIsLoading}
          error={!!countError}
        />
        <DownloadAction
          disabled={downloadDisabled}
          downloadFunc={getAssociatedVehiclesExport}
          fileName="associated-vehicles"
          requestParams={{
            ...requestParams,
            limit: MAX_ROWS_DOWNLOAD_LIMIT,
          }}
          count={countData?.count as number}
          entityName="vehicle"
          filters={filters}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={filteredSchema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageOnFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
        />
      )}
      {error && (
        <APIError
          error={error}
          onBadRequest={() => {
            resetFilterSortState();
            onBadRequest();
          }}
        />
      )}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default AssociatedVehicles;
