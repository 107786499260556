import { Autocomplete, SelectProps, TextField } from "@mui/material";

import { TestProps } from "shared/types";

import { SelectOption } from "features/ui/Select";

interface Props<T> extends TestProps {
  label?: string;
  options: SelectOption<T>[];
  groupedOptions?: SelectOption<T>[][];
  selectedOption: SelectOption<T> | null; // intentionally null and not undefined as otherwise's MUI's Autocomplete complains about "controlled vs uncontrolled component"
  onSelectedOptionChange: (value: SelectOption<T>) => void;
  className?: string;
  width?: number;
  fullWidth?: SelectProps["fullWidth"];
  disabled?: SelectProps["disabled"];
  disableClearable?: boolean;
  placeholder?: string;
}

const DEFAULT_WIDTH = 250;

const HORIZONTAL_LINE = <hr className="m-2" />;

const DropdownWithSearch = <T,>({
  label = "",
  options,
  groupedOptions,
  selectedOption,
  onSelectedOptionChange,
  className = "",
  width = DEFAULT_WIDTH,
  fullWidth = false,
  testId,
  placeholder,
  ...otherProps
}: Props<T>) => {
  if (groupedOptions) {
    const optionsWithGroups: SelectOption<T>[] = [];
    groupedOptions.forEach((opList, index) =>
      opList.forEach((op) => {
        optionsWithGroups.push({ ...op, group: index.toString() });
      })
    );
    options = optionsWithGroups;
  }

  return (
    <Autocomplete
      disablePortal
      options={options}
      groupBy={(option) => option.group ?? ""}
      fullWidth={fullWidth}
      sx={fullWidth ? undefined : { width }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size="small"
          placeholder={placeholder}
        />
      )}
      renderGroup={
        groupedOptions
          ? (params) => (
              <li key={params.key}>
                {params.group !== "0" && HORIZONTAL_LINE}
                <ul>{params.children}</ul>
              </li>
            )
          : undefined
      }
      size="small"
      getOptionLabel={(option) => option.value.toString() || ""}
      className={className}
      value={selectedOption}
      onChange={(_, newValue: SelectOption<T> | null) => {
        if (newValue) {
          onSelectedOptionChange(newValue);
        }
      }}
      data-testid={testId}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      {...otherProps}
    />
  );
};

export default DropdownWithSearch;
