import Alert from "@mui/material/Alert";

import { SensorReadingTimelineEntry } from "shared/api/sensors/api";

import InfoIcon from "features/ui/Icons/Info";

import { MAX_DATA_POINTS_UNTIL_SENSORS_CHART_SLOW } from "./constants";

interface Props {
  data?: SensorReadingTimelineEntry[];
}

const DataAlert = ({ data }: Props) => {
  const show = data && data.length > MAX_DATA_POINTS_UNTIL_SENSORS_CHART_SLOW;
  if (!show) return null;

  return (
    <Alert
      severity="warning"
      variant="outlined"
      style={{
        padding: "0 1rem",
        marginBottom: 10,
      }}
    >
      <div className="flex justify-between">
        <div>
          Currently displaying most recent{" "}
          {MAX_DATA_POINTS_UNTIL_SENSORS_CHART_SLOW} sensor readings.
        </div>
        <div className="ml-2">
          <InfoIcon
            text={
              <div className="px-2 py-1 text-xs">
                Your current selection returned {data.length} data points. To
                maintain performance, only the most recent{" "}
                {MAX_DATA_POINTS_UNTIL_SENSORS_CHART_SLOW} are being displayed.
                <br />
                <br />
                To enhance performance, consider the following:
                <ul className="my-2 pl-5 list-disc list-outside">
                  <li>Narrow the time range.</li>
                  <li>Use daily aggregation.</li>
                  <li>Apply additional filters.</li>
                </ul>
                These adjustments will help optimize the display and improve
                overall page performance.
              </div>
            }
          />
        </div>
      </div>
    </Alert>
  );
};

export default DataAlert;
