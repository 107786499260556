import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";

import { TOP_CONTRIBUTORS_NONE_EXPOSURE } from "pages/constants";
import { mapByVehicleAgeExposureAttributes } from "pages/hooks";

import { SelectOption } from "features/ui/Select";

export const useSETopContributorsExposureOptions = (): SelectOption[] => {
  const { attributes } = useSignalEventOccurrencesSchema();
  return [
    ...mapByVehicleAgeExposureAttributes(attributes),
    TOP_CONTRIBUTORS_NONE_EXPOSURE,
  ];
};
