import { useContext } from "react";

import { IssueAttributeContext } from "shared/contexts/IssuesContextWrapper";
import {
  ISSUE_GROUP_NAME_FILTER,
  ISSUE_SEVERITY_FILTER,
  ISSUE_STATUS_FILTER,
  ISSUES_GENERIC_FILTER,
} from "shared/filterDefinitions";

import { SchemaEntry } from "features/ui/Table";

import { SchemaEntryOverride, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useIssuesSchema = (): UseSchema => {
  const attributes = useContext(IssueAttributeContext);

  const attributeMap = getAttributeMap(attributes);
  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(ISSUES_GENERIC_FILTER, attributeMap);

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const overrides: SchemaEntryOverride = {
    description: {
      schemaOverride: {
        limitedWidthClass: "w-48",
      },
    },
    assignedGroupID: {
      filterOverride: ISSUE_GROUP_NAME_FILTER({
        label: getDisplayLabel("assignedGroupID", "Assigned Group"),
        fieldName: "assignedGroupID",
        disableArbitraryText: true,
      }),
    },
    notes: {
      schemaOverride: {
        limitedWidthClass: "w-48",
      },
    },
    severityObj: {
      filterOverride: ISSUE_SEVERITY_FILTER({
        label: getDisplayLabel("severityObj", "Severity"),
        fieldName: "severityObj.value",
        disableArbitraryText: true,
      }),
      sortOverride: {
        fieldNameForAPI: "severityObj.valueOrder",
      },
    },
    statusObj: {
      filterOverride: ISSUE_STATUS_FILTER({
        label: getDisplayLabel("statusObj", "Status"),
        fieldName: "statusObj.value",
        disableArbitraryText: true,
      }),
      sortOverride: {
        fieldNameForAPI: "statusObj.valueOrder",
      },
    },
  };

  const schema: SchemaEntry[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    getSchemaEntry,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaForAccessor,
  };
};

export default useIssuesSchema;
