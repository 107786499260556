import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  DeleteRequest,
  ExportRequest,
  getFetcher,
  ListAttributesRequest,
  patchFetcher,
  PermissionEntry,
  postFetcher,
  UpdatePermissionRequest,
} from "shared/api/api";
import { clientV1 } from "shared/api/axios";
import { OrderedValue } from "shared/api/orderedValues/api";
import { Group } from "shared/api/rbac/api";
import { SignalEvent } from "shared/api/signalEvents/api";
import { createURL } from "shared/api/utils";
import { ISSUES_ROUTE, SUGGESTED_ISSUES_ROUTE } from "shared/constants";
import {
  BucketByIssuesEnum,
  BucketBySuggestedIssuesEnum,
  GroupByIssuesEnum,
  GroupBySuggestedIssuesEnum,
  IssueClaimGroupBy,
  IssueTypes,
  IssueVehiclePopulation,
  MileageUnit,
} from "shared/types";

import { getBaseAPIRoute } from "pages/Issues/utils";

export interface Issue {
  ID: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  assignee?: string;
  name: string;
  description?: string;
  statusObj?: OrderedValue;
  severityObj?: OrderedValue;
  atRiskPopulationFilter: string;
  comparisonPopulationFilter?: string;
  claimFilter?: string;
  signalEventOccurrencesFilter?: string;
  assignedGroupID: string | null;
  assignedGroup: Group | null;
  access: PermissionEntry[];
  canEdit: boolean;
  notes?: string;
  externalID?: string;
  promotedFromID: string | null;
}

export interface BaseIssueRequest extends APIPaginatedRequest {
  IDs: string[];
  baseRoute?: string;
  includeComparisonPopulation?: boolean;
}

export interface GetIssuePopulationRequest {
  IDs: string[];
  baseRoute?: string;
  vehiclePopulation: IssueVehiclePopulation;
  signalEventsTimeWindow?: number;
  signalEventsReoccurrenceTimeWindowFrom?: number;
  signalEventsReoccurrenceTimeWindowTo?: number;
  // updatedAt param is not required by the API but is passed to force the re-triggering of the API
  // calls after editing the issue
  updatedAt: string;
}

export interface GetIssuePopulationByVehicleAgeRequest
  extends GetIssuePopulationRequest {
  byVehicleAgeExposure?: string;
}

export interface GetIssuePopulationWithGroupByRequest
  extends GetIssuePopulationRequest {
  groupBy?: IssueClaimGroupBy | string;
}

export interface GetIssuePopulationWithGroupByAndByVehicleAgeRequest
  extends GetIssuePopulationWithGroupByRequest {
  byVehicleAgeExposure?: string;
}

const getIssueRequest = (
  { IDs, baseRoute = ISSUES_ROUTE, ...params }: GetIssuePopulationRequest,
  url: string
): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([baseRoute, ...IDs, url]),
    params,
  });

const getIssueGroupByRequest = (
  {
    baseRoute = ISSUES_ROUTE,
    IDs,
    groupBy = "",
    ...params
  }: GetIssuePopulationWithGroupByRequest,
  url: string
): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([baseRoute, ...IDs, url, groupBy]),
    params,
  });

// region List Issues
export interface ListIssuesRequest extends APIPaginatedRequest {}

export const listIssuesRequestURI = (params: ListIssuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([ISSUES_ROUTE]),
    params,
  });

export const listIssues = (args: ListIssuesRequest) =>
  getFetcher<Issue[]>(listIssuesRequestURI(args));

export const listIssuesCountRequestURI = (params: ListIssuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([ISSUES_ROUTE, "count"]),
    params,
  });
// endregion

// region List Issue Details
export interface GetIssueRequest {
  id: string;
  skipRequest?: boolean;
}

export const getIssueRequestURI = ({
  id,
  skipRequest,
}: GetIssueRequest): string =>
  skipRequest
    ? ""
    : clientV1.getUri({
        method: "get",
        url: createURL([ISSUES_ROUTE, id]),
      });

export const getIssue = (args: GetIssueRequest) =>
  getFetcher<Issue>(getIssueRequestURI(args));
// endregion

// region Issue values
const listIssuesValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([ISSUES_ROUTE, "values", fieldName]),
    params,
  });

export const listIssuesValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listIssuesValuesRequestURI(args));
// endregion

const deleteIssueRequestURI = ({ id }: DeleteRequest): string =>
  clientV1.getUri({
    method: "DELETE",
    url: createURL([ISSUES_ROUTE, id]),
  });

export const deleteIssue = (args: DeleteRequest) =>
  clientV1.delete(deleteIssueRequestURI(args));

interface UpdateIssueAccessRequest extends Partial<UpdatePermissionRequest> {
  ID: string;
}

const updateIssueAccessRequestURI = ({
  ID,
  ...params
}: UpdateIssueAccessRequest): string =>
  clientV1.getUri({
    method: "PATCH",
    url: createURL([ISSUES_ROUTE, ID, "access"]),
    params,
  });

export const updateIssueAccess = ({ ID, ...args }: UpdateIssueAccessRequest) =>
  patchFetcher<PermissionEntry[]>(updateIssueAccessRequestURI({ ID }), args);

export interface NewIssueRequest {
  name: string;
  description?: string;
  assignee?: string;
  atRiskPopulationFilter: string;
  comparisonPopulationFilter?: string;
  claimFilter?: string;
  signalEventOccurrencesFilter?: string;
  statusID?: string;
  severityID?: string;
  notes?: string;
}

const newIssueRequestURI = (params: NewIssueRequest): string =>
  clientV1.getUri({
    method: "POST",
    url: ISSUES_ROUTE,
    ...params,
  });

export const newIssue = (data: NewIssueRequest) =>
  postFetcher<Issue>(newIssueRequestURI(data), data);

export interface UpdateIssueRequest extends Partial<NewIssueRequest> {
  ID: string;
}

const updateIssueRequestURI = ({ ID, ...params }: UpdateIssueRequest): string =>
  clientV1.getUri({
    method: "PATCH",
    url: createURL([ISSUES_ROUTE, ID]),
    params,
  });

export const updateIssue = (args: UpdateIssueRequest) =>
  patchFetcher<Issue>(updateIssueRequestURI({ ID: args.ID }), args);

export interface IssueClaim {
  ID: string;
  externalID: string | null;
  VIN: string;
  date: string;
  mileage: number | null;
  mileageUnit: MileageUnit | null;
  monthsInService: number | null;
  costTotal: number | null;
  currencyCode: string | null;
  failedPartNumber: string | null;
  failedPartNumberDescription: string | null;
  laborCode: string | null;
  laborCodeDescription: string | null;
  notes: string | null;
  notesTechnician: string | null;
  notesCorrection: string | null;
  notesCustomer: string | null;
  group: string | null;
  subgroup: string | null;
  populations: string[] | null;
  previousClaims: number | null;
  mentionedPhrases: string[] | null;
  mentionedSignalEvents: SignalEvent[] | null;
  signalEventsTriggered: string[] | null;
  signalEventsOccurrences: number[] | null;
}

export interface ListIssueClaimsRequest extends BaseIssueRequest {
  mileageUnit: MileageUnit;
}

export const listIssueClaimsURI = ({
  IDs,
  baseRoute = ISSUES_ROUTE,
  ...params
}: ListIssueClaimsRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([baseRoute, ...IDs, "claims"]),
    params,
  });

export interface ListIssueClaimsCountRequest extends BaseIssueRequest {
  filter?: string;
}

export const listIssueClaimsCountURI = ({
  IDs,
  baseRoute = ISSUES_ROUTE,
  ...params
}: ListIssueClaimsCountRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([baseRoute, ...IDs, "claims", "count"]),
    params,
  });

export interface IssueSignalEvent {
  recordedAt: string;
  populations: string[];
  signalEventID: string;
  description: string | null;
  signalEventType: string;
  VIN: string;
  odometer: number | null;
  newOccurrence: boolean | null;
  ecuFamily: string | null;
  symptom: string | null;
  milLampStatus: string | null;
  currentFault: string | null;
  extTransactionID: string | null;
  daysToNextClaim: number | null;
}
export const listIssueSignalEventsURI = ({
  IDs,
  baseRoute = ISSUES_ROUTE,
  ...params
}: BaseIssueRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([baseRoute, ...IDs, "signalEvents"]),
    params,
  });

export const listIssueSignalEventsCountURI = ({
  IDs,
  baseRoute = ISSUES_ROUTE,
  ...params
}: BaseIssueRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([baseRoute, ...IDs, "signalEvents", "count"]),
    params,
  });

export interface IssueClaimStatistics {
  IPTV: number | null;
  claimCount: number;
  distinctAffectedVehicles: number;
  percentageOfVehiclesAffected: number | null;
}

export const getIssueClaimStatisticsURI = (
  params: GetIssuePopulationRequest
): string => getIssueRequest(params, "claimStatistics");

export interface IssueSignalEventStatistics {
  distinctAffectedVehicles: number;
  percentageOfVehiclesAffected: number | null;
  IPTV: number | null;
}

export const getIssueSignalEventStatisticsURI = (
  params: GetIssuePopulationRequest
): string => getIssueRequest(params, "signalEventsStatistics");

export interface IssueRelationshipStatistics {
  percentOfClaimsWithPrecedingSignalEvent: number | null;
  claimRateForVehiclesWithSignalEvent: number | null;
}

export const getIssueRelationshipStatisticsURI = (
  params: GetIssuePopulationRequest
): string => getIssueRequest(params, "claimSignalEventsStatistics");

export interface IssueClaimByVehicleAge {
  cumulativeCPV: number;
  cumulativeIPTV: number;
  exposure: number;
  unit: string;
}

export const getIssueClaimsByVehicleAgeURI = (
  params: GetIssuePopulationByVehicleAgeRequest
): string => getIssueRequest(params, "claimsByExposureTimeline");

export interface IssueSignalEventByVehicleAge {
  cumulativeIPTV: number;
  exposure: number;
  signalEventID: string;
}

export const getIssueSignalEventByVehicleAgeURI = (
  params: GetIssuePopulationByVehicleAgeRequest
): string => getIssueRequest(params, "signalEventsByExposureTimeline");

export interface IssueSignalEventTopSignalEvents {
  cumulativeIPTV: number;
  rollingIPTV: number;
  date: string;
  ts?: number;
  signalEventID: string;
}

export const getIssueSignalEventTopSignalEventsURI = (
  params: GetIssuePopulationRequest
): string => getIssueRequest(params, "topSignalEventsTimeline");

interface IssueClaimByCalendarTimeProjection {
  date: string;
  cumulativeCPV: number;
  cumulativeIPTV: number;
  rollingCPV: number;
  rollingIPTV: number;
}

interface IssueClaimByCalendarTimeData {
  cumulativeCPV: number;
  cumulativeIPTV: number;
  rollingCPV: number;
  rollingIPTV: number;
  date: string;
  ts?: number;
  currencyCode: string;
}

export interface IssueClaimByCalendarTime {
  data: IssueClaimByCalendarTimeData[];
  projection: IssueClaimByCalendarTimeProjection[];
}

export const getIssueClaimByCalendarTimeURI = (
  params: GetIssuePopulationRequest
): string => getIssueRequest(params, "claimsByCalendarTimeTimeline");

export interface IssueClaimBreakdownByCalendarTime {
  cumulativeCPV: number;
  cumulativeIPTV: number;
  rollingCPV: number;
  rollingIPTV: number;
  date: string;
  ts?: number;
  currencyCode: string;
  groupByAttributeValue: string;
}

export const getIssueClaimBreakdownByCalendarTimeURI = (
  params: GetIssuePopulationWithGroupByRequest
): string =>
  getIssueGroupByRequest(params, "claimsBreakdownByCalendarTimeTimeline");

export interface IssueClaimBreakdownByVehicleAge {
  cumulativeCPV: number;
  cumulativeIPTV: number;
  exposure: number;
  groupByAttributeValue: string;
  unit: string;
}

export const getIssueClaimBreakdownVehicleAgeURI = (
  params: GetIssuePopulationWithGroupByAndByVehicleAgeRequest
): string =>
  getIssueGroupByRequest(params, "claimsBreakdownByExposureTimeline");

// region Issue Relationship Calendar Time
export interface IssueRelationshipByCalendarTimeTimeline {
  date: string;
  signalEventsRollingDPTV: number;
  signalEventsCumulativeDPTV: number;
  signalEventsRollingDPTVDistinctVINs: number;
  signalEventsCumulativeDPTVDistinctVINs: number;
  claimsRollingIPTV: number;
  claimsCumulativeIPTV: number;
  claimsWithEventRollingIPTV: number;
  claimsWithEventCumulativeIPTV: number;
}

export const getIssueRelationshipByCalendarTimeTimelineURI = (
  params: GetIssuePopulationRequest
): string => getIssueRequest(params, "relationshipByCalendarTimeTimeline");
// endregion

// region Issue Relationship Calendar Time
export interface IssueRelationshipByVehicleAgeTimeline {
  exposure: number;
  signalEventsCumulativeDPTV: number;
  signalEventsCumulativeDPTVDistinctVINs: number;
  claimsCumulativeIPTV: number;
  claimsWithEventCumulativeIPTV: number;
}

export const getIssueRelationshipByVehicleAgeTimelineURI = (
  params: GetIssuePopulationByVehicleAgeRequest
): string => getIssueRequest(params, "relationshipByExposureTimeline");
// endregion

// region Signal Event Occurrences By Calendar Time
export interface IssueSignalEventsByCalendarTime {
  date: string;
  ts?: number;
  rollingIPTV: number;
  cumulativeIPTV: number;
}

export const getIssueSignalEventsByCalendarTimeURI = (
  params: GetIssuePopulationRequest
): string => getIssueRequest(params, "signalEventsByCalendarTimeTimeline");
// endregion

// region Cumulative Signal Event Occurrences By Months in Service
export interface IssueCumulativeSignalEventsByVehicleAge {
  exposure: number;
  cumulativeIPTV: number;
}

export const getIssueCumulativeSignalEventsByVehicleAgeURI = (
  params: GetIssuePopulationByVehicleAgeRequest
): string =>
  getIssueRequest(params, "cumulativeSignalEventsByExposureTimeline");
// endregion

// region Repair Efficacy Statistics
export interface IssueRepairEfficacyStatistics {
  countOfClaimsWithReoccurringSignalEvent: number;
  percentOfClaimsWithReoccurringSignalEvent: number | null;
}

export const getIssueRepairEfficacyStatisticsURI = (
  params: GetIssuePopulationRequest
): string => getIssueRequest(params, "repairEfficacyStatistics");
// endregion

// region Repair Efficacy VIN Timeline
export interface IssueRepairEfficacyVINTimeline {
  externalID: string;
  VIN: string;
  laborCode: string;
  failedPartNumber: string;
  claimDate: string;
  daysSinceClaim: number;
  codeType?: string;
  distinctSignalEvents?: string[];
  otherClaimExternalID: string;
  otherClaimLaborCode: string;
}

export const getIssueRepairEfficacyVINTimelineURI = (
  params: GetIssuePopulationRequest
): string => getIssueRequest(params, "repairEfficacyVINTimeline");
// endregion

// region Repair Efficacy Reoccurrence Rate by Claim Attribute
export interface IssueRepairEfficacyReoccurrenceRateByClaimAttribute {
  daysSinceClaim: number;
  rateOfClaimsWithReoccurringSignalEvent: number;
  numberOfClaimsWithReoccurringSignalEvent: number;
  totalClaimsWithPrecedingSignalEvent: number;
  groupByAttributeValue: string;
}

export const getIssueRepairEfficacyReoccurrenceRateByClaimAttributeURI = (
  params: GetIssuePopulationWithGroupByRequest
): string =>
  getIssueGroupByRequest(
    params,
    "repairEfficacyReoccurrenceRateByAttributeTimeline"
  );
// endregion

// region Repair Efficacy Reoccurrence Rate by Population
export interface IssueRepairEfficacyReoccurrenceRateByPopulation {
  daysSinceClaim: number;
  rateOfClaimsWithReoccurringSignalEvent: number;
  numberOfClaimsWithReoccurringSignalEvent: number;
  totalClaimsWithPrecedingSignalEvent: number;
}

export const getIssueRepairEfficacyReoccurrenceRateByPopulationURI = (
  params: GetIssuePopulationRequest
): string =>
  getIssueRequest(params, "repairEfficacyReoccurrenceRateByPopulationTimeline");
// endregion

// region Repair Efficacy Bar Chart by Claim Attribute
export interface IssueRepairEfficacyReoccurrenceRateByAttribute {
  rateOfClaimsWithReoccurringSignalEvent: number;
  numberOfClaimsWithReoccurringSignalEvent: number;
  totalClaimsWithPrecedingSignalEvent: number;
  groupByAttributeValue: string;
}

export const getIssueRepairEfficacyReoccurrenceRateByAttributeURI = (
  params: GetIssuePopulationWithGroupByRequest
): string =>
  getIssueGroupByRequest(params, "repairEfficacyReoccurrenceRateByAttribute");
// endregion

// region Issue Claims Export
const getIssueClaimsExportRequestURI = (
  issue: IssueTypes,
  { type = "xlsx", IDs, ...params }: ExportRequest
): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([getBaseAPIRoute(issue), ...IDs, "claims", "export", type]),
    params,
  });

export const getIssueClaimsExport = (issue: IssueTypes) => {
  return (args: ExportRequest) =>
    getFetcher<Blob>(getIssueClaimsExportRequestURI(issue, args), {
      responseType: "blob",
    });
};
// endregion

// region Issue Signal Events Export
const getIssueSignalEventsExportRequestURI = (
  issue: IssueTypes,
  { type = "xlsx", IDs, ...params }: ExportRequest
): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([
      getBaseAPIRoute(issue),
      ...IDs,
      "signalEvents",
      "export",
      type,
    ]),
    params,
  });
// endregion

// region Issue Signal Events Export
export const getIssueSignalEventsExport = (issue: IssueTypes) => {
  return (args: ExportRequest) =>
    getFetcher<Blob>(getIssueSignalEventsExportRequestURI(issue, args), {
      responseType: "blob",
    });
};
// endregion

export interface GetIssuePopulationPaginatedRequest
  extends APIPaginatedRequest {
  IDs: string[];
  baseRoute?: string;
  vehiclePopulation: IssueVehiclePopulation;
  signalEventsTimeWindow?: number;
  updatedAt: string;
}

export const getIssueRelationshipAssociatedSignalEventsURI = (
  params: GetIssuePopulationPaginatedRequest
): string => getIssueRequest(params, "relationshipAssociatedSignalEvents");

// region Export Issues
export const getIssuesExport = (args: ExportRequest) =>
  getFetcher<Blob>(getIssuesExportRequestURI(args), {
    responseType: "blob",
  });

const getIssuesExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([ISSUES_ROUTE, "export", type]),
    params,
  });
// endregion

export const listIssuesAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([ISSUES_ROUTE, "attributes"]),
    params,
  });

export interface GetIssuesOverviewRequest {
  bucketBy: BucketByIssuesEnum;
  filter?: string;
  groupBy: GroupByIssuesEnum;
  splitByIssueSource: boolean | null;
}

export interface IssuesOverview {
  bucketByAttributeValue: string | null;
  count: number;
  groupByAttributeValue: string;
  origin: string | null;
}

export const getIssuesOverviewRequestURI = ({
  ...params
}: GetIssuesOverviewRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([ISSUES_ROUTE, "overview"]),
    params,
  });

export interface GetSuggestedIssuesOverviewRequest {
  bucketBy: BucketBySuggestedIssuesEnum;
  filter?: string;
  groupBy: GroupBySuggestedIssuesEnum;
}

export interface SuggestedIssuesOverview {
  bucketByAttributeValue: string | null;
  count: number;
  groupByAttributeValue: string;
}

export const getSuggestedIssuesOverviewRequestURI = ({
  ...params
}: GetSuggestedIssuesOverviewRequest): string =>
  clientV1.getUri({
    method: "GET",
    url: createURL([SUGGESTED_ISSUES_ROUTE, "overview"]),
    params,
  });
