import { format } from "date-fns";
import { toDate } from "date-fns-tz";
import { XAxisProps } from "recharts";

import {
  IssueRepairEfficacyVINTimeline,
  IssuesOverview,
  SuggestedIssuesOverview,
} from "shared/api/issues/api";
import { SuggestedIssue } from "shared/api/suggestedIssues/api";
import { ISSUES_ROUTE, SUGGESTED_ISSUE_RUNS_ROUTE } from "shared/constants";
import {
  BucketByIssuesEnum,
  BucketBySuggestedIssuesEnum,
  ChartActionID,
  GroupByIssuesEnum,
  GroupBySuggestedIssuesEnum,
  IssueChart,
  IssueChartType,
  IssueTypes,
  IssueVehiclePopulation,
} from "shared/types";
import { formatNumber } from "shared/utils";

import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import {
  DataElement,
  LegendConfigLabel,
  YAxisLine,
} from "features/ui/charts/types";
import {
  generateXAxisTickProps,
  getAxisValue,
  getColor,
  getMISTicksFromDIS,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { SelectOption } from "features/ui/Select";

import * as config from "config/config";

import { DASHBOARD_GROUP_BY_KEY, DEFAULT_ISSUES_FILTER } from "./constants";

const CHART_OPTIONS_KEY_PREFIX = "chartOptions";
const CHART_OPTIONS_KEY_SEPARATOR = ".";

export const getPopulationKeyValuePairs = () => [
  { id: "At-Risk", value: "At-Risk" },
  { id: "Comparison", value: "Comparison" },
];

export const getChartOptionsKey = (
  name: string,
  ID: string,
  versionSuffix?: string
) =>
  [CHART_OPTIONS_KEY_PREFIX, name, ID, versionSuffix]
    .filter(Boolean)
    .join(CHART_OPTIONS_KEY_SEPARATOR);

export const getRelatedSignalEventsTimePeriods = (): SelectOption<number>[] => [
  { id: 1, value: 1, testId: "period-1" },
  { id: 3, value: 3, testId: "period-3" },
  { id: 7, value: 7, testId: "period-7" },
  { id: 14, value: 14, testId: "period-14" },
  { id: 30, value: 30, testId: "period-30" },
];

export const getSignalEventReoccurrencesTimePeriods =
  (): SelectOption<number>[] => [
    { id: 0, value: 0, testId: "reoccurrence-period-0" },
    { id: 1, value: 1, testId: "reoccurrence-period-1" },
    { id: 3, value: 3, testId: "reoccurrence-period-3" },
    { id: 7, value: 7, testId: "reoccurrence-period-7" },
    { id: 14, value: 14, testId: "reoccurrence-period-14" },
    { id: 30, value: 30, testId: "reoccurrence-period-30" },
    { id: 45, value: 45, testId: "reoccurrence-period-45" },
    { id: 60, value: 60, testId: "reoccurrence-period-60" },
    { id: 75, value: 75, testId: "reoccurrence-period-75" },
    { id: 90, value: 90, testId: "reoccurrence-period-90" },
  ];

export const getDefaultRelatedSignalEventsTimePeriod = () =>
  getRelatedSignalEventsTimePeriods().slice(-1)[0];

export const getSelectedPeriod = (period: number) =>
  getRelatedSignalEventsTimePeriods().find((x) => x.id === period) ||
  getDefaultRelatedSignalEventsTimePeriod();

// TODO: Might find a better way to distinguish between issue and suggested issue
export const isSuggestedIssue = (issue: IssueTypes) => !("canEdit" in issue);

type IssuesRoutes = typeof ISSUES_ROUTE | typeof SUGGESTED_ISSUE_RUNS_ROUTE;

export const getBaseAPIRoute = (issue: IssueTypes): IssuesRoutes =>
  isSuggestedIssue(issue) ? SUGGESTED_ISSUE_RUNS_ROUTE : ISSUES_ROUTE;

export const getIssueCombinedID = (issue: IssueTypes): string[] =>
  isSuggestedIssue(issue)
    ? [issue.ID, (issue as SuggestedIssue).updated]
    : [issue.ID];

export const getPopulationString = (population: IssueVehiclePopulation) =>
  population === "atRisk" ? "At-Risk Population" : "Comparison Population";

const getValueFormatter = (
  actions: ChartAction[],
  actionId: ChartActionID,
  optionId: string | undefined
) =>
  actions
    .find((x) => x.id === actionId)
    ?.options?.find((x) => x.id === optionId)?.valueFormatter;

const getTooltipValueFormatter = (
  actions: ChartAction[],
  actionId: ChartActionID,
  optionId: string | undefined
) =>
  actions
    .find((x) => x.id === actionId)
    ?.options?.find((x) => x.id === optionId)?.tooltipValueFormatter;

const dateToTs = (date: string) => toDate(date).getTime();

export const prepareScatteredChartLineDefinitions = (
  data: IssueRepairEfficacyVINTimeline[]
): LegendConfigLabel[] => {
  if (!data || data.length === 0) {
    return [];
  }

  const uniqueValues = getChartValues("codeType", data, [], false);
  const uniqueCodeTypes = [...new Set([...uniqueValues, ""])];

  return uniqueCodeTypes.map((value, idx) => {
    return {
      key: value,
      color: getColor(idx),
      label: value || "Claim",
      shape: value ? "circle" : "triangle",
    } as LegendConfigLabel;
  });
};

export const prepareLineDefinitions = (values: string[]) =>
  values.map((value, idx) => {
    return {
      key: value === null ? "null" : value,
      color: getColor(idx),
      label: value === null ? "None" : value,
    } as YAxisLine;
  });

export const getChartValues = (
  field: string,
  data?: Record<string, any>[],
  comparisonData?: Record<string, any>[],
  numericSort: boolean = true
) => {
  if (
    (!data || data.length === 0) &&
    (!comparisonData || comparisonData.length === 0)
  ) {
    return [];
  }

  const dataValues = data?.map((entry) => entry[field]) || [];
  const compDataValues = comparisonData?.map((entry) => entry[field]) || [];

  const allValues = [...new Set([...dataValues, ...compDataValues])];
  return numericSort ? allValues.sort((a, b) => a - b) : allValues.sort();
};

export const appendTsFromDate = (data?: Record<string, any>[]) => {
  return data?.map((row) => {
    return {
      ts: dateToTs(row.date),
      ...row,
    };
  });
};

export const getKeysAndLabels = (
  type: IssueChartType,
  chart: IssueChart,
  xAxisLabel?: string,
  exposure?: string
) => {
  const defaultVehicleAgeKeysAndLabels = {
    xAxisKey: "exposure",
    xAxisLabel: xAxisLabel ?? "",
    tooltipLabel: xAxisLabel ?? "",
  };
  if (type === "VehicleAge" && exposure === "monthsInService") {
    defaultVehicleAgeKeysAndLabels.tooltipLabel = "Days In Service";
  }

  const defaultDaysSinceClaimKeysAndLabels = {
    xAxisKey: "daysSinceClaim",
    xAxisLabel: xAxisLabel ?? "Days Since Claim",
    tooltipLabel: "Days Since Claim",
  };

  const defaultCalendarDaysKeysAndLabels = {
    xAxisKey: "ts",
    xAxisLabel: xAxisLabel ?? "Date",
    tooltipLabel: "Date",
  };

  const defaultBarKeysAndLabels = {
    xAxisKey: "name",
    xAxisLabel: xAxisLabel ?? "",
    tooltipLabel: "",
  };

  const barCharts = ["RepairEfficacy_ReoccurrenceBar"];

  const repairDateXLabelCharts = [
    "Claims_OccurrencesByCalendarTime",
    "Claims_TopXByCalendarTime",
    "Relationships_ClaimOccurrencesByCalendarTime",
  ];

  const occurrenceDateXLabelCharts = [
    "SignalEvents_OccurrencesByCalendarTime",
    "SignalEvents_RateByCalendarTime",
    "Relationships_SignalEventOccurrencesByCalendarTime",
  ];

  if (barCharts.includes(chart)) {
    return defaultBarKeysAndLabels;
  }

  if (repairDateXLabelCharts.includes(chart)) {
    return { ...defaultCalendarDaysKeysAndLabels, xAxisLabel: "Repair Date" };
  }

  if (occurrenceDateXLabelCharts.includes(chart)) {
    return {
      ...defaultCalendarDaysKeysAndLabels,
      xAxisLabel: "Occurrence Date",
    };
  }

  switch (type) {
    case "VehicleAge":
      return defaultVehicleAgeKeysAndLabels;
    case "CalendarTime":
      return defaultCalendarDaysKeysAndLabels;
    case "DaysSinceClaim":
      return defaultDaysSinceClaimKeysAndLabels;
    default:
      return defaultVehicleAgeKeysAndLabels;
  }
};

export const getXAxisProps = (
  type: IssueChartType,
  data: DataElement[],
  exposure?: string
): XAxisProps => {
  if (type === "VehicleAge") {
    return {
      type: "number",
      domain: ["auto", "auto"],
      tickFormatter: (value: number) =>
        exposure === "monthsInService"
          ? // API returns days in service so convert it to months in service for ticks
            `${formatNumber(value / 30, 0)}`
          : value.toString(),
      // since we use tickFormatter above, X axis tick values can be duplicated, so we have to make them unique
      ticks:
        exposure === "monthsInService" ? getMISTicksFromDIS(data) : undefined,
    };
  }

  if (type === "DaysSinceClaim") {
    return {
      type: "number",
      domain: ["auto", "auto"],
    };
  }

  return generateXAxisTickProps(data);
};

export const getTooltipProps = (type: IssueChartType, tooltipLabel: string) => {
  if (["VehicleAge", "DaysSinceClaim"].includes(type)) {
    return {
      labelFormatter: (value: number) => `${tooltipLabel}: ${value}`,
    };
  }

  return {
    labelFormatter: (unixTime: number) =>
      `${tooltipLabel}: ${format(unixTime, "dd MMMM yy")}`,
  };
};

export const getAxisKeyLabelFromActions = (
  selectedOptions: SelectedChartOptions[],
  actions: ChartAction[],
  actionID: ChartActionID
) => {
  const axisKey = getSelectedOptionId(selectedOptions, actionID);
  const axisValue = getAxisValue(actions, actionID, axisKey);
  const valueFormatter = getValueFormatter(actions, actionID, axisKey);
  const tooltipValueFormatter = getTooltipValueFormatter(
    actions,
    actionID,
    axisKey
  );
  return { axisKey, axisValue, valueFormatter, tooltipValueFormatter };
};

export interface transformedData {
  graphData: DataElement[];
  yAxisBars: { [key: string]: string };
  splitGraphData: DataElement[];
}

const yAxisBarsAge = {
  "0": "<1 Week",
  "1": "1 Week",
  "2": "2 Weeks",
  "3": "3 Weeks",
  "4": "4+ Weeks",
};

const xAxisAge: DataElement[] = [
  {
    xAxisKey: "0",
  },
  {
    xAxisKey: "1",
  },
  {
    xAxisKey: "2",
  },
  {
    xAxisKey: "3",
  },
  {
    xAxisKey: "4",
  },
];

const formatXAxis = (
  graphData: DataElement[],
  groupBy: GroupByIssuesEnum | GroupBySuggestedIssuesEnum
) => {
  // remove empty graph data (such that only have xAxisKey and no data elements)
  const onlyHasXAxisKeys = graphData.every(
    (group) =>
      Object.keys(group).length === 1 &&
      group.hasOwnProperty(DASHBOARD_GROUP_BY_KEY)
  );

  if (onlyHasXAxisKeys) {
    return [];
  }

  // format empty values
  graphData.forEach((group) => {
    if (group[DASHBOARD_GROUP_BY_KEY] === "") {
      if (groupBy === "assigned_group") {
        group[DASHBOARD_GROUP_BY_KEY] = "(unassigned or no access)";
      } else {
        group[DASHBOARD_GROUP_BY_KEY] = "(empty)";
      }
    }
  });

  // handle age label formatting
  if (groupBy === "age") {
    const existingKeys = graphData.map(
      (group) => group[DASHBOARD_GROUP_BY_KEY]
    );

    // we make sure we map age to the correct label and retain the values
    let ageData: DataElement[] = [];
    xAxisAge.forEach((el) => {
      if (existingKeys.includes(el[DASHBOARD_GROUP_BY_KEY])) {
        ageData.push({
          ...graphData.find(
            (g) => g[DASHBOARD_GROUP_BY_KEY] === el[DASHBOARD_GROUP_BY_KEY]
          ),
          // @ts-ignore
          xAxisKey: yAxisBarsAge[el[DASHBOARD_GROUP_BY_KEY]],
        });
      } else {
        ageData.push({
          // @ts-ignore
          xAxisKey: yAxisBarsAge[el[DASHBOARD_GROUP_BY_KEY]],
        });
      }
    });

    return ageData;
  }
  return graphData;
};

const findOrCreateGroup = (
  targetGraphData: DataElement[],
  groupByAttributeValue: string
) => {
  let group = targetGraphData.find(
    (el) => el[DASHBOARD_GROUP_BY_KEY] === groupByAttributeValue
  );
  if (!group) {
    group = {
      xAxisKey: groupByAttributeValue,
    };
    targetGraphData.push(group);
  }
  return group;
};

export const transformIssuesOverviewData = (
  data: IssuesOverview[] | undefined,
  bucketBy: BucketByIssuesEnum,
  groupBy: GroupByIssuesEnum,
  splitByIssueSource: boolean
) => {
  const result: transformedData = {
    graphData: [],
    yAxisBars: {},
    splitGraphData: [],
  };

  data?.forEach((item) => {
    const { groupByAttributeValue, bucketByAttributeValue, count, origin } =
      item;

    let targetGraphData = result.graphData;
    if (splitByIssueSource) {
      targetGraphData =
        origin === "ManuallyCreated" ? result.graphData : result.splitGraphData;
    }

    let group = findOrCreateGroup(targetGraphData, groupByAttributeValue);

    if (splitByIssueSource) {
      // we need to make sure to have all xAxisKeys in both graphData and splitGraphData
      let otherGraphData;
      if (targetGraphData === result.graphData) {
        otherGraphData = result.splitGraphData;
      } else {
        otherGraphData = result.graphData;
      }

      findOrCreateGroup(otherGraphData, groupByAttributeValue);
    }

    // @ts-ignore
    group[bucketByAttributeValue] = count;

    if (bucketByAttributeValue === "") {
      if (bucketBy === "assigned_group") {
        result.yAxisBars[bucketByAttributeValue] = "(unassigned or no access)";
      } else {
        result.yAxisBars[bucketByAttributeValue] = "(empty)";
      }
    } else if (bucketByAttributeValue === null) {
      // @ts-ignore
      result.yAxisBars[bucketByAttributeValue] = "(null)";
    } else {
      result.yAxisBars[bucketByAttributeValue] = bucketByAttributeValue;
    }
  });

  // format values on x axis
  result.graphData = formatXAxis(result.graphData, groupBy);
  if (splitByIssueSource) {
    result.splitGraphData = formatXAxis(result.splitGraphData, groupBy);
  }

  // format values on y axis
  if (bucketBy === "age") {
    result.yAxisBars = yAxisBarsAge;
  } else if (bucketBy === "none") {
    result.yAxisBars = {
      null: "Issue count",
    };
  }

  return result;
};

export const transformSuggestedIssuesOverviewData = (
  data: SuggestedIssuesOverview[] | undefined,
  bucketBy: BucketByIssuesEnum | BucketBySuggestedIssuesEnum,
  groupBy: GroupByIssuesEnum | GroupBySuggestedIssuesEnum
) => {
  const result: transformedData = {
    graphData: [],
    yAxisBars: {},
    splitGraphData: [],
  };

  data?.forEach((item) => {
    const { groupByAttributeValue, bucketByAttributeValue, count } = item;

    let group = findOrCreateGroup(result.graphData, groupByAttributeValue);

    // @ts-ignore
    group[bucketByAttributeValue] = count;

    if (bucketByAttributeValue === "") {
      result.yAxisBars[bucketByAttributeValue] = "(empty)";
    } else if (bucketByAttributeValue === null) {
      // @ts-ignore
      result.yAxisBars[bucketByAttributeValue] = "(null)";
    } else {
      result.yAxisBars[bucketByAttributeValue] = bucketByAttributeValue;
    }
  });

  // format values on x axis
  result.graphData = formatXAxis(result.graphData, groupBy);

  // format values on y axis
  if (bucketBy === "age") {
    result.yAxisBars = yAxisBarsAge;
  } else if (bucketBy === "none") {
    result.yAxisBars = {
      null: "Issue count",
    };
  }

  return result;
};

export const getDefaultIssueFilter = (): FilterGroupState => {
  const {
    pages: { issues },
  } = config.get();
  return issues?.defaultFilters || DEFAULT_ISSUES_FILTER;
};

export const getMaxValue = (data: DataElement[]): number => {
  let maxValue = 0;

  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key !== DASHBOARD_GROUP_BY_KEY && item[key] > maxValue) {
        maxValue = item[key];
      }
    });
  });

  return maxValue;
};

interface Dictionary {
  [id: string]: string;
}

const idDict: Dictionary = {
  status: "statusObj.value",
  assigned_group: "assignedGroupID",
  severity: "severityObj.value",
  assignee: "assignee",
  created_by: "createdBy",
  age: "age",
};

// mbencina: this is a temporarily disabled list, we will address this in a different PR
export const disabledIds = ["assigned_group", "age"];

export const getGroupByAttribute = (
  id: string,
  value: string
): SelectOption => {
  return {
    id: idDict[id],
    value: value,
  };
};
