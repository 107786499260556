import classNames from "classnames";
import { toDate } from "date-fns-tz";

import { APIFilter, formatAPIDate } from "shared/api/utils";

import DatePickerRange from "features/ui/DatePickerRange";

import { FilterGroupState } from "./FilterBuilder/types";
import { getSelectOptionsFromFilterGroupState } from "./FilterBuilder/utils";
import SelectFilter from "./FilterTypes/SelectFilter";
import {
  FilterOperator,
  FilterSchemaItem,
  onFilterChangeCallback,
} from "./types";
import { isDateTimeField } from "./utils";

interface Props {
  schema: FilterSchemaItem[];
  filters?: FilterGroupState;
  staticFilters?: APIFilter[];
  // hide specific filters by their fieldName
  hideFilters?: string[];
  onFilterChange: onFilterChangeCallback;
  initialized?: boolean;
  horizontal?: boolean;
  defaultIsOpen?: boolean;
}

const Filters = ({
  filters,
  staticFilters,
  hideFilters = [],
  schema,
  onFilterChange,
  initialized = false,
  horizontal = false,
  defaultIsOpen = false,
}: Props) => {
  const initialSelected = getSelectOptionsFromFilterGroupState(filters);

  const filtersToUse = schema.filter(
    ({ fieldName }) => !hideFilters.includes(fieldName)
  );

  // We want to wait to load filters from local storage. It should be near-instant, so we probably not need loader.
  if (!initialized) {
    return <></>;
  }
  return (
    <div
      className={classNames("flex flex-wrap", {
        "flex-col": !horizontal,
      })}
    >
      {filtersToUse.map(
        ({
          fieldName,
          filterType = "select",
          search = false,
          filterDataType,
          ...otherProps
        }) => {
          return (
            <div
              key={fieldName}
              className={classNames("mb-2 md:mb-0", { "mr-3": horizontal })}
            >
              {filterType === "select" && (
                <SelectFilter
                  key={fieldName}
                  initialSelected={initialSelected[fieldName]}
                  fieldName={fieldName}
                  search={search}
                  onFilterChange={onFilterChange}
                  staticFilters={staticFilters}
                  fullWidth={!horizontal}
                  filterDataType={filterDataType}
                  {...otherProps}
                />
              )}
              {filterType === "date" && (
                <DatePickerRange
                  startDateLabel="From"
                  endDateLabel="To"
                  key={fieldName}
                  showTimeSelect={isDateTimeField(filterDataType)}
                  initialDateStart={
                    initialSelected[fieldName] &&
                    initialSelected[fieldName][0]?.value
                      ? toDate(initialSelected[fieldName][0].value.toString())
                      : null
                  }
                  initialDateEnd={
                    initialSelected[fieldName] &&
                    initialSelected[fieldName][1]?.value
                      ? toDate(initialSelected[fieldName][1].value.toString())
                      : null
                  }
                  onChange={(dateFrom: Date | null, dateTo: Date | null) => {
                    if (dateFrom) {
                      const formattedDateFrom = formatAPIDate(
                        dateFrom.toString(),
                        filterDataType
                      );
                      const initialSelectedFromDate = formatAPIDate(
                        initialSelected[fieldName][0]?.value as string,
                        filterDataType
                      );
                      if (formattedDateFrom !== initialSelectedFromDate) {
                        onFilterChange({
                          key: fieldName,
                          op_id: FilterOperator.BETWEEN,
                          values: [
                            formattedDateFrom,
                            initialSelected[fieldName][1]?.value.toString() ||
                              "",
                          ],
                          dataType: filterDataType,
                        });
                      }
                    }
                    if (dateTo) {
                      const formattedDateTo = formatAPIDate(
                        dateTo.toString(),
                        filterDataType
                      );
                      const initialSelectedToDate = formatAPIDate(
                        initialSelected[fieldName][1]?.value as string,
                        filterDataType
                      );
                      if (formattedDateTo !== initialSelectedToDate) {
                        onFilterChange({
                          key: fieldName,
                          op_id: FilterOperator.BETWEEN,
                          values: [
                            initialSelected[fieldName][0]?.value.toString() ||
                              "",
                            formattedDateTo,
                          ],
                          dataType: filterDataType,
                        });
                      }
                    }
                  }}
                  defaultIsOpen={defaultIsOpen}
                  {...otherProps}
                />
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default Filters;
