import { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { YAxisProps } from "recharts";

import { GetIssuesOverviewRequest } from "shared/api/issues/api";
import { useIssuesOverview } from "shared/api/issues/hooks";
import { useCustomLocalStorageState } from "shared/hooks";
import { BucketByIssuesEnum, GroupByIssuesEnum } from "shared/types";

import { useBarSelection } from "pages/ClaimAnalytics/tabPages/TopContributors/hooks";

import Accordion from "features/ui/Accordion";
import APIError from "features/ui/APIError";
import BarChart from "features/ui/charts/BarChart";
import ChartActions, {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import { getDefaultActions } from "features/ui/charts/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";

import {
  DASHBOARD_GROUP_BY_KEY,
  ISSUES_CHART_ACTIONS,
  ISSUES_CHART_ACTIONS_KEY,
  ISSUES_CHART_KEY,
  ISSUES_DASHBOARD_KEY,
} from "./constants";
import {
  disabledIds,
  getAxisKeyLabelFromActions,
  getGroupByAttribute,
  getMaxValue,
  transformIssuesOverviewData,
} from "./utils";

interface Props {
  filterSortState: UseFilterSortState;
}

const IssuesDashboard = ({ filterSortState }: Props) => {
  const [issuesDashboardExtended, setIssuesDashboardExtended] =
    useCustomLocalStorageState(ISSUES_DASHBOARD_KEY, {
      defaultValue: false,
    });

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(ISSUES_CHART_KEY, {
    defaultValue: getDefaultActions(ISSUES_CHART_ACTIONS),
  });

  const { filters, resetFilterSortState, manageOnFilterChange } =
    filterSortState;

  const filtersQuery = getFiltersQuery(filters);

  const { axisKey: axisKeyBucketBy, axisValue: axisValueBucketBy } =
    getAxisKeyLabelFromActions(
      selectedOptions,
      ISSUES_CHART_ACTIONS,
      "bucketBy"
    );

  const { axisKey: axisKeyGroupBy, axisValue: axisValueGroupBy } =
    getAxisKeyLabelFromActions(
      selectedOptions,
      ISSUES_CHART_ACTIONS,
      "groupBy"
    );

  const { axisKey: axisKeyOrigin } = getAxisKeyLabelFromActions(
    selectedOptions,
    ISSUES_CHART_ACTIONS,
    "splitByIssueSource"
  );

  const { axisValue: measureLabel } = getAxisKeyLabelFromActions(
    selectedOptions,
    ISSUES_CHART_ACTIONS,
    "measure"
  );

  const selectedGroupByAttribute: SelectOption = useMemo(
    () => getGroupByAttribute(axisKeyGroupBy, axisValueGroupBy),
    [axisKeyGroupBy, axisValueGroupBy]
  );

  const {
    selectedBars,
    showContextMenu,
    handleOnChartClick,
    handleOnBarClick,
    onBarRightClick,
    contextMenu,
  } = useBarSelection({
    selectedGroupByAttribute,
    groupByKey: DASHBOARD_GROUP_BY_KEY,
    onIssuesFiltersChange: disabledIds.includes(axisKeyGroupBy)
      ? undefined
      : manageOnFilterChange,
    menuOffsetElement: document.querySelector(
      '[data-testid="accordion-issuesDashboard"]'
    ),
  });

  const splitByIssueSource = axisKeyOrigin === "true";

  const requestParams: GetIssuesOverviewRequest = {
    bucketBy: axisKeyBucketBy as BucketByIssuesEnum,
    filter: filtersQuery,
    groupBy: axisKeyGroupBy as GroupByIssuesEnum,
    splitByIssueSource: splitByIssueSource,
  };

  const { data, isLoading, error } = useIssuesOverview(requestParams);

  const { graphData, yAxisBars, splitGraphData } = transformIssuesOverviewData(
    data,
    axisKeyBucketBy as BucketByIssuesEnum,
    axisKeyGroupBy as GroupByIssuesEnum,
    splitByIssueSource
  );

  const yAxisProps: YAxisProps = {
    domain: [0, Math.max(getMaxValue(graphData), getMaxValue(splitGraphData))],
  };

  const [availableChartActions, setAvailableChartActions] =
    useCustomLocalStorageState<ChartAction[]>(ISSUES_CHART_ACTIONS_KEY, {
      defaultValue: ISSUES_CHART_ACTIONS,
    });

  const handleOnOptionChange = (selectedOptions: SelectedChartOptions[]) => {
    // we want to make sure that user cannot select the same option for both groupBy and bucketBy which returns an API error
    const groupByOption = selectedOptions.find(
      (option) => option.id === "groupBy"
    )?.optionId;
    const bucketByOption = selectedOptions.find(
      (option) => option.id === "bucketBy"
    )?.optionId;

    // update chart actions to remove the selected bucketBy option from groupBy options and vice versa
    const updatedChartActions = ISSUES_CHART_ACTIONS.map((action) => {
      if (action.id === "groupBy" && bucketByOption) {
        return {
          ...action,
          options: action.options?.filter(
            (option) => option.id !== bucketByOption
          ),
        };
      }
      if (action.id === "bucketBy" && groupByOption) {
        return {
          ...action,
          options: action.options?.filter(
            (option) => option.id !== groupByOption
          ),
        };
      }
      return action;
    });

    setAvailableChartActions(updatedChartActions);
    setSelectedOptions(selectedOptions);
  };

  const baseChartTitle = `${measureLabel} grouped by ${axisValueGroupBy}`;
  const chartTitle =
    axisKeyBucketBy === "none"
      ? baseChartTitle
      : `${baseChartTitle} and bucketed by ${axisValueBucketBy}`;

  return (
    <Accordion
      id="issuesDashboard"
      title={chartTitle}
      expanded={issuesDashboardExtended}
      onChange={() => setIssuesDashboardExtended(!issuesDashboardExtended)}
      arrowPosition="left"
      rightContent={
        <ChartActionsWrap>
          <ChartActions
            actions={availableChartActions}
            selectedOptions={selectedOptions}
            onOptionChange={handleOnOptionChange}
          />
        </ChartActionsWrap>
      }
    >
      <div>
        {showContextMenu && contextMenu}
        {!error && (
          <div>
            {isLoading && <Skeleton height={300} />}
            {splitByIssueSource && (
              <div>
                Promoted Issues
                {splitGraphData && splitGraphData.length > 0 ? (
                  <div>
                    <BarChart
                      data={splitGraphData}
                      xAxisKey={DASHBOARD_GROUP_BY_KEY}
                      xAxisProps={{
                        angle: -10,
                        height: 50,
                        dy: 10,
                      }}
                      yAxisBars={yAxisBars}
                      yAxisProps={yAxisProps}
                      selectedBars={selectedBars}
                      onBarClick={handleOnBarClick}
                      onBarRightClick={onBarRightClick}
                      onChartClick={handleOnChartClick}
                    />
                  </div>
                ) : (
                  <div className="py-4 text-gray-400 text-sm">No data.</div>
                )}
                Created Issues
              </div>
            )}
            {graphData && graphData.length > 0 ? (
              <div>
                <BarChart
                  data={graphData}
                  xAxisKey={DASHBOARD_GROUP_BY_KEY}
                  xAxisProps={{
                    angle: -10,
                    height: 50,
                    dy: 10,
                  }}
                  yAxisBars={yAxisBars}
                  yAxisProps={yAxisProps}
                  selectedBars={selectedBars}
                  onBarClick={handleOnBarClick}
                  onBarRightClick={onBarRightClick}
                  onChartClick={handleOnChartClick}
                />
              </div>
            ) : (
              <div className="py-4 text-gray-400 text-sm">No data.</div>
            )}
          </div>
        )}
        {error && (
          <APIError
            error={error}
            onBadRequest={() => {
              resetFilterSortState();
              setSelectedOptions(getDefaultActions(ISSUES_CHART_ACTIONS));
            }}
          />
        )}
      </div>
    </Accordion>
  );
};

export default IssuesDashboard;
