import { SortBy } from "shared/types";

import { ChartAction } from "features/ui/charts/ChartActions";
import { MAX_FILTER_ID } from "features/ui/Filters/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { FilterOperator } from "features/ui/Filters/types";

export const ISSUE_ID_QUERY_KEY = "issueId";
export const ISSUE_RUN_DATE_QUERY_KEY = "issueRunDate";
export const ISSUE_NAME_QUERY_KEY = "issueName";
export const ISSUE_IS_SUGGESTED_QUERY_KEY = "isSuggestedIssue";
export const EDIT_ISSUE_CLAIMS_INDEX = 2;
export const EDIT_ISSUE_SIGNAL_EVENTS_INDEX = 3;
export const EDIT_ISSUE_OVERVIEW_INDEX = 4;
export const VALUES_SEPARATOR = "___";
export const DASHBOARD_GROUP_BY_KEY = "xAxisKey";

export const SUGGESTED_ISSUES_PAGE_KEY = "suggested_issues_list_v2";
export const DEFAULT_SUGGESTED_ISSUES_SORT: SortBy = { discovered: "desc" };
export const DEFAULT_SUGGESTED_ISSUES_FILTER: FilterGroupState = {
  id: "group-0",
  type: "group",
  anyAll: "all",
  children: [
    {
      id: "row-0",
      type: "row",
      attribute: "updated",
      operator: FilterOperator.IN,
      values: [MAX_FILTER_ID],
    },
  ],
};
export const SUGGESTED_ISSUES_DASHBOARD_KEY =
  "suggested_issues_dashboard_state";
export const SUGGESTED_ISSUES_CHART_KEY =
  "suggestedIssuesDashboardChartOptions";
export const SUGGESTED_ISSUES_CHART_ACTIONS_KEY =
  "suggestedIssuesDashboardChartActions";
export const SUGGESTED_ISSUES_CHART_ACTIONS = [
  {
    id: "measure",
    title: "Measure",
    type: "dropdownSelect",
    defaultOptionId: "count",
    options: [
      {
        id: "count",
        label: "Suggested Issue count",
        value: "Suggested Issue count",
      },
    ],
  },
  {
    id: "groupBy",
    title: "X-Axis",
    type: "dropdownSelect",
    defaultOptionId: "status",
    options: [
      {
        id: "status",
        label: "Status",
        value: "Status",
      },
      {
        id: "assigned_group",
        label: "Assigned Group",
        value: "Assigned Group",
      },
      {
        id: "age",
        label: "Age",
        value: "Age",
      },
    ],
  },
  {
    id: "bucketBy",
    title: "Cell Axis",
    type: "dropdownSelect",
    defaultOptionId: "none",
    options: [
      {
        id: "none",
        label: "None",
        value: "None",
      },
      {
        id: "status",
        label: "Status",
        value: "Status",
      },
      {
        id: "assigned_group",
        label: "Assigned Group",
        value: "Assigned Group",
      },
      {
        id: "age",
        label: "Age",
        value: "Age",
      },
    ],
  },
] as ChartAction[];

export const ISSUES_PAGE_KEY = "issues_v3";
export const DEFAULT_ISSUES_SORT: SortBy = { name: "asc" };
export const DEFAULT_ISSUES_FILTER: FilterGroupState = {
  id: "group-0",
  type: "group",
  anyAll: "all",
  children: [
    {
      id: "row-0",
      type: "row",
      attribute: "statusObj.value",
      operator: FilterOperator.NOT_IN,
      values: ["Closed"],
    },
  ],
};
export const ISSUES_DASHBOARD_KEY = "issues_dashboard_state";
export const ISSUES_CHART_KEY = "issuesDashboardChartOptions";
export const ISSUES_CHART_ACTIONS_KEY = "issuesDashboardChartActions";
export const ISSUES_CHART_ACTIONS = [
  {
    id: "measure",
    title: "Measure",
    type: "dropdownSelect",
    defaultOptionId: "count",
    options: [
      {
        id: "count",
        label: "Issue count",
        value: "Issue count",
      },
    ],
  },
  {
    id: "groupBy",
    title: "X-Axis",
    type: "dropdownSelect",
    defaultOptionId: "status",
    options: [
      {
        id: "status",
        label: "Status",
        value: "Status",
      },
      {
        id: "assigned_group",
        label: "Assigned Group",
        value: "Assigned Group",
      },
      {
        id: "severity",
        label: "Type",
        value: "Type",
      },
      {
        id: "assignee",
        label: "Assignee",
        value: "Assignee",
      },
      {
        id: "created_by",
        label: "Created By",
        value: "Created By",
      },
      {
        id: "age",
        label: "Age",
        value: "Age",
      },
    ],
  },
  {
    id: "bucketBy",
    title: "Cell Axis",
    type: "dropdownSelect",
    defaultOptionId: "none",
    options: [
      {
        id: "none",
        label: "None",
        value: "None",
      },
      {
        id: "assigned_group",
        label: "Assigned Group",
        value: "Assigned Group",
      },
      {
        id: "severity",
        label: "Type",
        value: "Type",
      },
      {
        id: "assignee",
        label: "Assignee",
        value: "Assignee",
      },
      {
        id: "created_by",
        label: "Created By",
        value: "Created By",
      },
      {
        id: "age",
        label: "Age",
        value: "Age",
      },
    ],
  },
  {
    id: "splitByIssueSource",
    title: "Split by Issue Source",
    type: "boolean",
  },
] as ChartAction[];
