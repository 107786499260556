import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useClaimAttributes } from "shared/api/claims/hooks";

export const ClaimAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const ClaimAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useClaimAttributes({});

  return (
    <ClaimAttributeContext.Provider value={data}>
      {children}
    </ClaimAttributeContext.Provider>
  );
};

export default ClaimAttributesContextWrapper;
