import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useVehicleAttributes } from "shared/api/vehicles/hooks";

export const VehicleAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const VehicleAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useVehicleAttributes({});

  return (
    <VehicleAttributeContext.Provider value={data}>
      {children}
    </VehicleAttributeContext.Provider>
  );
};

export default VehicleAttributesContextWrapper;
