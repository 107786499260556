import Skeleton from "react-loading-skeleton";

import FormSection from "./FormSection";

const SignalEventPreviewVehicleSummary = () => {
  return (
    <FormSection
      data-testId="vehicle-preview-summary"
      title="Summary of Preview Vehicles"
      text="Select a vehicle to see its events in the list below"
    >
      <Skeleton height={200} />
    </FormSection>
  );
};

export default SignalEventPreviewVehicleSummary;
