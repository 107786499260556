import { KeyboardEvent } from "react";
import qs from "qs";

import {
  DATE_WITH_TIME_FORMAT,
  GLOBAL_PAGE_KEY_PREFIX,
  SHORT_DATE_FORMAT,
  TABLE_STATE_LOCAL_STORAGE_PREFIX,
} from "shared/constants";
import { SortBy } from "shared/types";
import {
  areArraysEqual,
  cleanString,
  cloneObject,
  formatDate,
  randomID,
} from "shared/utils";

import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import { useQuery } from "services/hooks";

import {
  DEFAULT_OPERATOR_PER_TYPE,
  MAX_FILTER_SELECT_OPTION,
  MAX_FILTER_SELECT_OPTION_DATE,
  MIN_FILTER_SELECT_OPTION,
  MIN_FILTER_SELECT_OPTION_DATE,
  OPERATOR_TO_LABEL,
  OPERATOR_TO_LABEL_OVERRIDE_BY_FILTER_TYPE,
  OPERATOR_TO_SIGN,
  OPERATOR_TO_WORDS,
  OPERATORS_MAP,
} from "./constants";
import { DEFAULT_FILTER_BUILDER_STATE } from "./FilterBuilder/constants";
import {
  FilterGroupState,
  FilterRowState,
  FilterRowStateNotNull,
} from "./FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
  isDefaultAdvancedFilterState,
  isFilterBuilderStateValid,
  oldFilterStateToNew,
  removeAttributesFromFilterGroupState,
  updateOrAddRowFilterGroupState,
} from "./FilterBuilder/utils";
import { relatedFiltersAreSame } from "./FilterTypes/RelatesFilter/utils";
import {
  FilterEntry,
  FilterOperator,
  FilterOverviewFormat,
  FilterSortState,
  FilterState,
  FilterType,
  FilterValue,
  InitialDateValues,
  SingleFilterState,
  UseInitialStateValuesAndKeysReturn,
} from "./types";

const DEFAULT_FILTER_KEY_MAP_VALUES = {};
export const CLASS_FOR_REMOVED_CHIPS = "!bg-red-200";
export const CLASS_FOR_UPDATED_CHIPS = "!bg-blue-100";

export const isDateField = (type?: DataType) =>
  type && [DataType.DATE, DataType.DATE_UTC].includes(type);

export const isDateTimeField = (type?: DataType) =>
  !!type &&
  [
    DataType.DATE_WITH_TIME,
    DataType.DATE_WITH_TIME_NO_TZ,
    DataType.DATE_WITH_TIME_UTC,
  ].includes(type);

export const isDateOrDateTimeField = (type?: DataType) =>
  isDateField(type) || isDateTimeField(type);

export const getFilterKeyMapValues = (schema: SchemaEntry[]) => {
  return schema.reduce((obj, { filter }) => {
    if (!filter) return obj;
    Object.assign(obj, { [filter.fieldName]: filter.label });
    return obj;
  }, DEFAULT_FILTER_KEY_MAP_VALUES);
};

export const operatorToSelectOption = (
  type: FilterType,
  op: FilterOperator
): SelectOption<FilterOperator> => {
  let value = OPERATOR_TO_LABEL[op] || cleanString(op);

  // TS complains about undefined even though we checked for in the condition - because we use Partial
  // - that's why we use "!" below to tell TS that we know that this is certainly NOT undefined...
  if (OPERATOR_TO_LABEL_OVERRIDE_BY_FILTER_TYPE[type] !== undefined) {
    if (OPERATOR_TO_LABEL_OVERRIDE_BY_FILTER_TYPE[type]![op] !== undefined) {
      value = OPERATOR_TO_LABEL_OVERRIDE_BY_FILTER_TYPE[type]![op]!;
    }
  }

  return { id: op, value };
};

export interface OperatorsToSelectOptionsProps {
  type: FilterType;
  disableSelectFilters?: boolean;
  disableContainsFilters?: boolean;
  disableStartsWithFilters?: boolean;
  disableIsEmptyFilters?: boolean;
  disableIsNotFilteredFilters?: boolean;
  whitelistedFilterOperators?: FilterOperator[];
}

export const filterOutOperators = (
  {
    disableSelectFilters,
    disableContainsFilters,
    disableStartsWithFilters,
    disableIsEmptyFilters,
    disableIsNotFilteredFilters,
    whitelistedFilterOperators,
  }: OperatorsToSelectOptionsProps,
  operator: FilterOperator
) => {
  if (
    whitelistedFilterOperators &&
    !whitelistedFilterOperators.includes(operator)
  ) {
    return false;
  }

  if (
    disableSelectFilters &&
    [FilterOperator.IN, FilterOperator.NOT_IN].includes(operator)
  ) {
    return false;
  }
  if (
    disableContainsFilters &&
    [FilterOperator.CONTAINS, FilterOperator.NOT_CONTAINS].includes(operator)
  ) {
    return false;
  }
  if (
    disableStartsWithFilters &&
    [FilterOperator.STARTS_WITH, FilterOperator.NOT_STARTS_WITH].includes(
      operator
    )
  ) {
    return false;
  }
  if (
    disableIsEmptyFilters &&
    [FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY].includes(operator)
  ) {
    return false;
  }
  if (
    disableIsNotFilteredFilters &&
    [FilterOperator.NOT_FILTERED].includes(operator)
  ) {
    return false;
  }
  return true;
};

export const getOperatorsSelectOptions = (
  props: OperatorsToSelectOptionsProps
): SelectOption<FilterOperator>[][] => {
  const { type } = props;

  return OPERATORS_MAP[type].map((opList) =>
    opList
      .filter((op) => filterOutOperators(props, op))
      .map((op) => operatorToSelectOption(type, op))
  );
};

export const getDefaultOperatorSelectOption = (
  props: OperatorsToSelectOptionsProps
) => {
  const { type } = props;
  const allowedFilters = OPERATORS_MAP[type].map((opList) =>
    opList.filter((op) => filterOutOperators(props, op))
  );

  const defaultOperator =
    allowedFilters.flat()[0] || DEFAULT_OPERATOR_PER_TYPE[type];

  return operatorToSelectOption(type, defaultOperator);
};

export const getFormattedFilter = (
  { fieldName, operator, fieldValue }: FilterEntry,
  format: FilterOverviewFormat,
  dataType: DataType
) => {
  const formattedFilter =
    format === "badge"
      ? getFormattedFilterForBadge
      : getFormattedFilterForLabel;

  return formattedFilter(fieldName, operator, fieldValue, dataType);
};

const getFormattedFilterForBadge = (
  fieldName: string,
  operator: FilterOperator,
  fieldValue: FilterValue,
  dataType: DataType
) => {
  const formattedOperator = OPERATOR_TO_SIGN[operator];
  let formattedFieldValue = fieldValue;

  if (
    [FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY].includes(operator)
  ) {
    formattedFieldValue = "";
  }

  if (typeof fieldValue === "string" && isDateOrDateTimeField(dataType)) {
    // if we need to format the date more, we can do it here..
    // - dates dont have IN operator, so we dont need to worry about that
    if (operator === FilterOperator.BETWEEN) {
      formattedFieldValue = `${fieldValue}`;
    } else if (operator === FilterOperator.IN_LAST) {
    } else {
      if (isDateTimeField(dataType)) {
        formattedFieldValue = formatDate(fieldValue, DATE_WITH_TIME_FORMAT);
      } else {
        formattedFieldValue = formatDate(fieldValue, SHORT_DATE_FORMAT, true);
      }
    }
  }

  return {
    formattedFieldName: fieldName,
    formattedOperator,
    formattedFieldValue,
  };
};

const getFormattedFilterForLabel = (
  fieldName: string,
  operator: FilterOperator,
  fieldValue: FilterValue,
  dataType: DataType
) => {
  let formattedOperator = OPERATOR_TO_WORDS[operator];
  let formattedFieldValue = fieldValue;

  if (
    [FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY].includes(operator)
  ) {
    formattedFieldValue = "";
    formattedOperator = `${formattedOperator} empty`;
  }

  if (typeof fieldValue === "string" && isDateOrDateTimeField(dataType)) {
    // if we need to format the date more, we can do it here..
    // - dates dont have IN operator, so we dont need to worry about that
    if (operator === FilterOperator.BETWEEN) {
      formattedFieldValue = `${fieldValue}`;
    } else if (operator === FilterOperator.IN_LAST) {
    } else if (isDateTimeField(dataType)) {
      formattedFieldValue = formatDate(fieldValue, DATE_WITH_TIME_FORMAT);
    } else {
      formattedFieldValue = formatDate(fieldValue, SHORT_DATE_FORMAT, true);
    }
  }

  return {
    formattedFieldName: fieldName,
    formattedOperator,
    formattedFieldValue,
  };
};

/**
 * Last X days/weeks/months/years
 */
// will match 12m, 12d, 12w, 12y (or any other number) but not if spaces or anything else is present .. has to start with a number and end with a letter..
const regexToMatchIsLastFormat = /^\d{1,}(?:d|w|m|y)$/;

export type DateOption = "d" | "w" | "m" | "y";

export const DATE_UNIT_OPTIONS: SelectOption<DateOption>[] = [
  { id: "d", value: "Days" },
  { id: "w", value: "Weeks" },
  { id: "m", value: "Months" },
  { id: "y", value: "Years" },
];

// "36 months" ("36m) is a default. Export needed for tests.
export const DEFAULT_DATE_UNIT_OPTION = DATE_UNIT_OPTIONS[2];
export const DEFAULT_LAST_X_VALUE = 36;

export const getDefaultLastXValue = (initialValues?: InitialDateValues) => {
  if (
    !initialValues ||
    !initialValues[0] ||
    !isValueInLastXFormat(initialValues[0])
  ) {
    return DEFAULT_LAST_X_VALUE.toString();
  }
  return initialValues[0].replace(/\D/g, "");
};

export const getDefaultDateUnitOption = (initialValues?: InitialDateValues) => {
  if (
    !initialValues ||
    !initialValues[0] ||
    !isValueInLastXFormat(initialValues[0])
  ) {
    return DEFAULT_DATE_UNIT_OPTION;
  }

  const unitValue = initialValues[0].slice(-1);
  return DATE_UNIT_OPTIONS.find(({ id }) => id === unitValue)!;
};

export const isValueInLastXFormat = (value: string | null) => {
  return value && regexToMatchIsLastFormat.test(value);
};

export const onlyAllowPositiveIntegersOnKeyDown = (event: KeyboardEvent) => {
  if (
    ((event.metaKey || event.ctrlKey) && event.code === "KeyA") ||
    [
      "Delete",
      "Backspace",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
    ].includes(event.code) ||
    event.code.startsWith("Digit")
  ) {
    return true;
  }
};

export const getFilterType = (type: string | undefined): FilterType => {
  if (type && (type.includes("int") || type.includes("double"))) {
    return "number";
  }

  if (type && type.includes("date")) {
    return "date";
  }

  return "string";
};

// we only do it for the first depth, because pending update/removal is not supported for nested filters in the app
export const getUpdatedDeletedAttributes = (
  objA?: FilterGroupState,
  objB?: FilterGroupState
): { updated: string[]; deleted: string[] } => {
  const updated: string[] = [];
  const deleted: string[] = [];

  const objBAttributes = objB?.children
    ?.filter((child) => child.type === "row")
    ?.map((child) => (child as FilterRowState).attribute);

  // Check for updated and removed keys
  objA?.children
    ?.filter((child) => child.type === "row")
    .forEach((rowOrGroup) => {
      const { attribute, operator, values, relates, extra } =
        rowOrGroup as FilterRowState;

      if (!objBAttributes?.includes(attribute)) {
        attribute && deleted.push(attribute);
      } else {
        // build two SingleFilterState objects to compare
        const objAFilter: SingleFilterState = {
          operator: operator || FilterOperator.NOT_FILTERED,
          values: values || [],
          relates: relates || undefined,
          extra: extra,
        };
        const objBFilterRowState = objB?.children.find(
          (child) =>
            child.type === "row" &&
            (child as FilterRowState).attribute === attribute
        ) as FilterRowState | undefined;

        const objBFilter: SingleFilterState = {
          operator: objBFilterRowState?.operator || FilterOperator.NOT_FILTERED,
          values: objBFilterRowState?.values || [],
          relates: objBFilterRowState?.relates || undefined,
          extra: objBFilterRowState?.extra,
        };

        if (!singleFiltersMatch(objAFilter, objBFilter)) {
          attribute && updated.push(attribute);
        }
      }
    });

  // Check for keys added in objB
  objB?.children
    ?.filter(({ type }) => type === "row")
    .forEach((rowOrGroup) => {
      const currentAttribute = (rowOrGroup as FilterRowState).attribute;
      if (
        !objA?.children.some(
          (child) =>
            child.type === "row" && child.attribute === currentAttribute
        )
      ) {
        currentAttribute && updated.push(currentAttribute);
      }
    });

  return { updated, deleted };
};

export const singleFiltersMatch = (
  filter1?: SingleFilterState,
  filter2?: SingleFilterState
): boolean => {
  if (filter1 === filter2) return true;
  if (!filter1 || !filter2) return false;

  return (
    Object.keys(filter1).length === Object.keys(filter2).length &&
    Object.keys(filter1).every((key) => Object.keys(filter2).includes(key)) &&
    filter1.operator === filter2.operator &&
    areArraysEqual(filter1.values, filter2.values) &&
    // naive
    JSON.stringify(filter1.extra) === JSON.stringify(filter2.extra) &&
    (filter1.relates && filter2.relates
      ? relatedFiltersAreSame(filter1.relates, filter2.relates)
      : true)
  );
};

// Adjust values so that they are compatible with the selected operator
// (e.g. if operator is "is empty", values should be ["null"])
export const prepareFilterValuesForAPI = (
  currentFilterValues: string[],
  operator: FilterOperator
): string[] => {
  let values: string[] = [...currentFilterValues];

  if (FilterOperator.NOT_FILTERED === operator) {
    values = [];
  }

  if (
    [FilterOperator.IS_EMPTY, FilterOperator.IS_NOT_EMPTY].includes(operator)
  ) {
    values = ["null"];
  }

  if ([FilterOperator.IS_TRUE, FilterOperator.IS_FALSE].includes(operator)) {
    values = [operator === FilterOperator.IS_TRUE ? "true" : "false"];
  }

  if (operator === FilterOperator.BETWEEN) {
    if (values.length === 1) {
      values = [values[0]];
    }
    if (values.length === 2) {
      values = [values[0], values[1]];
    }
  }

  if (
    [
      FilterOperator.GREATER_OR_EQUAL,
      FilterOperator.GREATER_THAN,
      FilterOperator.LESS_OR_EQUAL,
      FilterOperator.LESS_THAN,
      FilterOperator.IN_LAST,
    ].includes(operator) &&
    values.length > 0
  ) {
    values = [values[0]];
  }
  return values.filter((v) => v !== "");
};

export const resetFilters = (
  filters: FilterGroupState | undefined,
  accessors: string[],
  removedKeysArray: string[] = [],
  filterToResetToIfRemoved: FilterGroupState | undefined
): FilterGroupState => {
  if (!filters) return DEFAULT_FILTER_BUILDER_STATE;

  let newFilters: FilterGroupState = cloneObject(filters);

  for (const accessor of accessors) {
    if (!removedKeysArray.includes(accessor)) {
      const filterWithoutAccessor = removeAttributesFromFilterGroupState(
        newFilters,
        [accessor]
      );

      if (!filterWithoutAccessor) {
        newFilters = DEFAULT_FILTER_BUILDER_STATE;
      } else {
        Object.assign(
          newFilters,
          removeAttributesFromFilterGroupState(newFilters, [accessor])
        );
      }
    }

    if (removedKeysArray && removedKeysArray.includes(accessor)) {
      const accessorRow =
        filterToResetToIfRemoved &&
        (filterToResetToIfRemoved.children.find(
          (child) => child.type === "row" && child.attribute === accessor
        ) as FilterRowStateNotNull | undefined);

      if (accessorRow) {
        newFilters = updateOrAddRowFilterGroupState(newFilters, {
          ...accessorRow,
          id: `row-${randomID()}`,
        });
      }
    }
  }

  return newFilters;
};

export const getFilterChipColorClass = (
  fieldName: string,
  keysUpdated?: string[],
  keysRemoved?: string[]
): string | undefined => {
  if (keysUpdated && keysUpdated.includes(fieldName)) {
    return CLASS_FOR_UPDATED_CHIPS;
  }

  if (keysRemoved && keysRemoved.includes(fieldName)) {
    return CLASS_FOR_REMOVED_CHIPS;
  }

  return undefined;
};

export const getMinMaxSelectOptions = (
  filterType?: FilterType
): SelectOption[] | undefined => {
  if (filterType === "date") {
    return [MAX_FILTER_SELECT_OPTION_DATE, MIN_FILTER_SELECT_OPTION_DATE];
  }
  if (filterType === "number") {
    return [MAX_FILTER_SELECT_OPTION, MIN_FILTER_SELECT_OPTION];
  }
  return;
};

export const hasSomeFiltersApplied = (filter: FilterState): boolean => {
  if (!isFilterStateCorrect(filter)) return false;

  return Object.values(filter).some(
    ({ operator, values }) =>
      operator !== FilterOperator.NOT_FILTERED && values.length > 0
  );
};

export const hasSomeFiltersAppliedFilterGroupState = (
  filter: FilterGroupState
): boolean => {
  if (isDefaultAdvancedFilterState(filter)) return false;

  return isFilterBuilderStateValid(filter);
};

/**
 * Returns the number of rows that have filters applied in the filter group state.
 * It counts inside nested groups as well.
 */
export const getFilterStateCount = (filter: FilterGroupState): number => {
  if (
    isDefaultAdvancedFilterState(filter) ||
    !isFilterBuilderStateValid(filter)
  ) {
    return 0;
  }

  const countGroupRowsApplied = (filterGroup: FilterGroupState): number => {
    return filterGroup.children.reduce((acc, child) => {
      if (child.type === "group") {
        return acc + countGroupRowsApplied(child);
      }

      const { operator, values } = child as FilterRowState;
      return operator !== FilterOperator.NOT_FILTERED &&
        values &&
        values.length > 0
        ? acc + 1
        : acc;
    }, 0);
  };

  return countGroupRowsApplied(filter);
};

export const getAttributesFromFilter = (filter?: FilterState): string[] =>
  filter ? Object.keys(filter) : [];

export const runForAllAttributesInFilterState = (
  filter: FilterState,
  callback: (attribute: string) => void
): void =>
  getAttributesFromFilter(filter).forEach((attribute) => callback(attribute));

export const addToFilterState = (
  target: FilterState,
  attribute: string,
  stateToAdd: SingleFilterState
) => {
  Object.assign(target, { [attribute]: stateToAdd });
};

export const isFilterStateCorrect = (
  filterState: any
): filterState is FilterState => {
  if (!filterState) return false;

  const keys = getAttributesFromFilter(filterState as FilterState);

  if (!keys.length) {
    return true;
  }

  return keys.every((key) => {
    const values = (filterState as FilterState)[key]?.values;
    const operator = (filterState as FilterState)[key]?.operator;

    if (!operator || !Array.isArray(values)) {
      return false;
    }
    return true;
  });
};

/**
 * Removes filters that are not compatible with our app/got broken in the past https://viaduct-ai.atlassian.net/browse/APP-94
 * (Only lets filters that have operator & values properties defined through and removes the rest.)
 */
export const removeInvalidFilters = (
  filterState?: FilterState
): FilterState | undefined => {
  if (!filterState) return undefined;

  const filteredFilterState: FilterState = {};
  runForAllAttributesInFilterState(filterState, (fieldName) => {
    const currentValues = filterState[fieldName];

    if (currentValues.operator && Array.isArray(currentValues.values)) {
      addToFilterState(filteredFilterState, fieldName, currentValues);
    }
  });

  return filteredFilterState;
};

export const useInitialStateValuesAndKeys = (
  pageKey: string,
  disableUsingQuery: boolean,
  disableUsingLocalStorage: boolean,
  defaultFilterValues?: FilterGroupState
): UseInitialStateValuesAndKeysReturn => {
  const query = useQuery();

  // Note that only URI keys are modified, localStorage keys stay the same ("filters", "sort", "columns").
  const filtersKey = getURIKey("filters", pageKey);
  const sortKey = getURIKey("sort", pageKey);
  const columnsKey = getURIKey("columns", pageKey);

  const queryFilters = query[filtersKey]
    ? filterBuilderQueryToFilterBuilderState(query[filtersKey] as string)
    : undefined;

  const querySort = query[sortKey] as SortBy;
  const queryColumns = query[columnsKey] as string[];

  let initialValues: FilterSortState = {};

  // If any of the state parameters are present in the query, we assume the user
  // wants to see a specific configuration rather than their current one from local storage.
  if (
    // We still want to check if query for filters was defined as filterBuilderQueryToFilterBuilderState converts empty
    // string to undefined and it slips through cracks. If empty string is in query, we want to have a clean slate and not load
    // existing data from local storage
    (query[filtersKey] !== undefined ||
      queryFilters !== undefined ||
      querySort !== undefined ||
      queryColumns !== undefined) &&
    !disableUsingQuery
  ) {
    initialValues = {
      filters: queryFilters,
      sort: querySort,
      columns: queryColumns,
    };
  } else if (!disableUsingLocalStorage) {
    initialValues = getStateFromLocalStorage(pageKey, defaultFilterValues);
  }

  // When disableUsingQuery is true && defaultFilterValues is provided,
  // we want to set the initial filters to be defaultFilterValues.
  // - Ie. in FilterWizard, we want to use the defaultFilterValues if they are provided
  //   and we don't rely on the query params there ..
  if (disableUsingQuery && defaultFilterValues) {
    initialValues.filters = defaultFilterValues;
  }

  return {
    initialValues,
    queryKeys: {
      filtersKey,
      sortKey,
      columnsKey,
    },
  };
};

export const getPageKeyWithVersion = (pageKey: string) =>
  `${GLOBAL_PAGE_KEY_PREFIX}.${pageKey}`;

export const getFiltersKey = (pageKey: string) =>
  getURIKey("filters", getPageKeyWithVersion(pageKey));

export const getURIKey = (
  stateAttribute: keyof FilterSortState,
  pageKey: string
) => `${stateAttribute}_${pageKey}`;

export const getStateFromLocalStorage = (
  pageKey: string,
  defaultFilterValues?: FilterGroupState
): FilterSortState => {
  const filterSortStateLocalStorage = JSON.parse(
    localStorage.getItem(`${TABLE_STATE_LOCAL_STORAGE_PREFIX}.${pageKey}`) ??
      "{}"
  );

  const filtersFromLocalStorage = oldFilterStateToNew(
    filterSortStateLocalStorage.filters
  );

  const sortFromLocalStorage = filterSortStateLocalStorage.sort;

  const columnsFromLocalStorage = filterSortStateLocalStorage.columns;

  let localStorageFilterSortState: FilterSortState = {};

  if (sortFromLocalStorage !== undefined) {
    localStorageFilterSortState.sort = sortFromLocalStorage;
  }

  if (filtersFromLocalStorage !== undefined) {
    localStorageFilterSortState.filters = filtersFromLocalStorage;
  } else if (
    defaultFilterValues &&
    isFilterBuilderStateValid(defaultFilterValues)
  ) {
    localStorageFilterSortState.filters = cloneObject(defaultFilterValues);
  }

  if (columnsFromLocalStorage !== undefined) {
    localStorageFilterSortState.columns = columnsFromLocalStorage;
  }

  return localStorageFilterSortState;
};

/**
 * We are modifying the URI params to use our own keys instead of the ones in FilterSortState.
 * - We also change FilterState to query string format so we can use it in the URI
 * - And we set the values to empty string if they are empty objects.
 */
export const modifyURIParams = (
  data: FilterSortState,
  queryKeys: {
    filtersKey: string;
    sortKey: string;
    columnsKey: string;
  }
): FilterSortState => {
  const { filtersKey, sortKey, columnsKey } = queryKeys;
  const { filters, sort, columns } = data;

  const filtersQuery = getFiltersQuery(filters);

  return {
    [filtersKey]: filtersQuery,
    [sortKey]: sort || "",
    [columnsKey]: columns || "",
  };
};

export const persistToLocalStorage = (
  filterSortState: FilterSortState,
  pageKey: string
) => {
  localStorage.setItem(
    `${TABLE_STATE_LOCAL_STORAGE_PREFIX}.${pageKey}`,
    JSON.stringify(filterSortState)
  );
};

/**
 * Modifies the URI params so they match our expected format and
 * merges given data with the current query params.
 */
export const getUpdatedQueryParams = (
  data: FilterSortState,
  queryKeys: UseInitialStateValuesAndKeysReturn["queryKeys"],
  currentQuery: qs.ParsedQs
) => {
  const updatedFiltersForQuery = modifyURIParams(data, queryKeys);

  // Current query params + modified filters
  return qs.stringify(
    {
      ...currentQuery,
      ...updatedFiltersForQuery,
    },
    { arrayFormat: "indices" }
  );
};

export const getFilterLabel = (label: string, filters: FilterGroupState) => {
  const filtersCount = getFilterStateCount(filters);
  return hasSomeFiltersAppliedFilterGroupState(filters)
    ? `${label} (${filtersCount})`
    : label;
};

/**
 * If the selected operator is not available for the current attribute due to disableXY props
 * we have to choose a new default one when switching the attribute.
 */
export const getFirstAvailableSelectedOperator = (
  selected: FilterOperator | null,
  currentAttributeType: FilterType,
  attributeSchema?: SchemaEntry<string>
): FilterOperator => {
  const newDefaultOperator = getDefaultOperatorSelectOption({
    type: currentAttributeType,
    ...attributeSchema?.filter,
  }).id;

  const availableOperators = getOperatorsSelectOptions({
    type: currentAttributeType,
    ...attributeSchema?.filter,
  })
    .flat()
    .map(({ id }) => id);

  return selected && availableOperators.includes(selected)
    ? selected
    : newDefaultOperator;
};
