import { useGetIssueSignalEventTopSignalEvents } from "shared/api/issues/hooks";
import { useCustomLocalStorageState } from "shared/hooks";
import {
  IssueChart,
  IssueChartGroupBy,
  IssueChartType,
  IssueTypes,
} from "shared/types";

import GroupByChart from "pages/Issues/Charts/GroupByChart";
import { getChartOptionsKey, getPopulationString } from "pages/Issues/utils";

import Card from "features/ui/Card";
import {
  cumulativeDPTV,
  cumulativeDPTVDV,
  rollingDPTV,
  rollingDPTVDV,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions, {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import { getDefaultActions } from "features/ui/charts/utils";

const actions: ChartAction[] = [
  {
    id: "y",
    title: yAxis,
    type: "dropdownSelect",
    options: [cumulativeDPTVDV, rollingDPTVDV, cumulativeDPTV, rollingDPTV],
  },
];

const CHART_NAME: IssueChart = "SignalEvents_RateByCalendarTime";
const CHART_TYPE: IssueChartType = "CalendarTime";
const GROUP_BY_FIELD: IssueChartGroupBy = "signalEventID";
const TITLE = "Top 5 Signal Events by Date";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const RateByCalendarTimeChart = ({
  issue,
  withComparisonPopulation,
}: Props) => {
  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID);
  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const atRiskChartTitle = `${TITLE} : ${getPopulationString("atRisk")}`;
  const comparisonChartTitle = `${TITLE} : ${getPopulationString(
    "comparison"
  )}`;

  const commonChartProps = {
    issue,
    chartType: CHART_TYPE,
    chart: CHART_NAME,
    dataLoadHook: useGetIssueSignalEventTopSignalEvents,
    selectedOptions,
    groupByField: GROUP_BY_FIELD,
    actions,
    syncId: CHART_NAME,
  };

  return (
    <Card>
      <div className="space-y-5">
        <div>
          <ChartActionsWrap id={CHART_NAME} chartTitle={atRiskChartTitle}>
            <ChartActions
              actions={actions}
              selectedOptions={selectedOptions}
              onOptionChange={setSelectedOptions}
            />
          </ChartActionsWrap>
          <GroupByChart {...commonChartProps} population="atRisk" />
        </div>
        {withComparisonPopulation && (
          <div>
            <ChartActionsWrap
              id={CHART_NAME}
              chartTitle={comparisonChartTitle}
              showActions={false}
            />
            <GroupByChart {...commonChartProps} population="comparison" />
          </div>
        )}
      </div>
    </Card>
  );
};

export default RateByCalendarTimeChart;
