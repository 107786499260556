import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  CountRequest,
  ExportRequest,
  getFetcher,
  ListAttributesRequest,
  Predictions,
} from "shared/api/api";
import client, { clientV1 } from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { MileageUnit } from "shared/types";

const VEHICLES_BASE_URL = "vehicles";

export interface Vehicle {
  VIN: string;
  createdAt: string;
  updatedAt: string;
  fleet: string | null;
  externalID: string | null;
  saleDealerName: string | null;
  saleIntendedService: string | null;
  vehicleMake: string;
  vehicleModel: string;
  vehicleModelYear: number | null;
  vehicleGvwr: string | null;
  vehicleManufacturedAt: string | null;
  engineModel: string | null;
  engineModelYear: number | null;
  engineHorsepower: number | null;
  engineOnTimeTotal: number | null;
  idleRate: number | null;
  fuelConsumption: number | null;
  fuelConsumptionUnit: FuelConsumptionUnit | null;
  engineManufacturedAt: string | null;
  mileage: number | null;
  mileageUnit: MileageUnit | null;
  vehicleStartedDrivingAt: string | null;
  serviceDurationInMonths: number | null;
  vehicleCategoryID: string | null;
  transportCategoryID: string | null;
  predictions: Predictions;
  tags: string[];
  platform: string | null;
  body: string | null;
  country: string | null;
  purpose: string | null;
  engineSupplierCode: string | null;
  engineNumber: string | null;
  productCode: string | null;
  infotainmentModel: string | null;
  transmissionModel: string | null;
  transmissionSupplierCode: string | null;
  transmissionNumber: string | null;
  lastKnownDealerCountry: string | null;
  lastKnownDealerState: string | null;
  lastKnownDealerCity: string | null;
  brakeModel: string | null;
  chassisDetail: string | null;
  hvacModel: string | null;
  radio: string | null;
  collisionSensorModel: string | null;
}

type FuelConsumptionUnit = "mpg" | "lkm";

export interface GetVehicleRequest {
  vin: string;
  mileageUnit: MileageUnit;
}

export interface ListVehiclesRequest extends APIPaginatedRequest {
  mileageUnit: MileageUnit;
}

export interface CountRequestWithVehiclesFilter extends CountRequest {
  vehiclesFilter?: string;
  analytics?: boolean;
}

// --------
// List Vehicles
// --------
export const listVehiclesRequestURI = (params: ListVehiclesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: `/${VEHICLES_BASE_URL}`,
    params,
  });

// --------
// Get Single Vehicle
// --------
export const getVehicleRequestURI = ({
  vin,
  mileageUnit,
}: GetVehicleRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, vin]),
    params: { mileageUnit },
  });

// --------
// Vehicle Count
// --------
export const listVehiclesCountRequestURI = (params: CountRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, "count"]),
    params,
  });

// --------
// Vehicle Export
// --------
const getVehiclesExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, "export", type]),
    params,
  });

export const getVehiclesExport = (args: ExportRequest) =>
  getFetcher<Blob>(getVehiclesExportRequestURI(args), {
    responseType: "blob",
  });

// ---------
// Vehicles values
// ---------
const listVehiclesValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, "values", fieldName]),
    params,
  });

export const listVehiclesValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listVehiclesValuesRequestURI(args));

// --------
// Vehicle Attributes
// --------
export const listVehicleAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([VEHICLES_BASE_URL, "attributes"]),
    params,
  });

// --------
// Similar VINS
// --------
export interface ListSimilarVehicleFactorsRequest {
  failureModeID: string;
  currentVIN: string;
  similarVIN: string;
  limit?: number;
}

export const listSimilarVehicleFactorsRequestURI = ({
  failureModeID,
  currentVIN,
  similarVIN,
  ...params
}: ListSimilarVehicleFactorsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([
      VEHICLES_BASE_URL,
      currentVIN,
      "similarVehicles",
      failureModeID,
      "factors",
      similarVIN,
    ]),
    params,
  });

interface SimilarVehicleFactor {
  name: string;
  description: string;
  currentVINValue: number;
  similarVINValue: number;
  currentVINPercentile: number;
  similarVINPercentile: number;
  averageValue: number;
}

export interface SimilarVehicleFactors {
  target: string;
  currentVIN: string;
  similarVIN: string;
  factors: SimilarVehicleFactor[];
}
