import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useRepairAttributes } from "shared/api/repairs/hooks";

export const RepairAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const RepairAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useRepairAttributes({});

  return (
    <RepairAttributeContext.Provider value={data}>
      {children}
    </RepairAttributeContext.Provider>
  );
};

export default RepairAttributesContextWrapper;
