import { useMemo } from "react";

import {
  getIssueSignalEventsExport,
  IssueSignalEvent,
} from "shared/api/issues/api";
import {
  useListIssueSignalEvents,
  useListIssueSignalEventsCount,
} from "shared/api/issues/hooks";
import { applyAdditionalSorting, getSortFilter } from "shared/api/utils";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import {
  ISSUE_POPULATIONS_FILTER,
  ISSUE_SIGNAL_EVENTS_FILTER,
} from "shared/filterDefinitions";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";
import { IssueTypes, SortBy } from "shared/types";

import {
  getBaseAPIRoute,
  getIssueCombinedID,
  isSuggestedIssue,
} from "pages/Issues/utils";
import { VINEventTimelineDateLink } from "pages/VINView/ServiceRecords/VINEventTimelineDateLink";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const formatRow = (issueSignalEvent: IssueSignalEvent) => {
  const { VIN, populations, recordedAt } = issueSignalEvent;
  return {
    ...issueSignalEvent,
    VIN: <VINEventTimelineDateLink VIN={VIN} date={recordedAt} />,
    populations: populations?.join(", "),
  };
};

const ROWS_PER_PAGE = 10;
const DEFAULT_SORT: SortBy = { recordedAt: "desc" };

const IssueSignalEventsTable = ({ issue, withComparisonPopulation }: Props) => {
  const { getSchemaForAccessor } = useSignalEventOccurrencesSchema();

  const SCHEMA_ITEMS: (SchemaEntry | boolean | undefined)[] = [
    getSchemaForAccessor("recordedAt"),
    {
      label: "Population(s)",
      accessor: "populations",
      dataType: DataType.STRING,
      description: "Issue population(s).",
      filter: ISSUE_POPULATIONS_FILTER,
    },
    getSchemaForAccessor("signalEventID"),
    getSchemaForAccessor("description"),
    getSchemaForAccessor("signalEventType"),
    getSchemaForAccessor("VIN"),
    getSchemaForAccessor("odometer"),
    getSchemaForAccessor("newOccurrence"),
    getSchemaForAccessor("ecuFamily"),
    getSchemaForAccessor("symptom"),
    getSchemaForAccessor("milLampStatus"),
    getSchemaForAccessor("currentFault"),
    getSchemaForAccessor("extTransactionID"),
    {
      label: "Days to Next Claim",
      accessor: "daysToNextClaim",
      sortable: true,
      dataType: DataType.NUMBER,
      filter: ISSUE_SIGNAL_EVENTS_FILTER({
        filterType: "number",
        label: "Days to Next Claim",
        fieldName: "daysToNextClaim",
        disableSelectFilters: true,
      }),
    },
  ];
  const SCHEMA = SCHEMA_ITEMS.filter(Boolean) as SchemaEntry[];

  const { ID, comparisonPopulationFilter, updatedAt } = issue;
  const pageKey = `issue_signal_events_${ID}`;

  const {
    manageOnFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: pageKey,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const includeComparisonPopulation =
    Boolean(comparisonPopulationFilter) && withComparisonPopulation;

  const requestParams = {
    IDs: getIssueCombinedID(issue),
    baseRoute: getBaseAPIRoute(issue),
    includeComparisonPopulation,
    sort: getSortFilter(applyAdditionalSorting(sort)),
    filter: getFiltersQuery(filters),
    limit: ROWS_PER_PAGE,
    updatedAt,
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListIssueSignalEvents(requestParams);

  const countRequestParams = {
    IDs: getIssueCombinedID(issue),
    baseRoute: getBaseAPIRoute(issue),
    includeComparisonPopulation,
    filter: getFiltersQuery(filters),
    updatedAt,
  };

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListIssueSignalEventsCount(countRequestParams);

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  const downloadDisabled = !formattedData || formattedData.length === 0;

  const fileName = isSuggestedIssue(issue)
    ? "suggested-issue-signal-events"
    : "issue-signal-events";

  return (
    <>
      <div className="flex items-center mt-3 mb-1">
        <FiltersOverview
          filters={filters}
          tableSchema={SCHEMA}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto"
          count={countData?.count as number}
          entityName="signal event"
          isLoading={countIsLoading}
          error={!!countError}
        />
        <DownloadAction
          disabled={downloadDisabled}
          downloadFunc={getIssueSignalEventsExport(issue)}
          fileName={fileName}
          requestParams={{
            IDs: requestParams.IDs,
            filter: requestParams.filter,
            limit: MAX_ROWS_DOWNLOAD_LIMIT,
            includeComparisonPopulation:
              requestParams.includeComparisonPopulation,
          }}
          count={countData?.count as number}
          entityName="issue signal event"
          filters={filters}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={SCHEMA}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageOnFilterChange}
          filters={filters}
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default IssueSignalEventsTable;
