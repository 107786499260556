import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import {
  APISuccessResponse,
  getLaborCode,
  getPart,
  LaborCode,
  Part,
} from "shared/api/api";
import { getSignalEvent } from "shared/api/signalEvents/api";

interface Props {
  fieldName: string;
  fieldValue: string | number | boolean | null;
  textOnly?: boolean;
}

const dataFetchCallbackMap: Record<string, (args: any) => Promise<any>> = {
  laborCode: getLaborCode,
  failedPartNumber: getPart,
  mentionedSignalEvents: getSignalEvent,
  signalEventID: getSignalEvent,
};

// TODO: the SAME thing for Claims/Inspections/Repairs, consolidate!!!! the only diff is in dataFetchCallbackMap (attributes with descriptions..)
const DescriptionColumn = ({
  fieldName,
  fieldValue,
  textOnly = false,
}: Props) => {
  const dataFetchHandler: (
    args: any
  ) => Promise<APISuccessResponse<Part | LaborCode>> =
    dataFetchCallbackMap[fieldName];

  const [description, setDescription] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setError(false);
    dataFetchHandler({
      id: fieldValue,
    })
      .then((val) => {
        setError(false);
        setDescription(val?.data?.description);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  if (loading) {
    return <Skeleton width={100} />;
  }

  if (error) {
    return null;
  }

  if (textOnly) {
    return <>{description}</>;
  }

  return (
    <div className="flex-1 w-48 truncate" title={description}>
      {description}
    </div>
  );
};

export default DescriptionColumn;
