import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useCustomSignalEventDefinitionsAttributes } from "shared/api/customSignalEvents/hooks";

export const CustomSignalEventDefinitionAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const CustomSignalEventDefinitionsAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useCustomSignalEventDefinitionsAttributes({});

  return (
    <CustomSignalEventDefinitionAttributeContext.Provider value={data}>
      {children}
    </CustomSignalEventDefinitionAttributeContext.Provider>
  );
};

export default CustomSignalEventDefinitionsAttributesContextWrapper;
