import { Alert } from "@mui/material";

import { CustomSignalEventDefinition } from "shared/api/customSignalEvents/api";

interface Props {
  customSignalEventDefinition: CustomSignalEventDefinition;
}

const ErrorSection = ({ customSignalEventDefinition }: Props) => {
  const { status, invalidationReason, backfillError } =
    customSignalEventDefinition;

  return (
    <Alert
      color="error"
      severity="error"
      data-testid="error-message"
      className=""
    >
      <div>
        <div className="font-bold mb-1">
          {status === "invalid" &&
            "An error occurred while validating definition"}
          {status === "error_during_backfill" &&
            "An error occurred during backfill process"}
        </div>
        <div>
          {status === "invalid" && invalidationReason}
          {status === "error_during_backfill" && backfillError}
        </div>
      </div>
    </Alert>
  );
};

export default ErrorSection;
