import { ServiceRecord } from "shared/api/serviceRecords/api";

export const GENERIC_DATA_TO_DISPLAY: {
  accessor: keyof ServiceRecord;
  type?: "date" | "string" | "number";
}[] = [
  {
    accessor: "type",
  },
  {
    accessor: "date",
    type: "date",
  },
  {
    accessor: "status",
  },
  {
    accessor: "notes",
  },
];

export const GENERIC_JSON_DATA_KEY = "data";
