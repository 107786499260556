import { VEHICLES_COLLECTION_FILTER } from "shared/filterDefinitions";

import Filters from "features/ui/Filters";
import { useFilterSortState } from "features/ui/Filters/hooks";
import Section from "features/ui/Section";

import FailureModeEvents from "./FailureModeEvents";
import FailureModeEventsCount from "./FailureModeEventsCount";
import FailureModeEventsTimeline from "./FailureModeEventsTimeline";
import FailureModeInScopeVehicleCount from "./FailureModeInScopeVehicleCount";

interface Props {
  failureModeID: string;
}

const GLOBAL_FILTERS = [VEHICLES_COLLECTION_FILTER];

const FailureModeDefinitionTab = ({ failureModeID }: Props) => {
  const {
    manageOnFilterChange,
    filters,
    initialized: filtersInitialized,
  } = useFilterSortState({
    pageKey: `failure_mode_definitions_tab_${failureModeID}`,
    disableUsingQuery: true,
  });

  return (
    <>
      <div>
        <Filters
          initialized={filtersInitialized}
          schema={GLOBAL_FILTERS}
          onFilterChange={manageOnFilterChange}
          filters={filters}
          horizontal
        />
      </div>
      <Section>
        <div className="lg:flex flex-1 lg:space-x-4 mb-5">
          <FailureModeInScopeVehicleCount
            parentFilters={filters}
            failureModeID={failureModeID}
          />
          <FailureModeEventsCount
            parentFilters={filters}
            failureModeID={failureModeID}
            eventType="repair"
          />
          <FailureModeEventsCount
            parentFilters={filters}
            failureModeID={failureModeID}
            eventType="failure"
          />
        </div>
      </Section>
      <FailureModeEventsTimeline
        parentFilters={filters}
        failureModeID={failureModeID}
      />
      <FailureModeEvents
        parentFilters={filters}
        failureModeID={failureModeID}
      />
    </>
  );
};

export default FailureModeDefinitionTab;
