import { useFlags } from "launchdarkly-react-client-sdk";

import { useGetIssueClaimBreakdownByCalendarTime } from "shared/api/issues/hooks";
import { useCustomLocalStorageState } from "shared/hooks";
import {
  IssueChart,
  IssueChartGroupBy,
  IssueChartType,
  IssueTypes,
} from "shared/types";
import { pluralize } from "shared/utils";

import GroupByChart from "pages/Issues/Charts/GroupByChart";
import { getChartOptionsKey, getPopulationString } from "pages/Issues/utils";

import Card from "features/ui/Card";
import {
  cumulativeCPV,
  cumulativeIPTV,
  rollingCPV,
  rollingIPTV,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions, {
  ChartAction,
  ChartActionOption,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import {
  getAxisValue,
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { Option } from "features/ui/Select";

import * as config from "config/config";

import { useOptionsForAccessors } from "./utils";

const claimBreakdownByCalendarTimeTitleGenerate = (
  yAxis: string,
  groupByAttribute: string,
  xAxisOptions: ChartActionOption<Option>[]
) => {
  const selectedOption = xAxisOptions.find((o) => o.id === groupByAttribute);
  const selectedOptionTitle = pluralize(
    selectedOption?.value.toString() || "Value",
    5
  );
  if ([cumulativeCPV.id, rollingCPV.id].includes(yAxis)) {
    return `Top 5 ${selectedOptionTitle} by Cost`;
  }
  return `Top 5 ${selectedOptionTitle} by Rate`;
};

const CHART_NAME: IssueChart = "Claims_TopXByCalendarTime";
const GROUP_BY_FIELD: IssueChartGroupBy = "groupByAttributeValue";
const CHART_TYPE: IssueChartType = "CalendarTime";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const TopXByCalendarTimeChart = ({
  issue,
  withComparisonPopulation,
}: Props) => {
  const { warrantyClaimsCost } = useFlags();

  const {
    pages: { issues },
  } = config.get();
  const legendAccessors = issues?.topXChartsOptions || [
    "laborCode",
    "failedPartNumber",
  ];
  const legendOptions = useOptionsForAccessors(legendAccessors);
  const actions: ChartAction[] = [
    {
      id: "legend",
      title: "Legend",
      type: "dropdownSelect",
      options: legendOptions,
      defaultOptionId: legendAccessors[0],
    },
    {
      id: "y",
      title: yAxis,
      type: "dropdownSelect",
      options: [
        cumulativeIPTV,
        rollingIPTV,
        warrantyClaimsCost && cumulativeCPV,
        warrantyClaimsCost && rollingCPV,
      ].filter(Boolean),
    },
  ];

  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID, "v4");
  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const groupByValue = getAxisValue(
    actions,
    "legend",
    getSelectedOptionId(selectedOptions, "legend")
  );

  const commonChartProps = {
    issue,
    chartType: CHART_TYPE,
    chart: CHART_NAME,
    dataLoadHook: useGetIssueClaimBreakdownByCalendarTime,
    selectedOptions,
    groupByField: GROUP_BY_FIELD,
    actions,
    syncId: CHART_NAME,
  };

  const chartTitle = claimBreakdownByCalendarTimeTitleGenerate(
    getSelectedOptionId(selectedOptions, "y"),
    getSelectedOptionId(selectedOptions, "legend"),
    legendOptions
  );

  const atRiskChartTitle = `${chartTitle} : ${getPopulationString("atRisk")}`;
  const comparisonChartTitle = `${chartTitle} : ${getPopulationString(
    "comparison"
  )}`;

  return (
    <Card>
      <div className="space-y-5">
        <div>
          <ChartActionsWrap
            id={CHART_NAME}
            chartTitle={atRiskChartTitle}
            subTitle={groupByValue}
          >
            <ChartActions
              actions={actions}
              selectedOptions={selectedOptions}
              onOptionChange={setSelectedOptions}
            />
          </ChartActionsWrap>
          <GroupByChart {...commonChartProps} population="atRisk" />
        </div>
        {withComparisonPopulation && (
          <div>
            <ChartActionsWrap
              id={CHART_NAME}
              chartTitle={comparisonChartTitle}
              subTitle={groupByValue}
              showActions={false}
            />
            <GroupByChart {...commonChartProps} population="comparison" />
          </div>
        )}
      </div>
    </Card>
  );
};

export default TopXByCalendarTimeChart;
