import { getTopLevelRowFromFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import FilterDescriptionTable from "features/ui/Filters/other/FilterDescriptionTable";
import FLabel from "features/ui/Filters/other/FLabel";
import {
  FilterOperator,
  FilterOverviewFormat,
  FilterValue,
  RelatesFilterState,
} from "features/ui/Filters/types";
import Modal from "features/ui/Modal";

import { SIGNAL_EVENT_ID_FIELD_NAME } from "./constants";

const SIGNAL_EVENTS_SHOW_LIMIT = 3;

interface Props {
  relates: RelatesFilterState;
  format: FilterOverviewFormat;
  fieldNameFormatted: string;
  showValuesIfMultiple: boolean;
  noModalForMultipleValues: boolean;
  baseEntityText?: string;
  dense?: boolean;
  relatesBeforeNow?: boolean;
}

const RelatesFilterLabel = ({
  fieldNameFormatted,
  relates,
  format,
  showValuesIfMultiple,
  noModalForMultipleValues,
  baseEntityText = "Base Object",
  relatesBeforeNow = false,
  dense,
}: Props) => {
  const { filters, options, operator: relatesOperator } = relates;

  const childRow = getTopLevelRowFromFilterGroupState(
    SIGNAL_EVENT_ID_FIELD_NAME,
    filters
  );

  const values = childRow?.values || [];
  const operator = childRow?.operator || FilterOperator.IN;

  let defaultLabel: FilterValue = valuesToLabel(values);

  if (!showValuesIfMultiple && operator === FilterOperator.IN) {
    defaultLabel = (noModalForMultipleValues && (
      <span>{valuesToLabel(values)} values</span>
    )) || (
      <Modal
        triggerElement={valuesToLabel(values)}
        triggerElementClassNames="text-blue-500 hover:underline"
        children={[
          <div className="p-4" key={values.toString()}>
            <FilterDescriptionTable
              title={fieldNameFormatted}
              values={values}
              fieldName={SIGNAL_EVENT_ID_FIELD_NAME}
              searchString=""
            />
          </div>,
        ]}
      />
    );
  }

  return (
    <FLabel
      key={fieldNameFormatted}
      fieldName={fieldNameFormatted}
      dense={dense}
      operator={operator}
      fieldValue={
        <>
          <strong>{defaultLabel}</strong>
          <br /> {opearatorToLabel(relatesOperator)} within{" "}
          {relatesBeforeNow && "the last "}
          <strong>
            {options.windowSize} days{" "}
            {!relatesBeforeNow &&
              windowDirectionToLabel(options.windowDirection as string)}
          </strong>{" "}
          {baseEntityText}
        </>
      }
      format={format}
    />
  );
};

const valuesToLabel = (values: string[]) => {
  if (values.length <= SIGNAL_EVENTS_SHOW_LIMIT) {
    return values.join(", ");
  }

  return `${values.slice(0, SIGNAL_EVENTS_SHOW_LIMIT - 1).join(", ")}, ${
    values.length - SIGNAL_EVENTS_SHOW_LIMIT + 1
  }  more...`;
};

const opearatorToLabel = (operator: string) => {
  if (operator === "noccurs") {
    return "does not occur";
  }
  return operator;
};

const windowDirectionToLabel = (windowDirection: string) => {
  if (windowDirection === "both") {
    return "before or after";
  }
  return windowDirection;
};

export default RelatesFilterLabel;
