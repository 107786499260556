import { useEffect, useState } from "react";

import { createEmbeddingRequest, getEmbeddingURL } from "./utils";

export const useDashboardEmbedURL = (
  dashboard?: string,
  params: object = {},
  options: object = {},
  usesLegacyUrlEmbeedingService: boolean = true
) => {
  const [url, setURL] = useState("");

  useEffect(() => {
    if (!dashboard) {
      return;
    }

    const resource = { dashboard: parseInt(dashboard) };
    const request = createEmbeddingRequest(resource, params, options);
    getEmbeddingURL(request, usesLegacyUrlEmbeedingService).then((resp) => {
      setURL(resp);
    });
  }, [dashboard, params, options, usesLegacyUrlEmbeedingService]);

  return url;
};
