import BackToIssueBreadcrumb from "pages/Issues/BackToIssueBreadcrumb";
import {
  ISSUE_ID_QUERY_KEY,
  ISSUE_IS_SUGGESTED_QUERY_KEY,
  ISSUE_NAME_QUERY_KEY,
  ISSUE_RUN_DATE_QUERY_KEY,
} from "pages/Issues/constants";

import { filterStateToFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFiltersKey } from "features/ui/Filters/utils";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import * as config from "config/config";

import BackToSingleClaimBreadCrumb from "./BackToSingleClaimBreadCrumb";
import ClaimAnalyticsFilters from "./ClaimAnalyticsFilters";
import ClaimAnalyticsTabs from "./ClaimAnalyticsTabs";
import ConfirmationModalIssueUpdate from "./ConfirmationModalIssueUpdate";
import { CLAIMS_PAGE_KEY, PAGE_TITLE, VEHICLES_PAGE_KEY } from "./constants";

const QUERY_PARAMS_TO_PERSIST_THROUGH_TABS = [
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
]
  .map(getFiltersKey)
  .concat([
    ISSUE_ID_QUERY_KEY,
    ISSUE_NAME_QUERY_KEY,
    ISSUE_IS_SUGGESTED_QUERY_KEY,
    ISSUE_RUN_DATE_QUERY_KEY,
  ]);

const ClaimAnalytics = () => {
  const { pages } = config.get();

  const defaultClaimFilters = filterStateToFilterGroupState(
    pages.claimAnalytics?.defaultClaimFilters
  );
  const claimsFiltersFilterSortState = useFilterSortState({
    pageKey: CLAIMS_PAGE_KEY,
    defaultFilterValues: defaultClaimFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(CLAIMS_PAGE_KEY),
  });

  const defaultVehicleFilters = filterStateToFilterGroupState(
    pages.claimAnalytics?.defaultVehicleFilters
  );
  const vehiclePopulationFiltersFilterSortState = useFilterSortState({
    pageKey: VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(VEHICLES_PAGE_KEY),
  });

  const resetState = () => {
    claimsFiltersFilterSortState?.resetFilterSortState();
    vehiclePopulationFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <ConfirmationModalIssueUpdate
        page="claimAnalytics"
        vehicleFilters={vehiclePopulationFiltersFilterSortState.filters}
        claimFilters={claimsFiltersFilterSortState.filters}
      />
      <PageHeaderWrapper>
        <div className="flex flex-col">
          <BackToIssueBreadcrumb />
          <BackToSingleClaimBreadCrumb />
          <Title text={PAGE_TITLE} docsSectionId="claim-analytics" />
        </div>
      </PageHeaderWrapper>
      <ClaimAnalyticsFilters
        claimsFilterSortState={claimsFiltersFilterSortState}
        vehiclesFilterSortState={vehiclePopulationFiltersFilterSortState}
        defaultClaimFilters={defaultClaimFilters}
        defaultVehicleFilters={defaultVehicleFilters}
      />
      <ClaimAnalyticsTabs
        claimsFiltersFilterSortState={claimsFiltersFilterSortState}
        vehiclesFiltersFilterSortState={vehiclePopulationFiltersFilterSortState}
        queryParamsToPersist={QUERY_PARAMS_TO_PERSIST_THROUGH_TABS}
        onBadRequest={resetState}
      />
    </>
  );
};

export default ClaimAnalytics;
