import classNames from "classnames";

import { TestProps } from "shared/types";
import { cloneObject } from "shared/utils";

import { DEFAULT_FILTER_TYPE } from "features/ui/Filters/constants";
import { encodeRelatesFilter } from "features/ui/Filters/FilterTypes/RelatesFilter/utils";
import { FilterOperator, RelatesFilterState } from "features/ui/Filters/types";
import { getFirstAvailableSelectedOperator } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

import { DEFAULT_FILTER_BUILDER_STATE } from "./constants";
import FilterGroup from "./FilterGroup";
import { AnyAll } from "./FilterGroup/AnyAllSelect";
import { FilterGroupState, FilterRowState } from "./types";
import {
  deleteFromGroupByID,
  EditableRowProperty,
  insertInGroupAfterID,
  updateGroupAnyAllByID,
  updateRowPropertyByID,
} from "./utils";

export interface Props extends TestProps {
  filterBuilderState?: FilterGroupState;
  filtersSchema: SchemaEntry<string>[];
  defaultFilters?: FilterGroupState;
  onChange: (filterState: FilterGroupState) => void;
  inline?: boolean;
  disabled?: boolean;
  attributePlaceholder?: string;
}

const FilterBuilder = ({
  filterBuilderState = DEFAULT_FILTER_BUILDER_STATE,
  filtersSchema,
  defaultFilters,
  onChange,
  inline = false,
  disabled,
  attributePlaceholder,
  testId = "filter-builder",
}: Props) => {
  const addNewRow = (id: string) => {
    onChange(insertInGroupAfterID(filterBuilderState, id, "row"));
  };

  const addNewGroup = (id: string) => {
    onChange(insertInGroupAfterID(filterBuilderState, id, "group"));
  };

  const onDelete = (id: string) => {
    onChange(deleteFromGroupByID(filterBuilderState, id));
  };

  const onGroupOperatorChange = (id: string, anyAll: AnyAll) => {
    onChange(updateGroupAnyAllByID(filterBuilderState, id, anyAll));
  };

  const onResetToDefault = () => {
    onChange(defaultFilters || DEFAULT_FILTER_BUILDER_STATE);
  };

  const onRowDataChange = (
    id: string,
    property: EditableRowProperty,
    values?: string | string[] | RelatesFilterState
  ) => {
    let newFilterState = cloneObject(filterBuilderState);

    // if we are changing the attribute, we need to reset operator and values for this id before applying this change
    if (property === "attribute") {
      // get default operator for this attribute
      // TODO: this doesn't work for "relates" filter. When we change the attribute in the builder, this means that
      // when filter type is "relates" the value inputs won't show until we manually select the "operator" dropdown again.
      // When property = "relates" this needs to actually change the operator INSIDE "relates" not the top-level operator which is "occurs"
      // Maybe we can just call onRowDataChange again with "relates" as property and relevant data?
      // How do we figure out if this is "relates" filter?
      // if it is, we'd call onChange() immediately, and then onRowDataChange() again with "relates" as property and relevant data?
      // Can we just rewrite relates filters alltogether instead or ditch them? They seem to be very unintuitive in comparison to other filters.
      const attributeType =
        filtersSchema.find((schemaEntry) => schemaEntry.accessor === values)
          ?.filter?.filterType || DEFAULT_FILTER_TYPE;

      const defaultOperator = getFirstAvailableSelectedOperator(
        null,
        attributeType,
        filtersSchema.find((schemaEntry) => schemaEntry.accessor === values)
      );

      newFilterState = updateRowPropertyByID(
        newFilterState,
        id,
        "operator",
        defaultOperator
      );

      newFilterState = updateRowPropertyByID(newFilterState, id, "values", []);
      newFilterState = updateRowPropertyByID(newFilterState, id, "relates");
    }

    // if operator is is_empty or is_not_empty, we need to set values to "null"
    if (
      property === "operator" &&
      (values === FilterOperator.IS_EMPTY ||
        values === FilterOperator.IS_NOT_EMPTY)
    ) {
      newFilterState = updateRowPropertyByID(newFilterState, id, "values", [
        "null",
      ]);
    }

    // if relates filter is updated, update values and operator as well
    if (property === "relates") {
      // is_empty/is_not_empty handling for "relates" filter needs to be done differently - make sure to set "values" to ["null"]
      const op = (
        (values as RelatesFilterState).filters.children[0] as FilterRowState
      ).operator;

      if (
        op === FilterOperator.IS_EMPTY ||
        op === FilterOperator.IS_NOT_EMPTY
      ) {
        //  TODO: ugly because we're modifying reference to values ..
        (
          (values as RelatesFilterState).filters.children[0] as FilterRowState
        ).values = ["null"];
      }

      const encodedRelatesFilter = [
        encodeRelatesFilter(values as RelatesFilterState),
      ];

      newFilterState = updateRowPropertyByID(
        newFilterState,
        id,
        "operator",
        FilterOperator.RELATES
      );
      newFilterState = updateRowPropertyByID(
        newFilterState,
        id,
        "values",
        encodedRelatesFilter
      );

      newFilterState = updateRowPropertyByID(
        newFilterState,
        id,
        "relates",
        values
      );
    }

    onChange(updateRowPropertyByID(newFilterState, id, property, values));
  };

  return (
    <div
      data-testid={testId}
      className={classNames("py-1 w-full", {
        "px-3 border-t h-[35rem]": !inline,
      })}
    >
      <FilterGroup
        key={filterBuilderState.id}
        id={filterBuilderState.id}
        depth={0}
        children={filterBuilderState.children}
        anyAll={filterBuilderState.anyAll}
        onNewRow={addNewRow}
        onNewGroup={addNewGroup}
        onDelete={onDelete}
        onGroupOperatorChange={onGroupOperatorChange}
        onRowDataChange={onRowDataChange}
        schema={filtersSchema}
        onResetToDefault={onResetToDefault}
        disabled={disabled}
        attributePlaceholder={attributePlaceholder}
      />
    </div>
  );
};

export default FilterBuilder;
