import { useFlags } from "launchdarkly-react-client-sdk";

import { useGetIssueClaimByCalendarTime } from "shared/api/issues/hooks";
import { useCustomLocalStorageState } from "shared/hooks";
import { IssueChart, IssueTypes } from "shared/types";

import ChartWithComparisonPopulation from "pages/Issues/Charts/ChartWithComparisonPopulation";
import PopulationChart from "pages/Issues/Charts/PopulationChart";
import {
  getAxisKeyLabelFromActions,
  getChartOptionsKey,
} from "pages/Issues/utils";

import Card from "features/ui/Card";
import {
  cumulativeCPV,
  cumulativeIPTV,
  rollingCPV,
  rollingIPTV,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions, {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import { getDefaultActions } from "features/ui/charts/utils";

const CHART_NAME: IssueChart = "Claims_OccurrencesByCalendarTime";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const claimRateByCalendarTimeTitleGenerate = (yAxis: string) => {
  switch (yAxis) {
    case cumulativeCPV.id:
    case rollingCPV.id:
      return "Claim Cost by Date";
    case cumulativeIPTV.id:
    case rollingIPTV.id:
    default:
      return "Claim Rate by Date";
  }
};

const OccurrencesByCalendarTimeChart = ({
  issue,
  withComparisonPopulation,
}: Props) => {
  const { warrantyClaimsCost } = useFlags();

  const actions: ChartAction[] = [
    {
      id: "y",
      title: yAxis,
      type: "dropdownSelect",
      options: [
        cumulativeIPTV,
        rollingIPTV,
        warrantyClaimsCost && cumulativeCPV,
        warrantyClaimsCost && rollingCPV,
      ].filter(Boolean),
    },
  ];

  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID);
  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const { axisKey: yAxisKey, axisValue: yAxisValue } =
    getAxisKeyLabelFromActions(selectedOptions, actions, "y");

  const PopulationsChart = withComparisonPopulation
    ? ChartWithComparisonPopulation
    : PopulationChart;

  const chartTitle = claimRateByCalendarTimeTitleGenerate(yAxisKey);

  return (
    <Card>
      <ChartActionsWrap id={CHART_NAME} chartTitle={chartTitle}>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      <PopulationsChart
        issue={issue}
        yAxisKey={yAxisKey}
        yAxisLabel={yAxisValue}
        chartType="CalendarTime"
        chart={CHART_NAME}
        dataLoadHook={useGetIssueClaimByCalendarTime}
      />
    </Card>
  );
};

export default OccurrencesByCalendarTimeChart;
