import {
  AxiosRequestHeaders,
  AxiosResponse,
  AxiosResponseHeaders,
} from "axios";

import { EventTypeEnum, MileageUnit, PermissionID } from "shared/types";

import client, { clientV1, clientWithoutV0Prefix } from "./axios";
import { createURL } from "./utils";
import { Vehicle } from "./vehicles/api";

export type APIHeaders = Record<string, string> & {
  link?: string;
};

export interface APIPaginatedRequest {
  limit?: number;
  before?: string;
  after?: string;
  filter?: string;
  sort?: string;
}

interface APIListResponse<Data> {
  data: Data;
}

export interface APISuccessResponse<Data> {
  data: Data;
  headers: AxiosResponseHeaders;
}

export interface APIErrorResponse {
  code: string;
  message: string;
  response?: {
    status: number;
    statusText: string;
  };
}

export interface APIListValuesRequest {
  fieldName: string;
  like?: string;
  limit?: number;
  filter?: string;
  claimFilter?: string;
}

export interface APIListValuesFailureModeEventRequest
  extends APIListValuesRequest {
  failureModeId: string;
}
export interface APIListValuesResponse {
  fieldName: string;
  like?: string;
  distinctValues: (string | null)[];
}

export const getFetcher = <Data>(
  path: string,
  headers?: object
): Promise<APISuccessResponse<Data>> =>
  client.get(path, headers).then(manageFetcherResponse);

export const postFetcher = <Data>(
  path: string,
  data: object,
  headers?: AxiosRequestHeaders
): Promise<APISuccessResponse<Data>> =>
  client.post(path, data, { headers }).then(manageFetcherResponse);

export const patchFetcher = <Data>(
  path: string,
  data: object,
  headers?: AxiosRequestHeaders
): Promise<APISuccessResponse<Data>> =>
  client.patch(path, data, { headers }).then(manageFetcherResponse);

export const putFetcher = <Data>(
  path: string,
  data: object,
  headers?: AxiosRequestHeaders
): Promise<APISuccessResponse<Data>> =>
  client.put(path, data, { headers }).then(manageFetcherResponse);

const manageFetcherResponse = <Data extends object>({
  data,
  headers,
}: AxiosResponse<Data | APIListResponse<Data>>): APISuccessResponse<Data> => {
  // always flatten API responses with data as the ONLY top-level field
  // Typescript respects the "in" to understand data is type APIListResponse<Data>
  // - also check if data is not "empty/null" first, as some PATCH request can return 204 - No Content
  if (data && "data" in data && Object.keys(data).length === 1) {
    return {
      data: data.data,
      headers: headers as AxiosResponseHeaders,
    };
  }

  return {
    data: data as Data,
    headers: headers as AxiosResponseHeaders,
  };
};

export interface ListAttributesRequest {
  filter?: string;
}

export type Predictions = Record<string, Prediction> | null;

interface Prediction {
  riskGroup: Risk;
  riskMultiple: number | null;
  prediction: number | null;
  riskStatus: string | null;
  repairStatus: string | null;
  repairDetails: string | null;
}

export type Risk = "low" | "medium" | "high" | null;

// Vehicles ECUs
export interface VehicleECU {
  ECUID: string;
  VIN: string;
  createdAt: string;
  updatedAt: string;
  calibration: string | null;
  configuration: string | null;
  hardware: string | null;
  program: string | null;
  serialNumber: string | null;
  software: string | null;
  supplier: string | null;
}

export const listVehiclesECUsRequestURI = ({
  ...params
}: APIPaginatedRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["vehicleECUs"]),
    params,
  });

export interface CountResponse {
  count: number;
}

export interface CountRequest {
  filter?: string;
}

// ---------
// Export CSV / EXCEL (download)
// ---------
export type ExportFileType = "xlsx" | "csv";

export interface ExportRequest extends APIPaginatedRequest {
  type: ExportFileType;
  // we need this for export URLs that need an ID in between, like issues/:ID/signalEvents/export/xlsx
  IDs: string[];
}
// ---------
// MAINTENANCE SCHEDULES + Vehicle Categories & Transport Categories
// ---------

export interface MaintenanceSchedule {
  service: string;
  miles?: number;
  kilometers?: number;
  hours?: number;
  months?: number;
}

export interface GetMaintenanceScheduleRequest extends APIPaginatedRequest {}

export const listMaintenanceScheduleRequestURI = ({
  ...params
}: GetMaintenanceScheduleRequest) =>
  clientV1.getUri({
    method: "get",
    url: `/maintenanceScheduleServices`,
    params,
  });

// Transport Categories

// used for both Vehicle Category & Transport Category
export interface VehicleGenericCategory {
  ID: string;
  name: string;
  description?: string;
}

export interface GetApiIdRequest {
  id: string;
}

export const getTransportCategoryRequestURI = ({ id }: GetApiIdRequest) =>
  clientV1.getUri({
    method: "get",
    url: createURL(["transportCategories", id]),
  });

export const getVehicleCategoryRequestURI = ({ id }: GetApiIdRequest) =>
  client.getUri({
    method: "get",
    url: createURL(["vehicleCategories", id]),
  });

export const getTransportCategory = (args: GetApiIdRequest) =>
  getFetcher<VehicleGenericCategory>(getTransportCategoryRequestURI(args));

export const listTransportCategoriesRequestURI = () =>
  clientV1.getUri({
    method: "get",
    url: `/transportCategories`,
  });

export const listTransportCategories = () =>
  getFetcher<VehicleGenericCategory[]>(listTransportCategoriesRequestURI());

// Vehicle Categories
export const listVehicleCategoriesRequestURI = (): string =>
  clientV1.getUri({
    method: "get",
    url: `/vehicleCategories`,
  });

// ---------
// SIMILAR VEHICLES
// ---------

export interface SimilarVehicle {
  currentVIN: string;
  similarVIN: string;
  failureModeID: string;
  date: string;
  similarity: number;
}

export interface ListSimilarVehiclesRequest extends APIPaginatedRequest {}

export const listSimilarVehiclesRequestURI = ({
  ...params
}: ListSimilarVehiclesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["similarVehicles"]),
    params,
  });

// ---------
// Fleets
// ---------
export interface ListFleetsRequest extends APIPaginatedRequest {}

export interface Fleet {
  name: string;
  numClaimsLastPeriod: number;
  numVehicles: number;
  percentageClaimsLastPeriod: number;
  timePeriodDays: number;
}

export interface EntityAttribute {
  ID: string;
  displayName: string;
  columnName: string | null;
  dataExplorerColumnName: string | null;
  description: string | null;
  type: string;
  nullable: boolean;
  filtering: boolean;
  grouping: boolean;
  sorting: boolean;
  values: boolean;
  // endpoint where details of the parameter can be retrieved
  relationEndpoint?: string | null;
  filteringConfig: EntityAttributeFiltering;
  // endpoint returns this field in response. If set to false, it is only available for filtering
  inJSONBody: boolean;
  // draws wider column in table (w-80)
  displayWideColumn: boolean;
  // will prevent showing this attribute in tables, but keep displaying it elsewhere (ie. filters, grouping ..)
  hideInTable: boolean;
  // hides the attribute's filter (in top-level filters & inside tables)
  hideFilter: boolean;
  byVehicleAgeBirthday: boolean;
  byVehicleAgeExposure: boolean;
  byVehicleAgeExposureBuckets: number[];
}

interface EntityAttributeFiltering {
  negativeNumbers: boolean;
  decimalNumbers: boolean;
  contains: boolean;
  empty: boolean;
  startsWith: boolean;
  // indicates that there is a low number of distinct values, suggesting that we load them on open
  lowCardinality: boolean;
  // enables support for min/max filtering in "IN" / "NOT_IN" filters on date or numeric attributes
  minMax: boolean;
}

interface Prediction {
  riskGroup: Risk;
  riskMultiple: number | null;
  prediction: number | null;
  riskStatus: string | null;
}

export const listFleetsRequestURI = ({
  ...params
}: ListFleetsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: `/fleets`,
    params,
  });

export const listFleetCountRequestURI = ({ ...params }: CountRequest): string =>
  clientV1.getUri({
    method: "get",
    url: `/fleets/count`,
    params,
  });

const listVehicleTagsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["vehicleTags", "values", fieldName]),
    params,
  });

export const listVehicleTagsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listVehicleTagsValuesRequestURI(args));

const listVehicleECUsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["vehicleECUs", "values", fieldName]),
    params,
  });

export const listVehicleECUsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listVehicleECUsValuesRequestURI(args));

// ---------
// Survival curves
// ---------
export interface SurvivalCurve {
  distance: number;
  probability: number;
}

export interface SurvivalCurveResponse {
  data: SurvivalCurve[];
  failureMileage99Percentile: number | null;
  failureMileageAverage: number | null;
}

export interface GetSurvivalCurvesRequest {
  failureModeID: string;
  distanceUnit: MileageUnit;
}

export const getSurvivalCurvesRequestURI = ({
  failureModeID,
  ...params
}: GetSurvivalCurvesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", failureModeID, "survivalCurve"]),
    params,
  });

// ---------
// Authentication
// ---------
export interface AuthenticationToken {
  accessToken: string;
  tokenType: string;
  refreshToken: string;
  expiry: string;
}

export const getAuthenticationTokenRequestURI = (): string =>
  client.getUri({
    method: "get",
    url: `/authentication/token`,
  });
// ---------
// Healthcheck
// ---------
export interface Healthcheck {
  env: string;
  service: string;
  version: string;
}

export const getHealthcheckRequestURI = () =>
  clientWithoutV0Prefix.getUri({
    method: "get",
    url: "/healthcheck",
  });

// ---------
// Collections
// ---------
export interface ListCollectionsRequest extends APIPaginatedRequest {}

export type CollectionType = "static" | "dynamic";

export interface Collection {
  ID: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  type?: CollectionType;
  filter: string | null;
  access: PermissionEntry[];
  createdBy: string;
  updatedBy: string;
  canEdit: boolean;
}

export const listVehicleCollectionsRequestURI = ({
  ...params
}: ListCollectionsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: `/collections`,
    params,
  });

export const listVehicleCollections = (args: ListCollectionsRequest) =>
  getFetcher<Collection[]>(listVehicleCollectionsRequestURI(args));

export const listVehicleCollectionsCountRequestURI = ({
  ...params
}: CountRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["collections", "count"]),
    params,
  });

// Create collection
export interface NewCollectionRequest {
  name: string;
  type?: CollectionType;
  filter: string;
}

interface NewCollectionFromVINsRequest {
  name: string;
  vins: string[];
}

interface NewCollectionResponse {
  ID: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  type: CollectionType;
  filter: string | null;
  invalidVINs: string[];
}

const newCollectionRequestURI = ({
  name,
  ...params
}: NewCollectionRequest): string =>
  clientV1.getUri({
    method: "POST",
    url: `/collections`,
    params,
  });

const newCollectionFromVINSRequestURI = ({
  name,
  vins,
  ...params
}: NewCollectionFromVINsRequest): string =>
  clientV1.getUri({
    method: "POST",
    url: `/collections/staticFromVINs`,
    params,
  });

export const newCollection = ({ filter, ...args }: NewCollectionRequest) =>
  postFetcher<NewCollectionResponse>(
    newCollectionRequestURI({ ...args, filter }),
    args
  );

export const newCollectionFromVINS = ({
  ...args
}: NewCollectionFromVINsRequest) =>
  postFetcher<NewCollectionResponse>(
    newCollectionFromVINSRequestURI({ ...args }),
    args
  );

// get specific collection
interface GetCollectionRequest {
  id: string;
}

const getCollectionRequestURI = ({ id }: GetCollectionRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["collections", id]),
  });

export const getCollection = (args: GetCollectionRequest) =>
  getFetcher<Collection>(getCollectionRequestURI(args));

// generic request definitions
export interface GetRequest {
  id: string;
}

export interface DeleteRequest {
  id: string;
}

// Delete collection
interface UpdateCollectionAccessRequest
  extends Partial<UpdatePermissionRequest> {
  ID: string;
}

const updateCollectionAccessRequestURI = ({
  ID,
  ...params
}: UpdateCollectionAccessRequest): string =>
  clientV1.getUri({
    method: "PATCH",
    url: createURL(["collections", ID, "access"]),
    params,
  });

export const updateCollectionAccess = ({
  ID,
  ...args
}: UpdateCollectionAccessRequest) =>
  patchFetcher<PermissionEntry[]>(
    updateCollectionAccessRequestURI({ ID }),
    args
  );

const deleteCollectionRequestURI = ({ id }: DeleteRequest): string =>
  clientV1.getUri({
    method: "DELETE",
    url: createURL(["collections", id]),
  });

export const deleteCollection = (args: DeleteRequest) =>
  clientV1.delete(deleteCollectionRequestURI(args));

const listVehicleCollectionsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["collections", "values", fieldName]),
    params,
  });

export const listVehicleCollectionsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(
    listVehicleCollectionsValuesRequestURI(args)
  );

// ---------
// Failure modes
// ---------
export type FailureModeEventType = "repair" | "failure";

export interface ListFailureModesRequest extends APIPaginatedRequest {
  skipRequest?: boolean;
}

export interface GetFailureModeRequest {
  id: string;
}

export interface ListFailureModesVehiclesRequest extends APIPaginatedRequest {
  id: string;
  mileageUnit: MileageUnit;
}

export interface ListFailureModesEventsRequest extends APIPaginatedRequest {
  id: string;
}

export interface ListFailureModesPredictionsRequest
  extends APIPaginatedRequest {}

export interface FailureMode {
  ID: string;
  name: string;
  description: string;
  status: string;
}

export interface FailureModeEvent {
  ID: string;
  VIN: string;
  eventTimestamp: string;
  eventType: FailureModeEventType;
  failureModeID: string;
  eventSourceID: string;
  eventSourceType: string;
  vehicle: Vehicle;
}

export interface FailureModePrediction {
  VIN: string;
  failureModeID: string;
  prediction: number;
  riskGroup: string;
  riskMultiple: number;
  riskStatus: string | null;
  repairStatus: string | null;
  repairDetails: string | null;
  warrantyActive: boolean | null;
}

export const listFailureModesRequestURI = ({
  skipRequest,
  ...params
}: ListFailureModesRequest): string =>
  skipRequest
    ? ""
    : client.getUri({
        method: "get",
        url: `/failureModes`,
        params,
      });

export const listFailureModes = (args: ListFailureModesRequest) =>
  getFetcher<FailureMode[]>(listFailureModesRequestURI(args));

export const getFailureModeRequestURI = ({
  id,
}: GetFailureModeRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", id]),
  });

export const getFailureMode = (args: GetFailureModeRequest) =>
  getFetcher<FailureMode>(getFailureModeRequestURI(args));

export const listFailureModeVehiclesRequestURI = ({
  id,
  ...params
}: ListFailureModesVehiclesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", id, "vehicles"]),
    params,
  });

export const listFailureModeEventsRequestURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", id, "events"]),
    params,
  });

export const getFailureModeEventsCountRequestURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", id, "events", "count"]),
    params,
  });

export const getFailureModeVehiclesCountRequestURI = ({
  id,
  ...params
}: ListFailureModesEventsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", id, "vehicles", "count"]),
    params,
  });

export const listFailureModePredictionsRequestURI = (
  params: ListFailureModesPredictionsRequest
): string =>
  client.getUri({
    method: "get",
    url: `/failureModePredictions`,
    params,
  });

export const listFailureModePredictionsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(
    listFailureModePredictionsValuesRequestURI(args)
  );

const listFailureModePredictionsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModePredictions", "values", fieldName]),
    params,
  });

export const listFailureModeEventsValues = (
  args: APIListValuesFailureModeEventRequest
) =>
  getFetcher<APIListValuesResponse>(
    listFailureModeEventsValuesRequestURI(args)
  );

const listFailureModeEventsValuesRequestURI = ({
  fieldName,
  failureModeId,
  ...params
}: APIListValuesFailureModeEventRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([
      "failureModes",
      failureModeId,
      "events",
      "values",
      fieldName,
    ]),
    params,
  });

export const listFailureModePredictionsCountRequestURI = (
  params: CountRequest
): string =>
  client.getUri({
    method: "get",
    url: "/failureModePredictions/count",
    params,
  });

const getFailureModePredictionsExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModePredictions", "export", type]),
    params,
  });

export const getFailureModePredictionsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getFailureModePredictionsExportRequestURI(args), {
    responseType: "blob",
  });

export type FailureModeEventTimelineGrouping = "day" | "month";
export interface FailureModeEventsTimelineRequest {
  failureModeID: string;
  grouping?: FailureModeEventTimelineGrouping;
  filter?: string;
}

export interface FailureModeEventsTimelineBucket {
  group: string;
  countFailures: number;
  countRepairs: number;
}

export const getFailureModeEventsTimelineURI = ({
  failureModeID,
  ...params
}: FailureModeEventsTimelineRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", failureModeID, "eventsTimeline"]),
    params,
  });

interface FailureModeVehiclesExportRequest extends APIPaginatedRequest {
  type: ExportFileType;
  failureModeID: string;
}

const getFailureModeVehiclesExportRequestURI = ({
  type = "xlsx",
  failureModeID,
  ...params
}: FailureModeVehiclesExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["failureModes", failureModeID, "vehicles", "export", type]),
    params,
  });

export const getFailureModeVehiclesExport = (
  args: FailureModeVehiclesExportRequest
) =>
  getFetcher<Blob>(getFailureModeVehiclesExportRequestURI(args), {
    responseType: "blob",
  });

export interface Dealer {
  ID: string;
  name: string;
  createdAt: string;
  city: string | null;
  countryCode: string | null;
  provinceCode: string | null;
  updatedAt: string;
}

export interface FailureModeCampaignEventsTimelineBucket {
  group: string;
  countFailures: number;
  countRepairs: number;
}

interface GroupData {
  name: string;
  description: string;
}

export interface PermissionEntry {
  ID: string;
  email?: string;
  everyone?: boolean;
  access: PermissionID;
  originalAccess?: PermissionID;
  groupID?: string;
  group?: GroupData;
  shown?: boolean;
}

interface CreatePermissionEntry {
  email?: string;
  everyone?: boolean;
  access: PermissionID;
}

interface UpdatePermissionEntry {
  ID: string;
  access: PermissionID;
}

export interface UpdatePermissionRequest {
  create: CreatePermissionEntry[];
  update: UpdatePermissionEntry[];
  remove: string[];
}

export interface DescriptionObject {
  ID: string;
  description: string;
}

// ---------
// Labor codes
// ---------

export interface LaborCode extends DescriptionObject {}

const LABOR_CODES_BASE_ROUTE = "laborCodes";

export interface ListLaborCodesRequest extends APIPaginatedRequest {}

export const listLaborCodesURI = (params: ListLaborCodesRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([LABOR_CODES_BASE_ROUTE]),
    params,
  });

interface GetLaborCodeRequest {
  id: string;
}

const getLaborCodeURI = ({ id }: GetLaborCodeRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([LABOR_CODES_BASE_ROUTE, id]),
  });

export const getLaborCode = (args: GetLaborCodeRequest) =>
  getFetcher<LaborCode>(getLaborCodeURI(args));

// ---------
// Parts
// ---------

export interface Part extends DescriptionObject {}

const PARTS_BASE_ROUTE = "parts";

export interface ListPartsRequest extends APIPaginatedRequest {}

export const listPartsURI = (params: ListPartsRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([PARTS_BASE_ROUTE]),
    params,
  });

export interface GetPartRequest {
  id: string;
}

const getPartURI = ({ id }: GetPartRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL([PARTS_BASE_ROUTE, id]),
  });

export const getPart = (args: GetPartRequest) =>
  getFetcher<Part>(getPartURI(args));

export const listVehicleECUsAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["vehicleECUs", "attributes"]),
    params,
  });

export interface ECU {
  ID: string;
  description: string;
  status: string;
}

export const listECUsRequestURI = (params: APIPaginatedRequest): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["ECUs"]),
    params,
  });

export interface ByVehicleAgeAgeRequest {
  byVehicleAgeBirthday: string;
  byVehicleAgeExposure: string;
  granularity: string;
}

export interface TopContributorsAgeRequest {
  byVehicleAgeExposure?: string;
  byVehicleAgeExposureBucket?: number;
}

export interface EventType {
  type: EventTypeEnum;
  name: string;
  resourceEndpoint: string;
  attributeEndpoint: string;
  VINAttribute: string;
  dateAttribute: string;
  mileageAttribute: string;
  engineHoursAttribute: string;
}

export const listEventRegistryURI = (): string =>
  clientV1.getUri({
    method: "get",
    url: createURL(["eventRegistry"]),
  });
