import Skeleton from "react-loading-skeleton";

import { SignalEventVehicleAgeTimeline } from "shared/api/signalEvents/api";
import { useListSignalEventsByVehicleAgeTimeline } from "shared/api/signalEvents/hooks";
import { NO_DATA } from "shared/constants";

import { SignalEventsByVehicleAgeChartProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";
import {
  generateByVehicleAgeYAxisLines,
  prepareByVehicleAgeDataByTimestamp,
} from "pages/utils";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import LineChart from "features/ui/charts/LineChart";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

import ChartTooltip from "./ChartTooltip";

const ByVehicleAgeChart = ({
  signalEventsFilters,
  vehiclesFilters,
  onBadRequest,
  actions,
  byVehicleAgeData,
  selectedOptions,
  setSelectedOptions,
}: SignalEventsByVehicleAgeChartProps) => {
  const {
    byVehicleAgeBirthday,
    byVehicleAgeBirthdayValue,
    byVehicleAgeExposure,
    granularity,
    yAxisKey,
    yAxisValue,
  } = byVehicleAgeData;

  const { data, isLoading, error } = useListSignalEventsByVehicleAgeTimeline({
    signalEventOccurrencesFilter: getFiltersQuery(signalEventsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    byVehicleAgeBirthday,
    byVehicleAgeExposure,
    granularity,
  });

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={400} width="100%" />
      </Card>
    );
  }

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  if (!data) return NO_DATA;

  const chartData =
    prepareByVehicleAgeDataByTimestamp<SignalEventVehicleAgeTimeline>(
      data,
      byVehicleAgeData
    );
  const yAxisLines =
    generateByVehicleAgeYAxisLines<SignalEventVehicleAgeTimeline>(
      data,
      byVehicleAgeData
    );

  const chartTitle = `${yAxisValue} by ${byVehicleAgeBirthdayValue}`;

  return (
    <Card>
      <ChartActionsWrap
        id="se-by-vehicle-age"
        chartTitle={chartTitle}
        contentClassName="w-[350px]"
      >
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      {data.length === 0 && NO_DATA}
      {data.length > 0 && (
        <LineChart
          height={400}
          width="100%"
          data={chartData}
          xAxisKey="ts"
          yAxisLines={yAxisLines}
          yAxisLabel={yAxisValue}
          xAxisLabel={byVehicleAgeBirthdayValue}
          connectNulls={true}
          tooltipProps={{
            content: (props: any) => (
              <ChartTooltip
                {...props}
                yAxisKey={yAxisKey}
                yAxisLabel={yAxisValue}
              />
            ),
          }}
          toggleableLines={true}
        />
      )}
    </Card>
  );
};

export default ByVehicleAgeChart;
