import { useFlags } from "launchdarkly-react-client-sdk";

import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";

import { DEFAULT_ISSUES_SORT, ISSUES_PAGE_KEY } from "./constants";
import IssuesDashboard from "./IssuesDashboard";
import IssuesFilters from "./IssuesFilters";
import IssuesTable from "./IssuesTable";
import { getDefaultIssueFilter } from "./utils";

const IssuesPage = () => {
  const { issueDashboards } = useFlags();

  const filterSortState = useFilterSortState({
    pageKey: ISSUES_PAGE_KEY,
    defaultFilterValues: getDefaultIssueFilter(),
    defaultSort: DEFAULT_ISSUES_SORT,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(ISSUES_PAGE_KEY),
  });

  return (
    <>
      <IssuesFilters filterSortState={filterSortState} />
      {issueDashboards && <IssuesDashboard filterSortState={filterSortState} />}
      <IssuesTable topFilterSortState={filterSortState} />
    </>
  );
};

export default IssuesPage;
