import { pluralize, roundToNDecimals } from "shared/utils";

export const xAxis = "X-axis";
export const yAxis = "Y-axis";

export const cumulativeCPV = {
  id: "cumulativeCPV",
  value: "Cumulative CPV",
  label: "Cumulative Cost per vehicle",
};

export const rollingCPV = {
  id: "rollingCPV",
  value: "Rolling 7-day CPV",
  label: "Rolling 7-day Cost per vehicle",
};

export const cumulativeDPTV = {
  id: "cumulativeIPTV",
  value: "Cumulative DPTV",
  label: "Cumulative DPTV",
};

export const rollingDPTV = {
  id: "rollingIPTV",
  value: "Rolling 7-day DPTV",
  label: "Rolling 7-day DPTV",
};

export const cumulativeIPTV = {
  id: "cumulativeIPTV",
  value: "Cumulative IPTV",
  label: "Cumulative IPTV",
};

export const rollingIPTV = {
  id: "rollingIPTV",
  value: "Rolling 7-day IPTV",
  label: "Rolling 7-day IPTV",
};

export const rolling = {
  id: "rolling",
  value: "Rolling 7-day",
  label: "Rolling 7-day",
};

export const cumulative = {
  id: "cumulative",
  value: "Cumulative",
  label: "Cumulative",
};

export const cumulativeDPTVDV = {
  id: "cumulativeDPTV",
  value: "Cumulative DPTV - Distinct VINs",
  label: "Cumulative distinct affected vehicles per thousand vehicles",
};

export const SEDPTV = {
  id: "signalEventsDPTV",
  value: "DPTV",
  label: "DPTV",
};

export const SEDPTVDV = {
  id: "signalEventsDPTVDistinctVINs",
  value: "DPTV - Distinct VINs",
  label: "DPTV - Distinct VINs",
};

export const rollingDPTVDV = {
  id: "rollingDPTV",
  value: "Rolling 7-day DPTV - Distinct VINs",
  label: "Rolling 7-day distinct affected vehicles per thousand vehicles",
};

export const numberOfClaimsWithReoccurringSignalEvent = {
  id: "numberOfClaimsWithReoccurringSignalEvent",
  value: "Count of Claims with Signal Event Reoccurrences",
  label: "Count of Claims with Signal Event Reoccurrences",
};

export const rateOfClaimsWithReoccurringSignalEvent = {
  id: "rateOfClaimsWithReoccurringSignalEvent",
  value: "% of Claims with Signal Event Reoccurrences",
  label: "% of Claims with Signal Event Reoccurrences",
  valueFormatter: (val: number) => roundToNDecimals(val * 100, 2),
  tooltipValueFormatter: (val: number) => `${val} %`,
};

export const rollingOccurrences = {
  id: "rollingOccurrences",
  value: "Rolling 7-day Occurrences",
  label: "Number of SE occurrences: 7-day rolling window up to date",
};

export const cumulativeOccurrences = {
  id: "cumulativeOccurrences",
  value: "Cumulative Occurrences",
  label: "Number of SE occurrences calculated cumulatively up to date",
};

export const rollingAffectedVINs = {
  id: "rollingAffectedVINs",
  value: "Rolling 7-day Affected Vehicles",
  label:
    "Number of distinct affected vehicles: 7-day rolling window up to date",
};

export const cumulativeAffectedVINs = {
  id: "cumulativeAffectedVINs",
  value: "Cumulative Affected Vehicles",
  label:
    "Number of distinct affected vehicles calculated cumulatively up to date",
};

type Entity = "Claim" | "Inspection" | "Repair";

export const getRollingEntityCount = (entity: Entity) => {
  return {
    id: `rolling${entity}Count`,
    value: `Rolling 7-day ${pluralize(entity)}`,
    label: `Number of ${pluralize(entity.toLowerCase())}: 7-day rolling window up to date`,
  };
};

export const getCumulativeEntityCount = (entity: Entity) => {
  return {
    id: `cumulative${entity}Count`,
    value: `Cumulative ${pluralize(entity)}`,
    label: `Number of ${pluralize(entity.toLowerCase())} counted cumulatively up to date`,
  };
};

export const rollingCost = {
  id: "rollingCost",
  value: "Rolling 7-day Total Cost",
  label: "Total cost of claims in 7-day rolling window up to date",
};

export const cumulativeCost = {
  id: "cumulativeCost",
  value: "Cumulative Cost",
  label: "Total cost of claims calculated cumulatively up to date",
};
