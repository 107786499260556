import { useState } from "react";

import { useIssuesFiltersSchema } from "shared/hooks";

import DropdownSelect from "features/ui/DropdownSelect";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import { ISSUES_PAGE_KEY } from "./constants";
import { getDefaultIssueFilter } from "./utils";

const LABEL = "Issue Filters";

export const ISSUES_PENDING_FILTERS_LS_KEY =
  getPendingFiltersKey(ISSUES_PAGE_KEY);

interface Props {
  filterSortState: UseFilterSortState;
}

const IssuesFilters = ({ filterSortState }: Props) => {
  const [filterSummaryOpen, setFilterSummaryOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { filters, isAdvancedFilterEditor } = filterSortState;
  const issueFiltersSchema = useIssuesFiltersSchema();

  const label = getFilterLabel(LABEL, filters);

  return (
    <div className="mb-3">
      <div className="flex">
        <DropdownSelect
          label={label}
          testId="issues-filters-dropdown"
          open={dropdownOpen}
          onOpen={(open) => {
            setDropdownOpen(open);
          }}
          hasAdvancedFilters={isAdvancedFilterEditor}
          content={
            <FilterSelector
              schema={issueFiltersSchema}
              filterSortState={filterSortState}
              title={LABEL}
              testId="issues-filters"
              pendingFiltersKey={ISSUES_PENDING_FILTERS_LS_KEY}
              onCloseFilters={() => {
                setDropdownOpen(false);
              }}
              initialIsAdvancedFilter={isAdvancedFilterEditor}
              defaultFilters={getDefaultIssueFilter()}
            />
          }
        />
        <ViewFiltersButton
          open={filterSummaryOpen}
          onClick={() => setFilterSummaryOpen(true)}
          onClose={() => setFilterSummaryOpen(false)}
        />
      </div>
      <FiltersSummary
        open={filterSummaryOpen}
        filterStates={[
          {
            name: LABEL,
            filters,
            schema: issueFiltersSchema,
          },
        ]}
      />
    </div>
  );
};

export default IssuesFilters;
