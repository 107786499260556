import { MdLogout as LogoutIcon } from "react-icons/md";
import { useOktaAuth } from "@okta/okta-react";

import { NAV_ITEM_ICON_SIZE } from "features/layout/SidebarNav/utils";

import * as config from "config/config";

import oktaLogout from "./utils";

/**
 * Log the user out based on the tenant config.
 *
 * Make sure that the relevant tenant config app in Okta has
 * Sign-out redirect URIs set to the URL of the app (that's the default)
 * OR if postLogoutRedirectUri is set to also include that!!
 *
 * Notes:
 *  - if we only wanna log out from the app (and not Okta!) we should use oktaAuth.tokenManager.clear();
 *  - otherwise we use await oktaAuth.signOut
 *
 * @see https://developer.okta.com/docs/guides/sign-users-out/react/main/
 */
const Logout = () => {
  const { oktaAuth } = useOktaAuth();
  const { isIframed } = config.get();

  if (isIframed) return null;

  return (
    <button
      onClick={() => oktaLogout(oktaAuth)}
      data-testid="viaduct-logout-cta"
      className="flex items-center space-x-2 font-medium text-sm leading-none md:leading-3"
    >
      <LogoutIcon size={NAV_ITEM_ICON_SIZE} />
      <span className="cursor-pointer">Logout</span>
    </button>
  );
};

export default Logout;
