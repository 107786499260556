import { SensorReadingsTimelineGrouping } from "shared/api/sensors/api";

import { ChartAction } from "features/ui/charts/ChartActions";

export const MAX_DATA_POINTS_UNTIL_SENSORS_CHART_SLOW = 1000;
export const DEFAULT_R_AXIS_WIDTH = 70;
export const MAX_SENSOR_Y_AXIS_LABEL_LENGTH = 18;

export const CHART_ACTIONS: ChartAction<SensorReadingsTimelineGrouping>[] = [
  {
    id: "legend",
    title: "Aggregation",
    type: "dropdownSelect",
    options: [
      {
        id: "day",
        value: "Daily",
      },
      {
        id: "hour",
        value: "Hourly",
      },
      {
        id: "none",
        value: "None",
      },
    ],
  },
];
