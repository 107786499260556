import { useFlags } from "launchdarkly-react-client-sdk";
import Skeleton from "react-loading-skeleton";

import {
  ClaimFiltersByVehicleAgeRequest,
  getClaimsByVehicleAgeTimelineExport,
} from "shared/api/claims/api";
import { useListClaimsByVehicleAgeTimeline } from "shared/api/claims/hooks";
import { Granularity } from "shared/api/constants";
import { MAX_ROWS_DOWNLOAD_LIMIT, NO_DATA } from "shared/constants";

import { ClaimsByVehicleAgeTableProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import Table from "features/ui/Table";

import MISColumnHeaders from "./MISColumnHeaders";
import { prepareVehiclesByAgeTableSchemaAndData } from "./utils";

const STICKY_FIRST_COLUMN = true;
const TABLE_HEIGHT_PX = 500;

const ByVehicleAgeTable = ({
  claimsFilters,
  vehiclesFilters,
  onBadRequest,
  byVehicleAgeData,
}: ClaimsByVehicleAgeTableProps) => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();

  const {
    byVehicleAgeBirthday,
    byVehicleAgeBirthdayValue,
    byVehicleAgeExposure,
    byVehicleAgeExposureValue,
    granularity,
  } = byVehicleAgeData;

  const requestParams: ClaimFiltersByVehicleAgeRequest = {
    claimsFilter: claimsFilters && getFiltersQuery(claimsFilters),
    vehiclesFilter: vehiclesFilters && getFiltersQuery(vehiclesFilters),
    byVehicleAgeBirthday,
    byVehicleAgeExposure,
    granularity,
    hideCosts: warrantyClaimsCostFF,
  };

  const { data, isLoading, error } =
    useListClaimsByVehicleAgeTimeline(requestParams);

  if (isLoading) {
    return <Skeleton height={400} className="mt-5" />;
  }

  if (!data) return NO_DATA;

  const {
    data: formattedData,
    schema,
    uniqueExposureValues,
  } = prepareVehiclesByAgeTableSchemaAndData(
    data,
    warrantyClaimsCostFF,
    granularity as Granularity,
    byVehicleAgeBirthdayValue
  );

  const extraRowHeaders = (
    <MISColumnHeaders
      stickyFirstColumn={STICKY_FIRST_COLUMN}
      label={byVehicleAgeExposureValue}
      uniqueExposureValues={uniqueExposureValues}
    />
  );

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <>
      {!error && (
        <div className="flex justify-end mt-5">
          <DownloadAction
            disabled={downloadDisabled}
            downloadFunc={getClaimsByVehicleAgeTimelineExport}
            fileName="claims-by-vehicle-age"
            requestParams={{
              ...requestParams,
              limit: MAX_ROWS_DOWNLOAD_LIMIT,
            }}
            entityName="claim"
            filters={mergeFilterGroupStates(claimsFilters, vehiclesFilters)}
          />
        </div>
      )}
      <div>
        {!error && (
          <Table
            data={formattedData}
            schema={schema}
            isLoading={isLoading}
            stickyFirstColumn={STICKY_FIRST_COLUMN}
            dense
            scrollHeight={TABLE_HEIGHT_PX}
            extraHeaderRowContent={extraRowHeaders}
          />
        )}
        {error && <APIError error={error} onBadRequest={onBadRequest} />}
        {!error && !isLoading && !formattedData?.length && (
          <div className="py-4 text-gray-400 text-sm">No results.</div>
        )}
      </div>
    </>
  );
};

export default ByVehicleAgeTable;
