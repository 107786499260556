import {
  ASSOCIATED_VEHICLES_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_TITLE,
  TOP_CONTRIBUTORS_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_TITLE,
} from "pages/constants";

export const REPAIRS_FILTER_LABEL = "Repair Filters";

export const PAGE_TITLE = "Repair Analytics";
export const REPAIRS_PAGE_KEY = "repairAn";
export const REPAIRS_VEHICLES_PAGE_KEY = "repairAnVehicles";

export const REPAIRS_TAB_KEY = "repairs";
const REPAIRS_TAB_TITLE = "Repairs";

export const REPAIRS_ANALYTICS_TABS = {
  [REPAIRS_TAB_KEY]: REPAIRS_TAB_TITLE,
  [TOP_CONTRIBUTORS_TAB_KEY]: TOP_CONTRIBUTORS_TAB_TITLE,
  [ASSOCIATED_VEHICLES_TAB_KEY]: ASSOCIATED_VEHICLES_TAB_TITLE,
};
