import Skeleton from "react-loading-skeleton";

import { useFailureModeVehiclesCount } from "shared/api/hooks";
import { formatNumber, getTenantMileageUnit } from "shared/utils";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

interface Props {
  failureModeID: string;
  parentFilters: FilterGroupState;
}

const FailureModeInScopeVehicleCount = ({
  failureModeID,
  parentFilters,
}: Props) => {
  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useFailureModeVehiclesCount({
    id: failureModeID,
    filter: getFiltersQuery(parentFilters),
    mileageUnit: getTenantMileageUnit(),
  });

  if (countIsLoading) {
    return <Skeleton containerClassName="flex-1" height={104} />;
  }

  return (
    <div className="flex-1 flex flex-col justify-center items-center shadow px-4 py-3 text-center rounded">
      <div
        className="text-3xl font-semibold"
        data-testid="failure-mode-in-scope-vehicles-count"
      >
        {!countError && countData && formatNumber(countData.count)}
      </div>
      <div className="text-gray-500">In-Scope Vehicles</div>
    </div>
  );
};

export default FailureModeInScopeVehicleCount;
