import classNames from "classnames";

import { FailureModeEventType } from "shared/api/api";
import { toTitleCase } from "shared/utils";

interface FailureModeEventTypeBadgeProps {
  type: FailureModeEventType;
}

// using full classes because of https://tailwindcss.com/docs/optimizing-for-production#writing-purgeable-html
const colorMap: Record<FailureModeEventType, string> = {
  repair: "text-blue-400",
  failure: "text-orange-600",
};

const FailureModeEventTypeBadge = ({
  type,
}: FailureModeEventTypeBadgeProps) => {
  const colorClass = type && colorMap[type];

  return (
    <span className={classNames(colorClass, "font-semibold")}>
      {toTitleCase(type)}
    </span>
  );
};

export default FailureModeEventTypeBadge;
