import Skeleton from "react-loading-skeleton";

import { FailureModeEventTimelineGrouping } from "shared/api/api";
import { useFailureModeEventsTimeline } from "shared/api/hooks";

import APIError from "features/ui/APIError";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import Section from "features/ui/Section";
import SectionTitle from "features/ui/SectionTitle";

import { FailureModeEventsGraph } from "./FailureModeEventGraph";
import { formatDataForRollingGraph } from "./utils";

interface Props {
  failureModeID: string;
  parentFilters: FilterGroupState;
}

const GROUPING: FailureModeEventTimelineGrouping = "month";

const FailureModeEventsTimeline = ({ failureModeID, parentFilters }: Props) => {
  const { data, isLoading, error } = useFailureModeEventsTimeline({
    failureModeID,
    grouping: GROUPING,
    filter: getFiltersQuery(parentFilters),
  });

  if (error) {
    return <APIError error={error} />;
  }

  return (
    <Section>
      <SectionTitle>Events by Month</SectionTitle>
      {isLoading || !data ? (
        <Skeleton height={300} />
      ) : (
        <FailureModeEventsGraph data={formatDataForRollingGraph({ data })} />
      )}
    </Section>
  );
};

export default FailureModeEventsTimeline;
