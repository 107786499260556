export const SIGNAL_EVENTS_TAB_KEY = "signal-events";
export const ASSOCIATED_CLAIMS_TAB_KEY = "associated-claims";
export const SE_ASSOCIATED_SIGNAL_EVENTS_TAB_KEY = "se-associated-se";

export const PAGE_TITLE = "Signal Event Analytics";
export const SIGNAL_EVENTS_PAGE_KEY = "sigEvAn";
export const VEHICLES_PAGE_KEY = "sigEvAnVehicles";

export const VEHICLES_FILTER_LABEL = "Vehicle Filters";
export const SIGNAL_EVENTS_FILTER_LABEL = "Signal Event Filters";
