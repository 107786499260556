import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { useSignalEvent } from "shared/api/signalEvents/hooks";

import APIError from "features/ui/APIError/APIError";
import Title from "features/ui/Title";

type Params = {
  id: string;
};

const SingleSignalEvent = () => {
  const { id } = useParams<Params>();

  const { data, isLoading, error } = useSignalEvent({
    id: id as string,
  });

  if (isLoading || !data) {
    return (
      <div className="flex space-x-3">
        <Skeleton count={2} height={300} />
      </div>
    );
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data && !isLoading && !error) {
    return <div>No data</div>;
  }

  return (
    <>
      <div className="mb-4">
        <Title text={data.name} />
      </div>
      <div>TODO</div>
    </>
  );
};

export default SingleSignalEvent;
