import { CustomSignalEventStatus } from "./types";

export const PAGE_TITLE = "Custom Signal Events";

export const CTA_TEXT = "New Custom Signal Event";

// ACTIVE_STATUS_LIST represents an ordered list of status progressions during the lifespan of a custom signal event definition
export const ACTIVE_STATUS_LIST: CustomSignalEventStatus[] = [
  "draft",
  "ready_for_validation",
  "invalid",
  "ready_for_backfill",
  "backfill_in_progress",
  "error_during_backfill",
  "ready_for_incremental_computation",
];

export const ERROR_STATUSES: CustomSignalEventStatus[] = [
  "invalid",
  "error_during_backfill",
];

export const EDITABLE_STATUSES: CustomSignalEventStatus[] = [
  "draft",
  ...ERROR_STATUSES,
];
export const PUBLISHABLE_STATUSES: CustomSignalEventStatus[] = ["draft"];
