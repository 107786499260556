import { useEffect, useState } from "react";
import { Location } from "react-router";
import ReactRouterPrompt from "react-router-prompt";
import { toast } from "react-toastify";

import { updateIssue } from "shared/api/issues/api";
import { useIssue } from "shared/api/issues/hooks";

import {
  ISSUE_ID_QUERY_KEY,
  ISSUE_IS_SUGGESTED_QUERY_KEY,
  ISSUE_NAME_QUERY_KEY,
} from "pages/Issues/constants";

import ConfirmationModal from "features/ui/ConfirmationModal";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  areFiltersEqual,
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";

import { useQuery } from "services/hooks";

import ModalText from "./ModalText";

const TITLE = "Save Changes?";
const CONFIRM_TEXT = "Yes, Save to Issue";
const CANCEL_TEXT = "No, Do Not Save";
const SUCCESS_MESSAGE = "Issue updated successfully";
const FAILURE_MESSAGE =
  "Updating issue failed. Please notify us if the problem persists.";

interface Props {
  page: "claimAnalytics" | "signalEventAnalytics";
  vehicleFilters?: FilterGroupState;
  claimFilters?: FilterGroupState;
  signalEventFilters?: FilterGroupState;
}

interface BlockerFunctionProps {
  currentLocation: Location;
  nextLocation: Location;
  historyAction: any; // should be HistoryAction as per https://www.npmjs.com/package/react-router-prompt
}

const ConfirmationModalIssueUpdate = ({
  page,
  vehicleFilters,
  claimFilters,
  signalEventFilters,
}: Props) => {
  const query = useQuery();

  const [showModal, setShowModal] = useState(true);

  const [initialVehicleFilters, setInitialVehicleFilters] =
    useState<FilterGroupState>();
  const [initialClaimFilters, setInitialClaimFilters] =
    useState<FilterGroupState>();
  const [
    initialSignalEventOccurrencesFilter,
    setInitialSignalEventOccurrencesFilter,
  ] = useState<FilterGroupState>();

  const issueId = query && (query[ISSUE_ID_QUERY_KEY] as string);
  const isSuggestedIssue = query[ISSUE_IS_SUGGESTED_QUERY_KEY] === "true";

  const skipRequest = !issueId || isSuggestedIssue;

  const { data: issue } = useIssue({
    id: issueId,
    skipRequest,
  });

  useEffect(() => {
    setShowModal(true);
    if (issue?.atRiskPopulationFilter) {
      setInitialVehicleFilters(
        filterBuilderQueryToFilterBuilderState(issue.atRiskPopulationFilter)
      );
    }
    if (issue?.claimFilter) {
      setInitialClaimFilters(
        filterBuilderQueryToFilterBuilderState(issue.claimFilter)
      );
    }
    if (issue?.signalEventOccurrencesFilter) {
      setInitialSignalEventOccurrencesFilter(
        filterBuilderQueryToFilterBuilderState(
          issue.signalEventOccurrencesFilter
        )
      );
    }
  }, [issue]);

  if (!query || !issueId || !query[ISSUE_NAME_QUERY_KEY] || isSuggestedIssue) {
    return null;
  }

  const hasChangedVehicleFilters = !areFiltersEqual(
    initialVehicleFilters,
    vehicleFilters
  );

  const hasChangedClaimFilters =
    page === "claimAnalytics" &&
    !areFiltersEqual(initialClaimFilters, claimFilters);

  const hasChangedSignalEventIDs =
    page === "signalEventAnalytics" &&
    !areFiltersEqual(initialSignalEventOccurrencesFilter, signalEventFilters);

  const issueName = query[ISSUE_NAME_QUERY_KEY] as string;

  const canEditThisIsssue = issue?.canEdit;

  const promptUserWhenNavigatingAway = Boolean(
    canEditThisIsssue &&
      (hasChangedVehicleFilters ||
        (page === "claimAnalytics" && hasChangedClaimFilters) ||
        (page === "signalEventAnalytics" && hasChangedSignalEventIDs))
  );

  const handleOnIssueUpdate = (onConfirm: () => void) => {
    let requestData = {
      ID: issueId,
      atRiskPopulationFilter: getFiltersQuery(vehicleFilters),
      claimFilter: getFiltersQuery(claimFilters),
      signalEventOccurrencesFilter: getFiltersQuery(signalEventFilters),
    };

    updateIssue(requestData)
      .then(() => {
        toast.success(SUCCESS_MESSAGE);
      })
      .catch((err: Error) => {
        toast.error(FAILURE_MESSAGE);
        console.log(err);
      })
      .finally(() => {
        setShowModal(false);
        onConfirm();
      });
  };

  const handleBlocker = ({
    currentLocation,
    nextLocation,
  }: BlockerFunctionProps) => {
    const isSamePathname = currentLocation.pathname === nextLocation.pathname;
    return promptUserWhenNavigatingAway && !isSamePathname;
  };

  const handleOnClose = ({
    confirm,
    onConfirm,
  }: {
    confirm: boolean;
    onConfirm: () => void;
  }) => (confirm ? handleOnIssueUpdate(onConfirm) : onConfirm());

  return (
    <ReactRouterPrompt when={handleBlocker}>
      {({ isActive, onConfirm }) => (
        <ConfirmationModal
          title={TITLE}
          text={
            <ModalText
              issueName={issueName}
              hasChangedVehicleFilters={hasChangedVehicleFilters}
              hasChangedClaimFilters={hasChangedClaimFilters}
              hasChangedSignalEventFilters={hasChangedSignalEventIDs}
            />
          }
          isOpen={isActive && showModal}
          onClose={(confirm) => handleOnClose({ confirm, onConfirm })}
          confirmText={CONFIRM_TEXT}
          closeText={CANCEL_TEXT}
        />
      )}
    </ReactRouterPrompt>
  );
};

export default ConfirmationModalIssueUpdate;
