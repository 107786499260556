import { useContext, useState } from "react";

import { formatAPIDate } from "shared/api/utils";
import { EventRegistryContext } from "shared/contexts/EventRegistryContext";
import { DATE_FILTER_GENERIC } from "shared/filterDefinitions";
import { useVehiclesFiltersSchema } from "shared/hooks";

import { VEHICLES_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";
import { SingnalEventStudioState } from "pages/SignalEventStudio/hooks";
import { getDateTypeForEventType } from "pages/SignalEventStudio/utils";

import DropdownSelect from "features/ui/DropdownSelect";
import Filters from "features/ui/Filters";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { filterStateToFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator, FilterState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import FormSection from "./FormSection";
import SignalEventPreviewData from "./SignalEventPreviewData";

interface Props {
  signalEventStudioState: SingnalEventStudioState;
}

const MAX_VEHICLES = 30;
const DEFAULT_DATE_PARAM = "date";

const VEHICLES_FILTER_PAGE_KEY = "custom_signal_events_vehicles_filter";
const DATE_FILTER_PAGE_KEY = "custom_signal_events_date_filter";

const SignalEventPreviewOccurrences = ({ signalEventStudioState }: Props) => {
  const eventTypes = useContext(EventRegistryContext);

  const [filterSummaryOpen, setFilterSummaryOpen] = useState(false);

  const [vehicleFilterDropdownOpen, setVehicleFilterDropdownOpen] =
    useState(false);
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const pendingVehicleFiltersKey = getPendingFiltersKey(
    VEHICLES_FILTER_PAGE_KEY
  );
  const vehiclesFilterSortState = useFilterSortState({
    pageKey: VEHICLES_FILTER_PAGE_KEY,
    pendingFiltersLocalStorageKey: pendingVehicleFiltersKey,
  });

  const vehiclePopulationFilterLabel = getFilterLabel(
    VEHICLES_FILTER_LABEL,
    vehiclesFilterSortState.filters
  );
  const isAdvancedVehiclesEditor =
    vehiclesFilterSortState.isAdvancedFilterEditor;

  const { selectedEventTypeOption, schema } = signalEventStudioState;
  const dateParam =
    eventTypes?.find((x) => x.type === selectedEventTypeOption?.id)
      ?.dateAttribute || DEFAULT_DATE_PARAM;
  const dateType = getDateTypeForEventType(dateParam, schema);

  const date = new Date();
  const aMonthAgo = new Date(date);
  aMonthAgo.setMonth(date.getMonth() - 1);

  const DEFAULT_DATE_FILTER: FilterGroupState = filterStateToFilterGroupState({
    [dateParam]: {
      values: [
        formatAPIDate(aMonthAgo.toString(), dateType),
        formatAPIDate(new Date().toString(), dateType),
      ],
      operator: FilterOperator.BETWEEN,
    },
  } as FilterState);

  const {
    filters: dateFilters,
    initialized: dateFiltersInitialized,
    manageOnFilterChange: manageOnDateFiltersChange,
  } = useFilterSortState({
    pageKey: DATE_FILTER_PAGE_KEY,
    defaultFilterValues: DEFAULT_DATE_FILTER,
  });

  return (
    <>
      <div className="relative">
        <div className="text-gray-600 text-sm mb-4 max-w-4xl">
          Preview occurrences of this Custom Signal Event in selection of
          vehicles over a period of time.
        </div>
      </div>
      <FormSection
        data-testId="vehicle-filters-section"
        title="Vehicles to Preview"
        text={`At most ${MAX_VEHICLES} vehicles can be previewed. If more than that are returned, ${MAX_VEHICLES} will be randomly selected.`}
      >
        <div className="flex space-x-3">
          <DropdownSelect
            testId="vehicles-filters-dropdown"
            label={vehiclePopulationFilterLabel}
            open={vehicleFilterDropdownOpen}
            onOpen={setVehicleFilterDropdownOpen}
            hasAdvancedFilters={isAdvancedVehiclesEditor}
            content={
              <FilterSelector
                schema={vehiclesFiltersSchema}
                filterSortState={vehiclesFilterSortState}
                title={VEHICLES_FILTER_LABEL}
                testId="vehicles-filters"
                pendingFiltersKey={pendingVehicleFiltersKey}
                baseEntityText="Vehicles"
                onCloseFilters={() => {
                  setVehicleFilterDropdownOpen(false);
                }}
                initialIsAdvancedFilter={isAdvancedVehiclesEditor}
              />
            }
          />
          <ViewFiltersButton
            open={filterSummaryOpen}
            onClick={() => setFilterSummaryOpen(true)}
            onClose={() => setFilterSummaryOpen(false)}
          />
          <Filters
            initialized={dateFiltersInitialized}
            schema={[
              DATE_FILTER_GENERIC({
                fieldName: dateParam,
                label: "Date",
                filterDataType: dateType,
              }),
            ]}
            onFilterChange={manageOnDateFiltersChange}
            filters={dateFilters}
            horizontal
          />
        </div>
        <FiltersSummary
          open={filterSummaryOpen}
          filterStates={[
            {
              name: VEHICLES_FILTER_LABEL,
              filters: vehiclesFilterSortState.filters,
              schema: vehiclesFiltersSchema,
            },
          ]}
        />
      </FormSection>
      <div className="w-[65rem]">
        <SignalEventPreviewData
          signalEventStudioState={signalEventStudioState}
          vehiclesFilterSortState={vehiclesFilterSortState}
        />
      </div>
    </>
  );
};

export default SignalEventPreviewOccurrences;
