import { useContext } from "react";

import { SignalEventOccurrenceAttributeContext } from "shared/contexts/SignalEventOccurrenceAttributesContextWrapper";
import {
  SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER,
  SIGNAL_EVENTS_GENERIC_FILTER,
  VEHICLES_GENERIC_FILTER,
} from "shared/filterDefinitions";

import { SchemaEntry } from "features/ui/Table";

import { SchemaOverride, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useSignalEventOccurrencesSchema = (
  accessorsToHide: string[] = []
): UseSchema => {
  const attributes = useContext(SignalEventOccurrenceAttributeContext);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(
    SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const overrides: Record<string, SchemaOverride> = {
    signalEventID: {
      schemaOverride: {
        filter: SIGNAL_EVENTS_GENERIC_FILTER({
          fieldName: "signalEventID",
          fieldNameForAPI: "ID",
          label: getDisplayLabel("signalEventID", "Signal Event ID"),
          search: true,
          disableFiltering: true,
          disableContainsFilters: true,
        }),
      },
    },
    description: {
      schemaOverride: { limitedWidthClass: "max-w-96" },
    },
    VIN: {
      schemaOverride: {
        filter: VEHICLES_GENERIC_FILTER({
          fieldName: "VIN",
          label: getDisplayLabel("VIN", "VIN"),
          search: true,
          disableFiltering: true,
        }),
      },
    },
    relatedSignalEventOccurrences: {
      schemaOverride: {
        filter: SIGNAL_EVENTS_GENERIC_FILTER({
          fieldName: "relatedSignalEventOccurrences",
          fieldNameForAPI: "ID",
          label: getDisplayLabel(
            "relatedSignalEventOccurrences",
            "Related Signal Events"
          ),
          search: true,
          disableFiltering: true,
          disableContainsFilters: true,
          filterType: "relates",
          baseEntityText: "Base Signal Events",
        }),
      },
    },
  };

  const schema: SchemaEntry<string>[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides,
    undefined,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaForAccessor,
    getSchemaEntry,
  };
};
export default useSignalEventOccurrencesSchema;
