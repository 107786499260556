import { useCallback, useEffect, useState } from "react";
import qs from "qs";
import { useNavigate } from "react-router";

import { SortBy } from "shared/types";

import { QS_PARSE_ARRAY_LIMIT } from "services/hooks";

import { DEFAULT_FILTER_BUILDER_STATE } from "./FilterBuilder/constants";
import { FilterGroupState } from "./FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  getTopLevelRowFromFilterGroupState,
  isAdvancedFilterState,
  removeAttributesFromFilterGroupState,
  updateOrAddRowFilterGroupState,
} from "./FilterBuilder/utils";
import {
  FilterSortState,
  OnFilterChangeProps,
  UseFilterSortState,
  UseFilterSortStateProps,
} from "./types";
import {
  getPageKeyWithVersion,
  getUpdatedQueryParams,
  persistToLocalStorage,
  useInitialStateValuesAndKeys,
} from "./utils";

export const useFilterSortState = ({
  pageKey,
  defaultSort,
  defaultFailureModeColumns = [],
  disableUsingQuery = false,
  disableUsingLocalStorage = false,
  defaultFilterValues,
  pendingFiltersLocalStorageKey,
}: UseFilterSortStateProps): UseFilterSortState => {
  const navigate = useNavigate();

  const pageKeyWithVersion = getPageKeyWithVersion(pageKey);
  const pendingFiltersLocalStorageKeyWithVersion =
    pendingFiltersLocalStorageKey &&
    getPageKeyWithVersion(pendingFiltersLocalStorageKey);

  // currently initialized is the same for ALL filters on the page - if one is not initialized,
  // all are considered not. We should either remove this state or reconsider its implementation
  const [initialized, setInitialized] = useState(false);

  const { initialValues, queryKeys } = useInitialStateValuesAndKeys(
    pageKeyWithVersion,
    disableUsingQuery,
    disableUsingLocalStorage,
    defaultFilterValues
  );

  const defaultFilters: FilterGroupState =
    initialValues.filters || DEFAULT_FILTER_BUILDER_STATE;

  const isAdvancedFilterEditorDefault = isAdvancedFilterState(defaultFilters);

  const [isAdvancedFilterEditor, setIsAdvancedFilterEditor] = useState(
    isAdvancedFilterEditorDefault
  );

  const [filters, setFilters] = useState<FilterGroupState>(defaultFilters);

  const [sort, setSort] = useState<SortBy | undefined>(
    initialValues.sort !== undefined ? initialValues.sort : defaultSort
  );

  const [columns, setColumns] = useState<string[]>(
    initialValues.columns !== undefined
      ? initialValues.columns || []
      : defaultFailureModeColumns
  );

  const { filtersKey, sortKey, columnsKey } = queryKeys;

  const onFilterSortStateChange = useCallback(
    (next: FilterSortState) => {
      const currentQuery = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
        arrayLimit: QS_PARSE_ARRAY_LIMIT,
        // We need higher depth because of the relates filter (default is 5)
        depth: 10,
      });

      const data: FilterSortState = disableUsingQuery
        ? { ...next }
        : {
            sort: next.sort || (currentQuery[sortKey] as SortBy),
            filters:
              next.filters ||
              filterBuilderQueryToFilterBuilderState(
                currentQuery[filtersKey] as string
              ),
            columns: next.columns || (currentQuery[columnsKey] as string[]),
          };

      if (!disableUsingLocalStorage) {
        persistToLocalStorage(data, pageKeyWithVersion);
      }

      if (data.sort) setSort(data.sort);

      if (data.filters) setFilters(data.filters);

      if (data.columns) setColumns(data.columns);

      if (!disableUsingQuery) {
        navigate(
          { search: getUpdatedQueryParams(data, queryKeys, currentQuery) },
          { replace: true }
        );
      }
    },
    // we dont want to run this when filters, sort, columns change because we use them inside this callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      navigate,
      persistToLocalStorage,
      disableUsingQuery,
      filtersKey,
      sortKey,
      columnsKey,
      disableUsingLocalStorage,
    ]
  );

  // Turn filters on / off based on previous values.
  // Only used with basic filters (not FilterBuilder)
  const manageOnFilterChange = ({
    key,
    op_id,
    values = [],
    ...otherProps
  }: OnFilterChangeProps) => {
    if (!filters) return;

    const childRow = getTopLevelRowFromFilterGroupState(key, filters);
    const currentValues = childRow?.values || [];
    const currentOperator = childRow?.operator;

    // Do not do anything when values or operator haven't changed.
    // Note: we just do naive equality check.
    // Note 2: the values can be the same but operator changed and we still have to trigger filtering!
    if (
      (currentValues === values ||
        JSON.stringify(currentValues) === JSON.stringify(values)) &&
      currentOperator === op_id
    ) {
      return;
    }

    // If values are empty, remove this key from filter.
    if (!values.length) {
      const updated = removeAttributesFromFilterGroupState(filters, [key]);
      return updateFilters(updated);
    }

    // FilterSelector will not be able to get correct pendingFilters state from localStorage
    // unless we reset this here in cases where UseFilterSortState is shared between table & FilterSelector LS
    // Random example where this is needed:
    // - Top contributors bar chart: "include selected values as page filter" will update filters,
    // but pending filters will stay the same - this resets them before setting filters and they will thus be the same as filters
    // due to defaultValue: filters (see FilterSelector.tsx)
    if (pendingFiltersLocalStorageKeyWithVersion) {
      localStorage.removeItem(pendingFiltersLocalStorageKeyWithVersion);
    }

    const newFilters = updateOrAddRowFilterGroupState(filters, {
      type: "row",
      id: `row-${new Date()}`,
      attribute: key,
      values,
      operator: op_id,
      ...otherProps,
    });

    return updateFilters(newFilters);
  };

  const manageOnSortChange = (sort: SortBy) => {
    return onFilterSortStateChange({
      sort,
    });
  };

  const manageOnVisibleFMColumnChange = (columns: string[]) => {
    return onFilterSortStateChange({
      columns,
    });
  };

  const updateFilters = (updatedFilters: FilterGroupState) => {
    return onFilterSortStateChange({
      filters: updatedFilters,
    });
  };

  const resetFilters = (fieldNames?: string[]) => {
    const updatedFilters =
      (fieldNames &&
        removeAttributesFromFilterGroupState(filters, fieldNames)) ||
      DEFAULT_FILTER_BUILDER_STATE;

    setInitialized(false);
    updateFilters(updatedFilters);
    // Filters don't like to be told what to set them to after being mounted. This is a little hacky solution to reset the component
    // that should be good enough
    setTimeout(() => setInitialized(true), 10);
  };

  const resetSort = () => {
    onFilterSortStateChange({
      sort: defaultSort,
    });
  };

  /* Reset ALL table state */
  const resetFilterSortState = () => {
    const defaultState = {
      sort: defaultSort,
      filters: defaultFilterValues,
      columns: [],
    };
    // We want to check if the filters are already reset, to avoid infinite loop in case we get 400 on default state request
    if (
      JSON.stringify({ sort, filters, columns }) !==
      JSON.stringify(defaultState)
    ) {
      setInitialized(false);
      onFilterSortStateChange({
        sort: defaultSort,
        filters: defaultFilterValues,
        columns: [],
      });
      // Filter don't like to be told what to set it to after being mounted. This is a little hacky solution to reset the component
      // that should be good enough
      setTimeout(() => setInitialized(true), 0);
    }
  };

  /* 
      When the user comes to the page initially we check if there's query params in the URL and store them to localStorage. 
      If there are none we check the localStorage and set filters / sort / columns based on that. 
    */
  useEffect(() => {
    // We also have to update pending filters in localStorage if we are using it.
    if (pendingFiltersLocalStorageKeyWithVersion) {
      localStorage.setItem(
        pendingFiltersLocalStorageKeyWithVersion,
        JSON.stringify(filters)
      );
    }

    // Upon load make sure our advanced filter editor is set correctly based on the filters.
    setIsAdvancedFilterEditor(isAdvancedFilterState(filters));

    onFilterSortStateChange({
      sort,
      columns,
      filters,
    });
    setTimeout(() => setInitialized(true), 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    initialized,
    sort,
    manageOnSortChange,
    filters,
    manageOnFilterChange,
    resetFilters,
    resetSort,
    resetFilterSortState,
    updateFilters,
    failureModeColumns: columns,
    manageOnVisibleFMColumnChange,
    isAdvancedFilterEditor,
    setIsAdvancedFilterEditor,
  };
};
