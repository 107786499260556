interface Props {
  title: string;
  text?: string;
  children: React.ReactNode;
  extraTitleComponent?: React.ReactNode;
}

const FormSection = ({ title, text, children, extraTitleComponent }: Props) => (
  <div className="mb-4 w-[65rem]">
    <div className="text-gray-600 text-base font-semibold mb-1">
      <div className="flex space-x-2 items-end">
        <span>{title}</span>
        {extraTitleComponent}
      </div>
    </div>
    {text && <div className="text-gray-600 text-sm mb-4">{text}</div>}
    {children}
  </div>
);

export default FormSection;
