import { useEffect, useState } from "react";
import classNames from "classnames";

import { SHORT_DATE_FORMAT } from "shared/constants";
import { formatDate, getTenantMileageUnit } from "shared/utils";

import { GENERIC_DATA_TO_DISPLAY, GENERIC_JSON_DATA_KEY } from "./constants";
import { RecordProps } from "./EventDetail";
import InformationWrapper from "./InformationWrapper";
import { getEntityFunc } from "./utils";

const GenericDetail = ({ serviceRecord, onClose }: RecordProps) => {
  const getEntity = getEntityFunc(serviceRecord);

  const [entityData, setEntityData] = useState<Record<string, any>>();

  useEffect(() => {
    if (!getEntity) return;

    getEntity({
      ID: serviceRecord.ID,
      mileageUnit: getTenantMileageUnit(),
    }).then((res) => {
      setEntityData(res.data[GENERIC_JSON_DATA_KEY]);
    });
  }, [getEntity, serviceRecord.ID]);

  return (
    <InformationWrapper
      externalID={serviceRecord.externalID || ""}
      onClose={onClose}
    >
      {GENERIC_DATA_TO_DISPLAY.map(({ accessor, type }, index) => {
        if (!serviceRecord[accessor]) return null;

        return (
          <div
            className={classNames("grid grid-cols-[180px_auto] gap-x-3 py-2", {
              "border-t": index > 0,
            })}
          >
            <span className="text-gray-400 capitalize">{accessor}</span>
            <span className="break-words">
              {(type === "date" &&
                formatDate(
                  serviceRecord[accessor] as string,
                  SHORT_DATE_FORMAT,
                  true
                )) ||
                (serviceRecord[accessor] as string)}
            </span>
          </div>
        );
      })}
      {entityData &&
        Object.keys(entityData).map((key) => (
          <div
            key={key}
            className="grid grid-cols-[180px_auto] gap-x-3 py-2 border-t"
          >
            <span className="text-gray-400">{key}</span>
            <span className="break-words">{entityData[key]}</span>
          </div>
        ))}
    </InformationWrapper>
  );
};

export default GenericDetail;
