import Skeleton from "react-loading-skeleton";

import FormSection from "./FormSection";

const SignalEventPreviewVehicleTimeline = () => {
  return (
    <FormSection
      data-testId="vehicle-preview-timeline"
      title="Timeline for Selected Vehicle"
      text="See the custom events in the context of other events for this vehicle"
    >
      <Skeleton height={200} />
    </FormSection>
  );
};

export default SignalEventPreviewVehicleTimeline;
