import {
  CountRequest,
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import * as api from "./api";

export const useListVehicles = (args: api.ListVehiclesRequest) =>
  usePaginatedAPI<typeof args, api.Vehicle[]>(api.listVehiclesRequestURI, args);

export const useListVehiclesCount = (args: CountRequest) =>
  useAPI<typeof args, CountResponse>(api.listVehiclesCountRequestURI, args);

export const useVehicle = (args: api.GetVehicleRequest) =>
  useAPI<typeof args, api.Vehicle>(api.getVehicleRequestURI, args);

export const useVehicleAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    api.listVehicleAttributesRequestURI,
    args
  );

export const useListSimilarVehicleFactors = (
  args: api.ListSimilarVehicleFactorsRequest
) =>
  useAPI<typeof args, api.SimilarVehicleFactors>(
    api.listSimilarVehicleFactorsRequestURI,
    args
  );
