import { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { add, endOfDay, startOfDay } from "date-fns";
import { toDate } from "date-fns-tz";
import { Divider } from "@mui/material";

import {
  CustomSignalEventsRequestBody,
  CustomSignalEventsTimelineBucket,
} from "shared/api/customSignalEvents/api";
import { useListCustomSignalEventsTimeline } from "shared/api/customSignalEvents/hooks";
import { SensorReadingsTimelineGrouping } from "shared/api/sensors/api";
import { useSensorsReadingsTimeline } from "shared/api/sensors/hooks";
import { ServiceRecord } from "shared/api/serviceRecords/api";
import { useListServiceRecords } from "shared/api/serviceRecords/hooks";
import { SignalEventOccurrencesVINAggregateBucket } from "shared/api/signalEvents/api";
import { useListSignalEventOccurrencesVINTimeline } from "shared/api/signalEvents/hooks";
import { APIFilter, formatAPIDate, getSortFilter } from "shared/api/utils";
import { Vehicle } from "shared/api/vehicles/api";
import { useVehicle } from "shared/api/vehicles/hooks";
import { EventRegistryContext } from "shared/contexts/EventRegistryContext";
import { DATE_FILTER_GENERIC } from "shared/filterDefinitions";
import {
  useCustomLocalStorageState,
  useSignalEventOccurrencesFiltersSchema,
} from "shared/hooks";
import { EventTypeEnum, SortBy } from "shared/types";
import { getTenantMileageUnit } from "shared/utils";

import { SIGNAL_EVENTS_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";
import {
  addFiltersToInputEvent,
  getDateTypeForEventType,
} from "pages/SignalEventStudio/utils";
import {
  MAX_LIMIT_EVENTS,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY,
} from "pages/VINView/constants";
import EventDetail from "pages/VINView/ServiceRecords/EventDetail";

import ChartActions, {
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import { DataElement, ZoomXState } from "features/ui/charts/types";
import { getDefaultActions } from "features/ui/charts/utils";
import DropdownSelect from "features/ui/DropdownSelect";
import Filters from "features/ui/Filters";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
  getTopLevelRowFromFilterGroupState,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import * as config from "config/config";

import {
  CHART_ACTIONS,
  DEFAULT_R_AXIS_WIDTH,
  MAX_DATA_POINTS_UNTIL_SENSORS_CHART_SLOW,
} from "./constants";
import DataAlert from "./DataAlert";
import EventsTimelineGraph from "./EventsTimelineGraph";
import EventsTimelineTabs from "./EventsTimelineTabs";
import SensorAndTriggerFilters from "./SensorAndTriggerFilters";
import SensorsChart from "./SensorsCharts";
import useShownSignalEvents from "./useShownSignalEvents";
import {
  getSharedXAxisProps,
  partitionArray,
  processEventSignals,
  processVehicleHistoryEvents,
  processVehicleServiceRecords,
} from "./utils";
import VehicleServiceEventDialog from "./VehicleServiceEventDialog";

interface Props {
  vin: string;
  staticFilters?: APIFilter[];
  customEventTableRows?: SignalEventOccurrencesVINAggregateBucket[];
  customEventsRequestBody?: CustomSignalEventsRequestBody;
  customEventSchema?: SchemaEntry[];
}

const Y_AXIS_LETTER_WIDTH_PX = 4;
const CHART_OPTIONS_KEY_PREFIX = "sensorsChartOptions";
const DEFAULT_OCCURRENCES_SORT: SortBy = { date: "desc" };
const DEFAULT_DATE_ATTRIBUTE = "date";

const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY = (vin: string) =>
  `vin_event_timeline_se_filter_data_${vin}`;

const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY = (vin: string) =>
  `vin_event_timeline_${vin}`;

const {
  pages: { vinView, sensors },
} = config.get();

const getDefaultFromDate = (vehicle?: Vehicle) => {
  if (
    vinView?.eventTimelineFromDateVehicleAttribute &&
    vehicle?.[vinView.eventTimelineFromDateVehicleAttribute as keyof Vehicle]
  ) {
    return toDate(
      vehicle[
        vinView.eventTimelineFromDateVehicleAttribute as keyof Vehicle
      ] as string
    );
  }

  if (vinView?.eventTimelineFromDate) {
    return toDate(vinView.eventTimelineFromDate);
  }

  return add(new Date(), { months: -1 });
};

const EventsTimeline = ({
  vin,
  staticFilters,
  customEventTableRows,
  customEventsRequestBody,
  customEventSchema,
}: Props) => {
  const pageKey = VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY(vin);
  const pageSEFilterKey = VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY(vin);
  const pageSensorsTriggersKey =
    VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY(vin);

  const vinSEPendingFiltersKey = getPendingFiltersKey(pageSEFilterKey);

  const [sharedZoom, setSharedZoom] = useState<ZoomXState>();
  const [zoomReferenceAreaOverride, setZoomReferenceAreaOverride] =
    useState<JSX.Element | null>();

  const { data: vehicle } = useVehicle({
    vin,
    mileageUnit: getTenantMileageUnit(),
  });

  const eventTypes = useContext(EventRegistryContext);

  const vehicleHistoryEvents = processVehicleHistoryEvents(vehicle);

  const defaultActions = getDefaultActions(CHART_ACTIONS);
  const [selectedAggregationWindow, setSelectedAggregationWindow] =
    useCustomLocalStorageState<SelectedChartOptions[]>(
      `${CHART_OPTIONS_KEY_PREFIX}_${vin}`,
      {
        defaultValue: defaultActions,
      }
    );

  const aggregationWindow = selectedAggregationWindow[0]
    .optionId as SensorReadingsTimelineGrouping;

  const handleOnReferenceLineClick = ({ date }: ServiceRecord) => {
    const eventsWithSameTimestamp =
      events?.filter(
        (serviceRecord: ServiceRecord) => serviceRecord.date === date
      ) || [];
    setSelectedEvents(eventsWithSameTimestamp);
  };

  const signalEventsOccurrencesFiltersSchema =
    useSignalEventOccurrencesFiltersSchema(["VIN", "recordedAt"]);

  const [filterSummaryOpen, setFilterSummaryOpen] = useState<boolean>(false);

  const [hoveredSignal, setHoveredSignal] = useState<string>("");
  const [selectedEvents, setSelectedEvents] = useState<ServiceRecord[]>([]);

  const defaultFromDate = getDefaultFromDate(vehicle);
  const defaultToDate = vinView?.eventTimelineToDate
    ? toDate(vinView?.eventTimelineToDate)
    : new Date();

  const defaultFilterValues = filterStateToFilterGroupState({
    recordedAt: {
      values: [
        formatAPIDate(
          // we do startOfDay/endOfDay conversion here as we are not doing it anymore in the API filter conversion step since
          // we introduced the timepicker for dates with time. We do not have timepicker here as the filter is common for signals, sensors and service records
          startOfDay(defaultFromDate).toString(),
          DataType.DATE_WITH_TIME_NO_TZ
        ),
        formatAPIDate(
          endOfDay(defaultToDate).toString(),
          DataType.DATE_WITH_TIME_NO_TZ
        ),
      ],
      operator: FilterOperator.BETWEEN,
    },
  });

  const handleOnZoomOut = () => {
    setSharedZoom(undefined);
  };

  const {
    manageOnFilterChange,
    filters,
    initialized: filtersInitialized,
  } = useFilterSortState({
    pageKey,
    defaultFilterValues,
  });

  const sensorsTriggersFiltersState = useFilterSortState({
    pageKey: pageSensorsTriggersKey,
  });

  const signalEventsFilterSortState = useFilterSortState({
    pageKey: pageSEFilterKey,
    defaultFilterValues: vinView?.defaultSignalEventFilters
      ? filterStateToFilterGroupState(vinView.defaultSignalEventFilters)
      : undefined,
  });

  const recordedAtRow = getTopLevelRowFromFilterGroupState(
    "recordedAt",
    filters
  );

  const startDate =
    recordedAtRow && recordedAtRow?.values?.length > 0
      ? recordedAtRow?.values[0]
      : "";
  const endDate =
    recordedAtRow && recordedAtRow?.values?.length > 1
      ? recordedAtRow?.values[1]
      : "";

  const sharedXAxisProps = getSharedXAxisProps(
    startDate,
    endDate,
    aggregationWindow
  );

  const staticFiltersRecords: APIFilter[] = [
    ...(staticFilters ?? []),
    {
      name: "date",
      value: formatAPIDate(startDate, DataType.DATE),
      op: "gte",
    },
    {
      name: "date",
      value: formatAPIDate(endDate, DataType.DATE),
      op: "lte",
    },
  ];

  const sensorsTriggersStaticFilters: APIFilter[] = [
    ...(staticFilters ?? []),
    {
      name: "readAt",
      value: formatAPIDate(
        startOfDay(toDate(startDate)).toString(),
        DataType.DATE_WITH_TIME_NO_TZ
      ),
      op: "gte",
    },
    {
      name: "readAt",
      value: formatAPIDate(
        endOfDay(toDate(endDate)).toString(),
        DataType.DATE_WITH_TIME_NO_TZ
      ),
      op: "lte",
    },
  ];

  const sensorIDRow = getTopLevelRowFromFilterGroupState(
    "sensorID",
    sensorsTriggersFiltersState.filters
  );

  const selectedSensorsIDs = useMemo(
    () => sensorIDRow?.values || [],
    [sensorIDRow?.values]
  );

  const [prevSelectedSensorsIDs, setPrevSelectedSensorsIDs] = useState<
    string[]
  >([]);

  const filtersQuery = getFiltersQuery(
    sensorsTriggersFiltersState.filters,
    sensorsTriggersStaticFilters
  );

  const {
    data,
    error: sensorReadingsError,
    isLoading: sensorReadingsIsLoading,
  } = useSensorsReadingsTimeline({
    mileageUnit: getTenantMileageUnit(),
    sensorReadingsFilter: filtersQuery,
    grouping: aggregationWindow,
    skipRequest: !selectedSensorsIDs.length,
  });

  const allStateSensors = data?.metadata?.filter(
    ({ type }) => type === "state"
  );
  const allStateSensorsIDs = allStateSensors?.map(({ ID }) => ID);

  /**
   * If aggregationWindow === "none" we split sensors into point-value vs state sensors because we draw 2 different charts.
   * When sensorChartGrouping !== "none" state sensors are considered point-value sensors, but we use "count" instead of
   * "value" to display the chart.
   * See prepareSensorsChartData() for more info.
   */
  const showStateSensorsChart = aggregationWindow === "none";
  const [selectedStateSensorsIDs, selectedPointValueSensorsIDs] = useMemo(
    () =>
      showStateSensorsChart
        ? partitionArray(
            selectedSensorsIDs,
            (sensorID) => allStateSensorsIDs?.includes(sensorID) || false
          )
        : [[], selectedSensorsIDs],
    [selectedSensorsIDs, allStateSensorsIDs, showStateSensorsChart]
  );

  // When selected sensors change from no selected to some selected
  // we want to setSelectedAggregationWindow to default if they were on non-default previously.
  useEffect(() => {
    if (
      defaultActions.length > 0 &&
      prevSelectedSensorsIDs.length === 0 &&
      selectedSensorsIDs.length > 0 &&
      selectedAggregationWindow[0].optionId !== defaultActions[0].optionId
    ) {
      setSelectedAggregationWindow(defaultActions);
    }
    setPrevSelectedSensorsIDs(selectedSensorsIDs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSensorsIDs.length]);

  const selectedStateSensors = useMemo(
    () =>
      allStateSensors?.filter(({ ID }) => selectedStateSensorsIDs.includes(ID)),
    [allStateSensors, selectedStateSensorsIDs]
  );

  const selectedPointValueSensors = useMemo(
    () =>
      data?.metadata?.filter(({ ID }) =>
        selectedPointValueSensorsIDs.includes(ID)
      ),
    [data, selectedPointValueSensorsIDs]
  );

  const [pointValueSensorsData, stateSensorsData] = useMemo(() => {
    if (!data || !selectedStateSensorsIDs) return [];

    const actualData = data.data;

    return partitionArray(
      actualData,
      ({ sensorID }) => !selectedStateSensorsIDs.includes(sensorID)
    );
  }, [data, selectedStateSensorsIDs]);

  // We do not expect state sensors would really affect performance as much as point value "voltage" etc, so we only trim point value sensors
  // Note that state-sensors are actually point-value when selectedWindowAggregation is set to "daily" so we effectively trim both if that's the case
  const trimmedPointValueSensorsData = pointValueSensorsData?.slice(
    -MAX_DATA_POINTS_UNTIL_SENSORS_CHART_SLOW
  );

  const hasTwoPointValueSensorsSelected = Boolean(
    selectedPointValueSensors && selectedPointValueSensors.length >= 2
  );

  const {
    shownSignalEvents,
    setShownSignalEvents,
    resetInitialShownSignalEvents,
  } = useShownSignalEvents(vin);

  const {
    data: events,
    isLoading: eventsIsLoading,
    error: eventsError,
  } = useListServiceRecords({
    filter: getFiltersQuery(undefined, staticFiltersRecords),
    limit: MAX_LIMIT_EVENTS,
  });

  const vehicleFilter: FilterGroupState = filterStateToFilterGroupState({
    VIN: { values: [vin], operator: FilterOperator.EQUALS },
  });

  const signalEventsGlobalFilters = filterStateToFilterGroupState({
    recordedAt: {
      values: [
        formatAPIDate(
          startOfDay(toDate(startDate)).toString(),
          DataType.DATE_WITH_TIME_NO_TZ
        ),
        formatAPIDate(
          endOfDay(toDate(endDate)).toString(),
          DataType.DATE_WITH_TIME_NO_TZ
        ),
      ],
      operator: FilterOperator.BETWEEN,
    },
  });

  const allFilters = mergeFilterGroupStates(
    signalEventsGlobalFilters,
    vehicleFilter,
    signalEventsFilterSortState.filters
  );

  const signalEventOccurrencesVINTimelineFilter = getFiltersQuery(allFilters);

  const {
    data: signals,
    isLoading: signalsIsLoading,
    error: signalsError,
  } = useListSignalEventOccurrencesVINTimeline({
    filter: signalEventOccurrencesVINTimelineFilter,
    limit: MAX_LIMIT_EVENTS,
    grouping: aggregationWindow,
    sort: getSortFilter(DEFAULT_OCCURRENCES_SORT),
  });

  const hasCustomSignalEvents = customEventsRequestBody !== undefined;

  const eventType = customEventsRequestBody?.inputEventType as EventTypeEnum;
  const dateParam =
    eventTypes?.find((x) => x.type === eventType)?.dateAttribute ||
    DEFAULT_DATE_ATTRIBUTE;
  const dateType = getDateTypeForEventType(dateParam, customEventSchema);

  const updatedCustomEventsRequestBody: CustomSignalEventsRequestBody =
    (hasCustomSignalEvents && {
      ...customEventsRequestBody,
      inputEventFilter: addFiltersToInputEvent(
        filterStateToFilterGroupState({
          [dateParam]: {
            values: [
              formatAPIDate(startOfDay(toDate(startDate)).toString(), dateType),
              formatAPIDate(endOfDay(toDate(endDate)).toString(), dateType),
            ],
            operator: FilterOperator.BETWEEN,
          },
        }),
        customEventsRequestBody.inputEventFilter
      ),
    }) || {
      inputEventType: undefined,
      customAttributes: [],
      vehiclesFilter: `VIN=eq:${vin}`,
      mileageUnit: getTenantMileageUnit(),
    };

  const {
    data: customSignalEvents,
    isLoading: customSignalEventsIsLoading,
    error: customSignalEventsError,
  } = useListCustomSignalEventsTimeline(
    {
      skipRequest: !hasCustomSignalEvents,
      grouping: aggregationWindow,
    },
    updatedCustomEventsRequestBody
  );

  const [cursorX, setCursorX] = useState<number | null>(null);
  const [isSeDropdownOpen, setIsSeDropdownOpen] = useState(false);

  const handleOnMouseMove = (e?: any) => {
    setCursorX(e?.chartX);
  };

  // filters need a couple frames to set up default value, so we stop temporarily rendering until we get values. It is not visible to users.
  if (!startDate || !endDate) {
    return null;
  }

  const signalEventFilterLabel = getFilterLabel(
    "Signal Event Filters",
    signalEventsFilterSortState.filters
  );

  const hasSensorIDFilter = selectedSensorsIDs.length > 0;

  const serviceRecordReferenceLines = processVehicleServiceRecords(events);

  // filtered by date range and VIN but not by "shown"
  const signalsAndCustomSignalEvents: CustomSignalEventsTimelineBucket[] = [
    ...(signals || []),
    ...(customSignalEvents || []),
  ];
  const signalEventPoints = processEventSignals(
    signalsAndCustomSignalEvents,
    shownSignalEvents
  );

  const dateFilterOccurrences = signalsAndCustomSignalEvents.reduce(
    (acc: DataElement, curr) => {
      if (curr.signalEventID in acc) {
        acc[curr.signalEventID] += curr.occurrences;
      } else {
        acc[curr.signalEventID] = curr.occurrences;
      }
      return acc;
    },
    {}
  );

  // signal events chart: we calculate how much space we need on the left to display event label that can be rather long (ex. sgn_eng_oil_temp1_top_prct)
  const pointMaxNameLength = signalEventPoints.reduce((prev, current) => {
    return Math.max(current.signal.length, prev);
  }, 0);
  // I have come to this number by drawing different lenghts on screen, so it might be approximate. I think it's still better than a fixed number
  // - we re-use this on all 3 charts on this page
  const graphLeftMargin = Math.max(
    50,
    pointMaxNameLength * Y_AXIS_LETTER_WIDTH_PX
  );

  const showSensorsCharts = Boolean(
    sensors?.enabled &&
      hasSensorIDFilter &&
      (stateSensorsData?.length || pointValueSensorsData?.length) &&
      (selectedStateSensors?.length || selectedPointValueSensors?.length)
  );

  const handleSyncDateWithZoom = (zoom: ZoomXState) => {
    const from = formatAPIDate(
      toDate(zoom.left).toString(),
      DataType.DATE_WITH_TIME_NO_TZ
    );
    const to = formatAPIDate(
      toDate(zoom.right).toString(),
      DataType.DATE_WITH_TIME_NO_TZ
    );
    manageOnFilterChange({
      key: "recordedAt",
      op_id: FilterOperator.BETWEEN,
      values: [from, to],
      dataType: DataType.DATE_WITH_TIME_UTC,
    });
    handleOnZoomOut();
  };

  const globalSignalEventOccurrencesFilter: FilterGroupState =
    filterStateToFilterGroupState({
      recordedAt: {
        values: [
          formatAPIDate(
            startOfDay(toDate(startDate)).toString(),
            DataType.DATE_WITH_TIME_NO_TZ
          ),
          formatAPIDate(
            endOfDay(toDate(endDate)).toString(),
            DataType.DATE_WITH_TIME_NO_TZ
          ),
        ],
        operator: FilterOperator.BETWEEN,
      },
      VIN: { values: [vin], operator: FilterOperator.EQUALS },
    });

  const eventsTimelineTabsFilters = mergeFilterGroupStates(
    signalEventsFilterSortState.filters,
    globalSignalEventOccurrencesFilter
  );

  const isAdvancedEditor = signalEventsFilterSortState.isAdvancedFilterEditor;

  return (
    <>
      <div
        className={classNames("items-center", {
          flex: !sensors?.enabled,
          "2xl:flex space-y-3 2xl:space-y-0 2xl:space-x-6": sensors?.enabled,
        })}
      >
        <div className="flex items-end space-x-3 space-y-3 shrink-0">
          <Filters
            initialized={filtersInitialized}
            schema={[
              DATE_FILTER_GENERIC({
                fieldName: "recordedAt",
                label: "Date",
                filterDataType: DataType.DATE,
              }),
            ]}
            // we need this so the component re-renders when filters change (filters below is an object and it's reference doesn't change)
            key={startDate + endDate}
            onFilterChange={manageOnFilterChange}
            filters={filters}
            horizontal
          />
          <DropdownSelect
            testId="signal-events-filters-dropdown"
            label={signalEventFilterLabel}
            buttonClass="mt-6 h-[38px] mr-4"
            open={isSeDropdownOpen}
            onOpen={(open) => {
              setIsSeDropdownOpen(open);
            }}
            hasAdvancedFilters={isAdvancedEditor}
            content={
              <FilterSelector
                schema={signalEventsOccurrencesFiltersSchema}
                filterSortState={signalEventsFilterSortState}
                title={SIGNAL_EVENTS_FILTER_LABEL}
                testId="signal-events-filters"
                pendingFiltersKey={vinSEPendingFiltersKey}
                onCloseFilters={() => {
                  setIsSeDropdownOpen(false);
                }}
                initialIsAdvancedFilter={isAdvancedEditor}
              />
            }
          />
          <ViewFiltersButton
            open={filterSummaryOpen}
            onClick={() => setFilterSummaryOpen(true)}
            onClose={() => setFilterSummaryOpen(false)}
          />
          {sensors?.enabled && (
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              className="hidden 2xl:block"
            />
          )}
        </div>
        <div
          className={classNames(
            "flex items-end space-x-3 space-y-3  w-full",
            { "justify-end h-[50px]": !sensors?.enabled },
            { "justify-between": sensors?.enabled }
          )}
        >
          {sensors?.enabled && (
            <SensorAndTriggerFilters
              vin={vin}
              filterState={sensorsTriggersFiltersState}
            />
          )}
          <ChartActionsWrap id="sensors">
            <ChartActions
              actions={CHART_ACTIONS}
              selectedOptions={selectedAggregationWindow}
              onOptionChange={setSelectedAggregationWindow}
            />
          </ChartActionsWrap>
        </div>
      </div>
      <FiltersSummary
        open={filterSummaryOpen}
        filterStates={[
          {
            name: "Signal Event Filters",
            filters: signalEventsFilterSortState.filters,
            schema: signalEventsOccurrencesFiltersSchema,
          },
        ]}
      />
      <DataAlert data={pointValueSensorsData} />

      {showSensorsCharts ? (
        <SensorsChart
          filterState={sensorsTriggersFiltersState}
          grouping={aggregationWindow}
          endDate={endDate}
          serviceRecordReferenceLines={serviceRecordReferenceLines}
          vehicleHistoryEvents={vehicleHistoryEvents}
          sharedXAxisProps={sharedXAxisProps}
          cursorX={cursorX}
          currentZoom={sharedZoom}
          onMouseMove={handleOnMouseMove}
          onSyncDateWithZoom={handleSyncDateWithZoom}
          onReferenceLineClick={handleOnReferenceLineClick}
          margin={{ left: graphLeftMargin }}
          onZoom={setSharedZoom}
          onZoomOut={handleOnZoomOut}
          onZoomReferenceAreaChange={setZoomReferenceAreaOverride}
          zoomReferenceAreaOverride={zoomReferenceAreaOverride}
          pointValueSensorsData={trimmedPointValueSensorsData}
          stateSensorsData={stateSensorsData}
          pointValueSensors={selectedPointValueSensors}
          stateSensors={selectedStateSensors}
          isLoading={sensorReadingsIsLoading}
          error={sensorReadingsError}
          showEventTimelineChart={true}
        />
      ) : (
        <div className="mt-3" />
      )}
      <EventsTimelineGraph
        isLoading={
          signalsIsLoading ||
          eventsIsLoading ||
          (hasCustomSignalEvents && customSignalEventsIsLoading)
        }
        error={(signalsError || eventsError || customSignalEventsError)!}
        serviceRecordReferenceLines={serviceRecordReferenceLines}
        vehicleHistoryEvents={vehicleHistoryEvents}
        shownSignalEvents={shownSignalEvents}
        setHoveredSignal={setHoveredSignal}
        hoveredSignal={hoveredSignal}
        sharedXAxisProps={sharedXAxisProps}
        cursorX={cursorX}
        currentZoom={sharedZoom}
        onMouseMove={handleOnMouseMove}
        onZoom={setSharedZoom}
        onZoomOut={handleOnZoomOut}
        onZoomReferenceAreaChange={setZoomReferenceAreaOverride}
        onSyncDateWithZoom={handleSyncDateWithZoom}
        zoomReferenceAreaOverride={zoomReferenceAreaOverride}
        onReferenceLineClick={handleOnReferenceLineClick}
        signalEventPoints={signalEventPoints}
        margin={{
          left: graphLeftMargin,
          right: hasTwoPointValueSensorsSelected
            ? DEFAULT_R_AXIS_WIDTH
            : undefined,
        }}
        sensorChartsPresent={showSensorsCharts}
        grouping={aggregationWindow}
      />
      {selectedEvents.length > 0 && (
        <VehicleServiceEventDialog
          isOpen={true}
          onClose={() => setSelectedEvents([])}
        >
          <>
            {selectedEvents.map((serviceRecord: ServiceRecord) => (
              <EventDetail
                serviceRecord={serviceRecord}
                key={serviceRecord.ID}
              />
            ))}
          </>
        </VehicleServiceEventDialog>
      )}
      <EventsTimelineTabs
        vin={vin}
        shownSignalEvents={shownSignalEvents}
        setShownSignalEvents={setShownSignalEvents}
        resetInitialShownSignalEvents={resetInitialShownSignalEvents}
        hoveredSignal={hoveredSignal}
        setHoveredSignal={setHoveredSignal}
        filters={eventsTimelineTabsFilters}
        customEventTableRows={customEventTableRows}
        dateFilterOccurrences={dateFilterOccurrences}
      />
    </>
  );
};

export default EventsTimeline;
