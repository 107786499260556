import {
  CustomSignalEventEventDefinitionRequestBody,
  CustomSignalEventsRequestBody,
} from "shared/api/customSignalEvents/api";
import { EventTypeEnum } from "shared/types";
import { getTenantMileageUnit } from "shared/utils";

import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
  isFilterBuilderStateValid,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import {
  CUSTOM_ATTRIBUTE_FUNCTION_PARAMETERS,
  CustomAttributeFunction,
  DownsamplingOptions,
  STOP_WORDS,
} from "./constants";
import { CustomAttribute } from "./Form/CustomAttributes/CustomAttributeSelect";
import { FunctionInputConfig } from "./Form/CustomAttributes/FunctionInput/FunctionInput";
import { Downsampling } from "./Form/DownsamplingSection";

export const isCustomAttributeComplete = (
  customAttribute: CustomAttribute
): boolean => {
  if (!customAttribute.type) {
    return false;
  }

  const params: FunctionInputConfig[] =
    CUSTOM_ATTRIBUTE_FUNCTION_PARAMETERS[
      customAttribute.type as CustomAttributeFunction
    ];
  return params.every((param) => {
    if (param.parameter === "eventFilter") {
      return (
        customAttribute[param.parameter] &&
        !isFilterInvalid(customAttribute[param.parameter], true)
      );
    }

    return (
      customAttribute[param.parameter] !== undefined &&
      customAttribute[param.parameter] !== ""
    );
  });
};

export const isFilterInvalid = (
  filterBuilderState: FilterGroupState | undefined,
  isCustomAttribute: boolean = false
): boolean =>
  !!filterBuilderState &&
  !isFilterBuilderStateValid(filterBuilderState, !isCustomAttribute) &&
  filterBuilderState !== DEFAULT_FILTER_BUILDER_STATE;

export const validateCustomEventFormState = (
  name: string,
  id: string,
  selectedEventTypeOption: SelectOption | undefined,
  filterBuilderState: FilterGroupState | undefined,
  customAttributeFilterBuilderState: FilterGroupState | undefined,
  customAttributes: CustomAttribute[],
  selectedDownsamplingOption: Downsampling,
  customSignalEventsEnabled?: boolean
): string[] => {
  const errors = [];

  if (customSignalEventsEnabled && !name) {
    errors.push("Name is required");
  }

  if (customSignalEventsEnabled && !id) {
    errors.push("ID is required");
  }

  if (!selectedEventTypeOption) {
    errors.push("Base Event Type is required");
  }

  if (isFilterInvalid(filterBuilderState)) {
    errors.push("Standard Attribute filter is invalid");
  }

  if (isFilterInvalid(customAttributeFilterBuilderState, true)) {
    errors.push("Custom Attribute filter is invalid");
  }

  customAttributes.forEach((customAttribute) => {
    if (!isCustomAttributeComplete(customAttribute)) {
      errors.push(`Custom Attribute "${customAttribute.label}" is incomplete`);
    }
  });

  const customAttributeNames = customAttributes.map(
    (customAttribute) => customAttribute.label
  );
  if (new Set(customAttributeNames).size !== customAttributeNames.length) {
    errors.push("Custom Attribute names must be unique");
  }

  if (
    selectedDownsamplingOption.option === DownsamplingOptions.OCCURS_AT_LEAST &&
    (selectedDownsamplingOption.occurrences < 0 ||
      selectedDownsamplingOption.secondsAfter < 0 ||
      selectedDownsamplingOption.secondsBefore < 0)
  ) {
    errors.push("Downsampling is incomplete");
  }

  if (
    selectedDownsamplingOption.option === DownsamplingOptions.REST_PERIOD &&
    (selectedDownsamplingOption.secondsAfter < 0 ||
      selectedDownsamplingOption.secondsBefore < 0)
  ) {
    errors.push("Downsampling is incomplete");
  }
  return errors;
};

export const getCustomSignalEventRequestParams = (
  VIN: string,
  selectedEventTypeOption: SelectOption | undefined,
  filterBuilderState: FilterGroupState | undefined,
  customAttributeFilterBuilderState: FilterGroupState | undefined,
  customAttributes: CustomAttribute[],
  selectedDownsamplingOption: Downsampling,
  vehiclesFilterBuilderState?: FilterGroupState
): CustomSignalEventsRequestBody => {
  const vehiclesFilterState =
    vehiclesFilterBuilderState ||
    filterStateToFilterGroupState({
      VIN: { values: [VIN], operator: FilterOperator.IN },
    });

  return {
    inputEventType: selectedEventTypeOption!.id as EventTypeEnum,
    inputEventFilter: getFiltersQuery(filterBuilderState),
    customAttributeFilter: getFiltersQuery(customAttributeFilterBuilderState),
    vehiclesFilter: getFiltersQuery(vehiclesFilterState),
    customAttributes: customAttributes.map((customAttribute) => ({
      ID: customAttribute.id,
      label: customAttribute.label,
      type: customAttribute.type as CustomAttributeFunction,
      parameters: {
        eventType: customAttribute.eventType,
        eventFilter: getFiltersQuery(customAttribute.eventFilter) || undefined,
        field: customAttribute.field,
        aggregation: customAttribute.aggregation,
        direction: customAttribute.direction,
        unit: customAttribute.unit,
        start: customAttribute.start,
        end: customAttribute.end,
        fieldA: customAttribute.fieldA,
        fieldB: customAttribute.fieldB,
        before: customAttribute.before,
        after: customAttribute.after,
      },
    })),
    downsampling:
      selectedDownsamplingOption.option !== "none"
        ? {
            type: selectedDownsamplingOption.option as
              | "occursAtLeast"
              | "restPeriod",
            parameters: {
              after: selectedDownsamplingOption.secondsAfter,
              before: selectedDownsamplingOption.secondsBefore,
              occurrences: selectedDownsamplingOption.occurrences,
            },
          }
        : undefined,
    mileageUnit: getTenantMileageUnit(),
  };
};

export const getCreateCustomSignalEventRequestBody = (
  id: string,
  name: string,
  description: string | null,
  VIN: string,
  selectedEventTypeOption: SelectOption | undefined,
  filterBuilderState: FilterGroupState | undefined,
  customAttributeFilterBuilderState: FilterGroupState | undefined,
  customAttributes: CustomAttribute[],
  selectedDownsamplingOption: Downsampling
): CustomSignalEventEventDefinitionRequestBody => {
  return {
    ID: id,
    name: name,
    description: description,
    type: selectedEventTypeOption!.id as EventTypeEnum,
    eventDefinition: {
      inputEventType: selectedEventTypeOption!.id as EventTypeEnum,
      inputEventFilter: getFiltersQuery(filterBuilderState),
      customAttributeFilter: getFiltersQuery(customAttributeFilterBuilderState),
      vehiclesFilter: getFiltersQuery(
        filterStateToFilterGroupState({
          VIN: { values: [VIN], operator: FilterOperator.IN },
        })
      ),
      customAttributes: customAttributes.map((customAttribute) => ({
        ID: customAttribute.id,
        label: customAttribute.label,
        type: customAttribute.type as CustomAttributeFunction,
        parameters: {
          eventType: customAttribute.eventType,
          eventFilter:
            getFiltersQuery(customAttribute.eventFilter) || undefined,
          field: customAttribute.field,
          aggregation: customAttribute.aggregation,
          direction: customAttribute.direction,
          unit: customAttribute.unit,
          start: customAttribute.start,
          end: customAttribute.end,
          fieldA: customAttribute.fieldA,
          fieldB: customAttribute.fieldB,
          before: customAttribute.before,
          after: customAttribute.after,
        },
      })),
      downsampling:
        selectedDownsamplingOption.option !== "none"
          ? {
              type: selectedDownsamplingOption.option as
                | "occursAtLeast"
                | "restPeriod",
              parameters: {
                after: selectedDownsamplingOption.secondsAfter,
                before: selectedDownsamplingOption.secondsBefore,
                occurrences: selectedDownsamplingOption.occurrences,
              },
            }
          : undefined,
      mileageUnit: getTenantMileageUnit(),
    },
  };
};

export const getDateTypeForEventType = (
  dateParam: string,
  eventSchema?: SchemaEntry[]
): DataType => {
  const schemaEntry = eventSchema?.find(
    (entry) => entry.accessor === dateParam
  );
  return schemaEntry?.dataType || DataType.DATE_WITH_TIME_NO_TZ;
};

export const addFiltersToInputEvent = (
  filters: FilterGroupState,
  filterQuery?: string
) => {
  const existingFilters = filterBuilderQueryToFilterBuilderState(filterQuery);
  const mergedFilter = mergeFilterGroupStates(existingFilters, filters);
  return getFiltersQuery(mergedFilter);
};

export const getInputConfiguration = (
  selectedOptionID: string
): FunctionInputConfig[] => {
  if (!selectedOptionID) {
    return [];
  }
  return CUSTOM_ATTRIBUTE_FUNCTION_PARAMETERS[
    selectedOptionID as CustomAttributeFunction
  ];
};

export const combineCustomEventsTableSchema = (
  baseEventTypeSchema: SchemaEntry[] | undefined,
  customAttributesSchema: SchemaEntry[],
  skippedAccessors: string[] = []
): SchemaEntry[] => {
  return [
    {
      label: "date",
      accessor: `date`,
      dataType: "dateWithTime" as DataType,
    },
    ...(customAttributesSchema || []),
    ...(baseEventTypeSchema
      ?.filter(({ accessor }) => !skippedAccessors.includes(accessor))
      .map((schemaEntry) => ({
        ...schemaEntry,
        accessor: `inputEventData.${schemaEntry.accessor}`,
        filter: undefined,
        sortable: false,
      })) || []),
  ];
};

// Function creates dropdown options (id, value pairs) from multiple schemas for a certain type of value (number, date, etc.)
// See unit tests for more context
export const combineSchemasToSelectOptions = (
  primarySchema: SchemaEntry[] | undefined,
  primaryEventType: string,
  secondarySchema: SchemaEntry[] | undefined,
  secondaryEventType: string | undefined,
  combineSchemas: boolean,
  dataTypes: string[]
): DropdownSelectOption[] => {
  const filteredPrimarySchema =
    primarySchema?.filter((a) => dataTypes.includes(a.dataType)) || [];
  const filteredSecondarySchema =
    secondarySchema?.filter((a) => dataTypes.includes(a.dataType)) || [];

  if (!combineSchemas || primaryEventType === secondaryEventType) {
    if (primarySchema) {
      return filteredPrimarySchema.map((a) => ({
        id: a.accessor,
        value: a.label,
      }));
    }
    if (secondarySchema) {
      return filteredSecondarySchema.map((a) => ({
        id: a.accessor,
        value: a.label,
      }));
    }
  }

  const primaryAccessors = filteredPrimarySchema.map(
    ({ accessor }) => accessor
  );
  const secondaryAccessors = filteredSecondarySchema.map(
    ({ accessor }) => accessor
  );

  const commonAccessors = primaryAccessors
    .filter((x) => secondaryAccessors.includes(x))
    .sort();
  const onlyPrimaryAccessors = primaryAccessors
    .filter((x) => !secondaryAccessors.includes(x))
    .sort();
  const onlySecondaryAccessors = secondaryAccessors
    .filter((x) => !primaryAccessors.includes(x))
    .sort();

  return [
    ...commonAccessors.map((x) => ({
      id: x,
      value: [
        filteredPrimarySchema.find((y) => y.accessor === x)?.label || "",
        `(${primaryEventType}, ${secondaryEventType})`,
      ].join(" "),
    })),
    ...onlyPrimaryAccessors.map((x) => ({
      id: x,
      value: [
        filteredPrimarySchema.find((y) => y.accessor === x)?.label || "",
        `(${primaryEventType})`,
      ].join(" "),
    })),
    ...onlySecondaryAccessors.map((x) => ({
      id: x,
      value: [
        filteredSecondarySchema.find((y) => y.accessor === x)?.label || "",
        `(${secondaryEventType})`,
      ].join(" "),
    })),
  ];
};

const shortenWord = (word: string): string => {
  word = word.replace(/[^a-zA-Z]/g, "");

  if (STOP_WORDS.has(word)) {
    return "";
  }
  if (word.length > 3) {
    const firstLetter = word[0];
    const isFirstLetterVowel = /[aeiou]/i.test(firstLetter);
    const shortened = word.replace(/[aeiou]/gi, "");
    return isFirstLetterVowel
      ? firstLetter + shortened.slice(0, 2)
      : shortened.slice(0, 3);
  }
  return word;
};

export const getIdFromName = (name: string): string => {
  const snakeCaseName = name
    .toLowerCase()
    .split(" ")
    .map(shortenWord)
    .filter(Boolean)
    .join("_");

  const res = `cse_${snakeCaseName}`;
  return res.length > 30 ? res.slice(0, 30) : res;
};
