import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useIssuesAttributes } from "shared/api/issues/hooks";

export const IssueAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const IssuesAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useIssuesAttributes({});

  return (
    <IssueAttributeContext.Provider value={data}>
      {children}
    </IssueAttributeContext.Provider>
  );
};

export default IssuesAttributesContextWrapper;
