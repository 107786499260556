import classNames from "classnames";
import { ReactComponent as Logo } from "imgs/ViaductLogoBlack.svg";
import { useFlags } from "launchdarkly-react-client-sdk";
import qs from "qs";
import { FiGift as GiftIcon } from "react-icons/fi";
import { IoHelpCircleOutline as HelpIcon } from "react-icons/io5";
import { MdChevronLeft as ChevronLeft } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";

import { DOCS_RELEASE_NOTES_TAB_KEY } from "pages/Docs/Docs";
import { hasSeenLatestReleaseNotes } from "pages/Docs/ReleaseNotes";

import Logout from "features/auth/Logout";
import VINSearch from "features/layout/SidebarNav/VINSearch";

import { routes } from "services/routes";

import styles from "./SidebarNav.module.css";
import SideMenuGroup from "./SideMenuGroup";
import { getSideNavGroups, NAV_ITEM_ICON_SIZE } from "./utils";

interface Props {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  toggleExpand: () => void;
}

const SidebarNav = ({ expanded, setExpanded, toggleExpand }: Props) => {
  const flags = useFlags();

  const navGroups = getSideNavGroups({ flags });

  const showReleaseNotesIcon = !hasSeenLatestReleaseNotes();

  return (
    <>
      {expanded && (
        <div
          className="lg:hidden fixed bg-black w-full h-full z-10 bg-opacity-50"
          onClick={() => setExpanded(false)}
        ></div>
      )}
      <nav
        className={classNames(
          "fixed lg:sticky h-screen max-h-screen top-0 z-10 flex flex-shrink-0 bg-gray-50 shadow transition-width ease-in-out duration-300",
          {
            "w-64": expanded,
            "w-4": !expanded, // could also use hover:w-64 if we want to open sidebar on-hover (but then we need width hover variants added in tailwind.config.js)
          }
        )}
      >
        <div
          className={classNames("flex flex-1 overflow-x-hidden flex-col", {
            "opacity-0 transition-opacity duration-150": !expanded,
          })}
        >
          <ul className="mb-5">
            <li
              className="px-4 lg:px-6 mb-5 cursor-pointer tracking-normal focus:outline-none flex justify-between lg:block mt-6"
              key="logo"
            >
              <Link to={routes.vehicles}>
                <Logo className={styles.logo} title="Viaduct Logo" />
              </Link>
            </li>
            <li className="px-5">
              <VINSearch />
            </li>
          </ul>
          <div className={expanded ? "overflow-y-auto" : "overflow-hidden"}>
            <ul id="main-menu">
              {navGroups.map((group) => (
                <SideMenuGroup key={group.title} {...group} />
              ))}
            </ul>
            <ul className="flex justify-between px-4 lg:px-6 border-t border-gray-200">
              <li className="transition duration-200 cursor-pointer text-gray-600 tracking-normal py-2 hover:text-blue-400 focus:text-blue-400 focus:outline-none">
                <NavLink
                  to={{
                    pathname: routes.docs,
                    search:
                      showReleaseNotesIcon && flags.releaseNotes
                        ? qs.stringify({ tab: DOCS_RELEASE_NOTES_TAB_KEY })
                        : "",
                  }}
                  className={({ isActive }) =>
                    isActive
                      ? "flex items-center space-x-2 font-medium text-sm leading-none md:leading-3 hover:text-blue-400 text-blue-500"
                      : "flex items-center space-x-2 font-medium text-sm leading-none md:leading-3"
                  }
                  // hide the navigation on the documentation page
                  onClick={() => !showReleaseNotesIcon && setExpanded(false)}
                >
                  {showReleaseNotesIcon && flags.releaseNotes ? (
                    <GiftIcon
                      size={NAV_ITEM_ICON_SIZE}
                      className="animate-pulse text-blue-300"
                    />
                  ) : (
                    <HelpIcon size={NAV_ITEM_ICON_SIZE} />
                  )}
                  <span>Help</span>
                </NavLink>
              </li>
              <li className="transition duration-200 cursor-pointer text-gray-600 tracking-normal py-2 hover:text-blue-400 focus:text-blue-400 focus:outline-none">
                <Logout />
              </li>
            </ul>
          </div>
        </div>
        <div onClick={toggleExpand}>
          {/* Group classes need to be in HTML directly: https://github.com/tailwindlabs/tailwindcss/issues/2848 */}
          <div className="w-8 absolute top-0 bottom-0 transform z-10 group">
            <div
              className={classNames(
                "h-full left-0 relative w-6 group",
                styles.sizer
              )}
            >
              <div className="absolute bg-blue-300 opacity-0 h-full left-0 w-0.5 transition transition-colors transition-opacity pointer-events-none group-hover:opacity-100"></div>
            </div>
            <button
              role="separator"
              className="absolute top-8 w-6 h-6 border text-gray-500 shadow opacity-100 rounded-full p-0 transition -left-3 transition-colors bg-white cursor-pointer group-hover:text-white group-hover:border-blue-300 group-hover:bg-blue-300"
            >
              <span className="cursor-pointer fill-current flex-shrink-0 leading-none">
                <ChevronLeft
                  className={classNames("mx-auto fill-current transform", {
                    "rotate-180": !expanded,
                  })}
                  size={20}
                />
              </span>
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default SidebarNav;
