import { APIListValuesRequest } from "shared/api/api";
import { SortBy } from "shared/types";

import { SelectOption } from "features/ui/Select";
import { DataType } from "features/ui/Table/TableBodyCell";

import { FilterGroupState } from "./FilterBuilder/types";

/**
 * @interface FilterSchemaItem
 * @member {string} label label to display on top of filter
 * @member {boolean} fieldName API field identifier
 * @member {boolean?} search whether filter should hit API whenever search query is changed
 * @member {boolean?} loadDataOnOpen whether filter should load data when dropdown is opened but search query is empty
 * @member {boolean?} multiple whether to allow user to select mutliple values
 * @member {number?} maxValues maximum number of values that can be selected. If not set, there is no limit
 * @member {function?} loadOptionsFunc function that loads intial values for dropdown
 * @member {function?} transformInitialSelectedFunc function that converts raw data (like IDs) to human readable format (like label/description)
 * @member {function?} formatLabelFunc function that formats value in appropriate format and adds a label
 * @member {boolean} description description that appears in the tooltip next to label
 * @member {JSX.Element | string | undefined} filterType filter type in case we want to override the SearchSelect type
 * @member {string?} fieldNameForAPI if field on values endpoints do not match the one in the table, we can override it with setting custom field name
 * @member {boolean?} disableFiltering if we do not want to apply filters from current table for getting values for dropdown
 * @member {boolean?} disableSelectFilters if we do not want to allow "in" and "not in" operators for this filter and thus SearchSelect filter
 * @member {boolean?} disableContainsFilters if we do not want to allow "Contains" operators for this filter
 * @member {boolean?} disableIsEmptyFilters if we do not want to allow "Is empty" and "Is not empty" operators for this filter
 * @member {boolean?} disableStartsWithFilters if we do not want to allow "Starts with" and "Does not start with" operators for this filter
 * @member {boolean?} disableArbitraryText if we do not want to allow custom text to be typed as filter value in SelectFilter (freeSolo)
 * @member {boolean?} enableMinMaxFilters if we want to allow min/max filter for this filter
 */
export interface FilterSchemaItem {
  label: string;
  fieldName: string;
  search?: boolean;
  loadDataOnOpen?: boolean;
  multiple?: boolean;
  maxValues?: number;
  loadOptionsFunc?: (args: APIListValuesRequest) => Promise<SelectOption[]>;
  transformInitialSelectedFunc?: (
    initialSelected: SelectOption[]
  ) => Promise<SelectOption[]>;
  formatLabelFunc?: (selected: SelectOption[]) => SelectOption[];
  loadOptionsArgs?: Record<string, any>;
  description?: JSX.Element | string;
  filterType?: FilterType;
  filterDataType?: DataType;
  fieldNameForAPI?: string;
  disableFiltering?: boolean;
  disableSelectFilters?: boolean;
  disableContainsFilters?: boolean;
  disableIsEmptyFilters?: boolean;
  disableStartsWithFilters?: boolean;
  disableIsNotFilteredFilters?: boolean;
  enableMinMaxFilters?: boolean;
  disableArbitraryText?: boolean;
  whitelistedFilterOperators?: FilterOperator[];
  onlyAllowPositiveIntegers?: boolean;
  customFilter?: FilterGroupState;
  baseEntityText?: string;
  disableSelectingWindowDirection?: boolean;
}

export const enum FilterOperator {
  NOT_FILTERED = "not_filtered",
  IN = "in",
  NOT_IN = "not_in",
  IS_EMPTY = "is_empty",
  IS_NOT_EMPTY = "is_not_empty",
  GREATER_THAN = "greater_than",
  GREATER_OR_EQUAL = "greater_or_equal",
  LESS_THAN = "less_than",
  LESS_OR_EQUAL = "less_or_equal",
  BETWEEN = "between",
  CONTAINS = "contains",
  NOT_CONTAINS = "not_contains",
  IN_LAST = "in_last",
  STARTS_WITH = "starts_with",
  NOT_STARTS_WITH = "not_starts_with",
  IS_TRUE = "is_true",
  IS_FALSE = "is_false",
  EQUALS = "eq",
  NOT_EQUALS = "neq",
  RELATES = "relates",
}

export type FilterState = {
  [key: string]: SingleFilterState;
};

export type OccursFilterOperator = "occurs" | "noccurs";

export type RelatesFilterOptionKeys =
  | "windowSize"
  | "windowType"
  | "windowDirection";

export type RelatesFilterState = {
  operator: OccursFilterOperator;
  filters: FilterGroupState;
  options: Record<RelatesFilterOptionKeys, string | number>;
};

export type RelatesFilterStateKeys = "operator" | "filters" | "options";

export type SingleFilterState = {
  values: string[];
  operator: FilterOperator;
  extra?: Record<string, string>;
  relates?: RelatesFilterState;
};

export type FilterType = "string" | "number" | "date" | "boolean" | "relates";

export type OnFilterChangeProps = {
  key: string;
  op_id: FilterOperator;
  values?: string[];
  extra?: SingleFilterState["extra"];
  relates?: RelatesFilterState;
  dataType?: DataType;
};

export type onFilterChangeCallback = (props: OnFilterChangeProps) => void;
export type onInputChangeCallback = (inputValue: string) => void;

export type FilterOverviewFormat =
  | "badge"
  | "label"
  | "label-inline"
  | "label-block";
export type FilterValue = string | JSX.Element;

export type ActiveBgColor = `bg-${string}` | "";

export interface FilterEntry {
  fieldName: string;
  operator: FilterOperator;
  fieldValue: FilterValue;
}

type DateUnit = "d" | "m" | "w" | "y";

export interface DateUnitOption extends SelectOption<DateUnit> {}

export type InitialDateValues = (string | null)[];

export interface UseFilterSortStateProps {
  pageKey: string;
  defaultSort?: SortBy;
  defaultFailureModeColumns?: string[];
  disableUsingQuery?: boolean;
  disableUsingLocalStorage?: boolean;
  defaultFilterValues?: FilterGroupState;
  pendingFiltersLocalStorageKey?: string;
}

export interface FilterSortState {
  filters?: FilterGroupState;
  sort?: SortBy;
  columns?: string[];
}

export interface UseFilterSortState {
  initialized: boolean;
  sort: SortBy | undefined;
  manageOnSortChange: (next: SortBy) => void;
  filters: FilterGroupState;
  updateFilters: (filters: FilterGroupState) => void;
  manageOnFilterChange: onFilterChangeCallback;
  resetFilters: (fieldNames?: string[]) => void;
  resetSort: () => void;
  resetFilterSortState: () => void;
  failureModeColumns: string[];
  manageOnVisibleFMColumnChange: (columns: string[]) => void;
  isAdvancedFilterEditor: boolean;
  setIsAdvancedFilterEditor: (isAdvanced: boolean) => void;
}

export interface UseInitialStateValuesAndKeysReturn {
  initialValues: FilterSortState;
  queryKeys: {
    filtersKey: string;
    sortKey: string;
    columnsKey: string;
  };
}
