import { ClaimsFiltersProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import ClaimsChart from "pages/ClaimAnalytics/tabPages/Claims/ClaimsChart";
import ClaimsTable from "pages/ClaimAnalytics/tabPages/Claims/ClaimsTable";
import MetricsCards from "pages/ClaimAnalytics/tabPages/Claims/MetricsCards";

const Claims = ({
  claimsFilters,
  vehiclesFilters,
  onBadRequest,
}: ClaimsFiltersProps) => (
  <div className="mt-5">
    <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5">
      <div className="grow">
        <ClaimsChart
          claimsFilters={claimsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      </div>
      <MetricsCards
        claimsFilters={claimsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
      />
    </div>
    <ClaimsTable
      claimsFilters={claimsFilters}
      vehiclesFilters={vehiclesFilters}
      onBadRequest={onBadRequest}
    />
  </div>
);

export default Claims;
