import { format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import Skeleton from "react-loading-skeleton";

import { useClaimsMetricsHistory } from "shared/api/claims/hooks";
import { MONTH_YEAR_FULL } from "shared/constants";
import { useCustomLocalStorageState } from "shared/hooks";

import { ClaimsFiltersProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { ChartTooltip } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/ChartTooltip";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import {
  cumulativeCost,
  cumulativeCPV,
  cumulativeIPTV,
  getCumulativeEntityCount,
  getRollingEntityCount,
  rollingCost,
  rollingCPV,
  rollingIPTV,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions, {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import LineChart from "features/ui/charts/LineChart";
import {
  getAxisLabel,
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { SelectOption } from "features/ui/Select";

import { prepareMetricsHistoryDataByTimestamp } from "./utils";

const CHART_HEIGHT_PX = 400;
const CHART_OPTIONS_KEY = "claimAnalyticsClaimsChartOptions";

const ClaimsChart = ({
  claimsFilters,
  vehiclesFilters,
  onBadRequest,
}: ClaimsFiltersProps) => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();

  const yAxisOptions: SelectOption[] = [
    rollingIPTV,
    cumulativeIPTV,
    warrantyClaimsCostFF && rollingCPV,
    warrantyClaimsCostFF && cumulativeCPV,
    getRollingEntityCount("Claim"),
    getCumulativeEntityCount("Claim"),
    warrantyClaimsCostFF && rollingCost,
    warrantyClaimsCostFF && cumulativeCost,
  ].filter(Boolean) as SelectOption[];

  const actions: ChartAction[] = [
    {
      id: "y",
      title: yAxis,
      type: "dropdownSelect",
      options: yAxisOptions,
    },
  ];

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_OPTIONS_KEY, { defaultValue: getDefaultActions(actions) });

  const { data, isLoading, error } = useClaimsMetricsHistory({
    claimsFilter: getFiltersQuery(claimsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
  });

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={CHART_HEIGHT_PX} />
      </Card>
    );
  }

  if (!data) {
    return (
      <Card>
        <div className="text-gray-500">No data available</div>
      </Card>
    );
  }

  const formattedChartData = prepareMetricsHistoryDataByTimestamp(data);

  const yAxisKey = getSelectedOptionId(selectedOptions, "y");
  const yAxisLabel = getAxisLabel(actions, "y", yAxisKey);

  const chartLines = [
    {
      key: yAxisKey,
      label: yAxisLabel,
    },
  ];

  return (
    <Card>
      <ChartActionsWrap>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      <LineChart
        height={400}
        width="100%"
        data={formattedChartData}
        xAxisKey="ts"
        yAxisLines={chartLines}
        xAxisLabel="Repair Date"
        enableZoom={true}
        xAxisProps={{
          domain: ["auto", "auto"],
          tickFormatter: (unixTime) => format(unixTime, "MMM yy"),
        }}
        tooltipProps={{
          labelFormatter: (unixTime: number) =>
            format(unixTime, MONTH_YEAR_FULL),
          content: (props: any) => (
            <ChartTooltip {...props} yAxisOptions={yAxisOptions} />
          ),
        }}
      />
    </Card>
  );
};

export default ClaimsChart;
