import { useEffect, useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useCustomLocalStorageState } from "shared/hooks";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { useGroupBySelectOptions } from "shared/schemas/hooks";

import { ClaimAnalyticsProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { useExposures } from "pages/hooks";
import {
  buildNewTopContributorsSelectedOptions,
  getDefaultTopContributorChartActions,
  getTopContributorsChartActions,
} from "pages/utils";

import { SelectedChartOptions } from "features/ui/charts/ChartActions";
import { getDefaultActions } from "features/ui/charts/utils";
import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { SelectOption } from "features/ui/Select";

import { getTopContributorsChartYAxisOptions } from "./ChartActions";
import { useClaimTopContributorsExposureOptions } from "./hooks";
import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";

const DEFAULT_GROUP_BY_ATTRIBUTE = "laborCode";
const DEFAULT_GROUP_BY_ATTRIBUTE_LABEL = "Labor code";
const DEFAULT_GROUP_BY_SELECT_OPTION: SelectOption = {
  id: DEFAULT_GROUP_BY_ATTRIBUTE,
  value: DEFAULT_GROUP_BY_ATTRIBUTE_LABEL,
};

const GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY =
  "claimAnalyticsTopContributorsGroupByAttribute";

const CHART_KEY = "claimAnalyticsTopContChartOptions";

const TopContributors = ({
  claimsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: ClaimAnalyticsProps) => {
  const { warrantyClaimsCost } = useFlags();

  const { attributes } = useClaimsSchema();

  const exposures = useClaimTopContributorsExposureOptions();

  const yAxisOptions = getTopContributorsChartYAxisOptions(warrantyClaimsCost);
  const defaultActions = getDefaultTopContributorChartActions(
    attributes,
    CHART_KEY,
    yAxisOptions,
    exposures
  );

  const [actions, setActions] = useState(defaultActions);

  const claimsFilters = claimsFiltersFilterSortState?.filters;
  const vehiclesFilters = vehiclesFiltersFilterSortState?.filters;

  const groupBySelectOptions = useGroupBySelectOptions("claim");
  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const [selectedGroupByAttribute, setSelectedGroupByAttribute] =
    useCustomLocalStorageState<SelectOption>(
      GROUP_BY_ATTRIBUTE_LOCAL_STORAGE_KEY,
      {
        defaultValue: DEFAULT_GROUP_BY_SELECT_OPTION,
      }
    );

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_KEY, {
    defaultValue: getDefaultActions(actions),
  });

  const {
    previousExposure,
    currentExposure,
    currentExposureBuckets,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
  } = useExposures(attributes, selectedOptions);

  useEffect(() => {
    // handle scenario when exposure is changed which means that we need to change exposureBucket options
    if (previousExposure.current !== currentExposure) {
      previousExposure.current = currentExposure;

      setActions(
        getTopContributorsChartActions(
          yAxisOptions,
          exposures,
          currentExposureBuckets,
          currentExposure
        )
      );

      setSelectedOptions(
        buildNewTopContributorsSelectedOptions(
          selectedOptions,
          currentExposureBuckets
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  const resetState = () => {
    setSelectedGroupByAttribute(DEFAULT_GROUP_BY_SELECT_OPTION);
    claimsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <div className="mt-4">
        {groupBySelectOptions.length > 0 && (
          <div className="mb-5">
            <div className="text-sm mb-1">
              Select a dimension to update the x-axis of the chart and first
              column of the table:
            </div>
            {selectedGroupByAttribute && (
              <DropdownWithSearch
                options={groupBySelectOptions}
                selectedOption={selectedGroupByAttribute}
                label="Group by attribute"
                onSelectedOptionChange={setSelectedGroupByAttribute}
                className="mt-4"
                testId="top-contributors-group-by-dropdown"
              />
            )}
          </div>
        )}
      </div>
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        claimsFilters={claimsFilters}
        filters={generalFilters}
        onClaimsFiltersChange={
          claimsFiltersFilterSortState?.manageOnFilterChange
        }
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageOnFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        claimsFilters={claimsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
