import { withLDProvider } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";

import "react-toastify/dist/ReactToastify.css";

import * as heap from "services/heap";
import { isInIFrame } from "services/iframe";
import { oktaAuth } from "services/okta";
import * as config from "config/config";

import Routes from "./Routes";

const Root = () => {
  const navigate = useNavigate();

  const {
    analytics: { heap: heapConfig },
    isIframed,
  } = config.get();

  if (heapConfig?.id) {
    try {
      heap.init();
    } catch (err) {
      console.error("failed to init heap", err);
    }
  }

  const restoreOriginalUri = (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "", window.location.origin), {
      replace: true,
    });
  };

  // Disallow embedding, unless the app's config has isIframed = true
  if (isInIFrame() && !isIframed) {
    return (
      <div className="text-center">
        iFrame is unsupported. For more information, contact{" "}
        {config.SUPPORT_EMAIL}
      </div>
    );
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes />
      <ToastContainer transition={Slide} />
    </Security>
  );
};

const {
  featureFlags: { launchDarklyClientId },
} = config.get();

export default withLDProvider({
  clientSideID: launchDarklyClientId,
  context: {
    kind: "user",
    key: "anonymous",
  },
})(Root);
