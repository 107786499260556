import { useGetIssueSignalEventsByCalendarTime } from "shared/api/issues/hooks";
import { useCustomLocalStorageState } from "shared/hooks";
import { IssueTypes } from "shared/types";

import ChartWithComparisonPopulation from "pages/Issues/Charts/ChartWithComparisonPopulation";
import PopulationChart from "pages/Issues/Charts/PopulationChart";
import {
  getAxisKeyLabelFromActions,
  getChartOptionsKey,
} from "pages/Issues/utils";

import Card from "features/ui/Card";
import {
  cumulativeDPTV,
  cumulativeDPTVDV,
  rollingDPTV,
  rollingDPTVDV,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions, {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import { getDefaultActions } from "features/ui/charts/utils";

const actions: ChartAction[] = [
  {
    id: "y",
    title: yAxis,
    type: "dropdownSelect",
    options: [cumulativeDPTVDV, rollingDPTVDV, cumulativeDPTV, rollingDPTV],
  },
];

const CHART_NAME = "SignalEvents_OccurrencesByCalendarTime";
const TITLE = "Signal Event Occurrences by Date";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const OccurrencesByCalendarTimeChart = ({
  issue,
  withComparisonPopulation,
}: Props) => {
  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID);
  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const { axisKey: yAxisKey, axisValue: yAxisValue } =
    getAxisKeyLabelFromActions(selectedOptions, actions, "y");

  const PopulationsChart = withComparisonPopulation
    ? ChartWithComparisonPopulation
    : PopulationChart;

  return (
    <Card>
      <ChartActionsWrap id={CHART_NAME} chartTitle={TITLE}>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      <PopulationsChart
        issue={issue}
        yAxisKey={yAxisKey}
        yAxisLabel={yAxisValue}
        chartType="CalendarTime"
        chart={CHART_NAME}
        dataLoadHook={useGetIssueSignalEventsByCalendarTime}
      />
    </Card>
  );
};

export default OccurrencesByCalendarTimeChart;
