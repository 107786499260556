import Skeleton from "react-loading-skeleton";

import { Risk } from "shared/api/api";

import PieChart from "features/ui/charts/PieChart";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import InfoIcon from "features/ui/Icons/Info";

import { useRisks } from "./hooks";
import RiskCount from "./RiskCount";

export interface RiskDistributionProps {
  filters?: FilterGroupState;
  failureModeID: string;
}

const RiskDistribution = ({
  filters,
  failureModeID,
}: RiskDistributionProps) => {
  const { riskCounts, isLoading } = useRisks({
    filters,
    failureModeID,
  });
  const { low, medium, high } = riskCounts;

  if (
    isLoading ||
    low === undefined ||
    medium === undefined ||
    high === undefined
  ) {
    return (
      <div className="my-10">
        <Skeleton
          height={215}
          count={4}
          containerClassName="lg:flex flex-1 lg:space-x-4"
        />
      </div>
    );
  }

  const hasResults = low > 0 || medium > 0 || high > 0;

  return (
    <div className="lg:flex lg:space-x-4">
      <div className="lg:flex flex-1 lg:space-x-4">
        {Object.entries(riskCounts).map(([key, value]) => (
          <RiskCount key={key} risk={key as Risk} count={value} />
        ))}
      </div>
      <div className="w-96 shadow flex-initial p-4 rounded">
        <div>
          <h3 className="text-sm mb-3">
            <span className="align-middle mr-1">Risk Distribution</span>
            <InfoIcon
              text="Risk Distribution only includes vehicles that have a current risk status for this Failure Mode."
              size="xs"
            />
          </h3>
          {hasResults && (
            <PieChart
              data={[
                { name: "High", value: high, color: "#F87171" },
                {
                  name: "Medium",
                  value: medium,
                  color: "#facc15",
                },
                { name: "Low", value: low, color: "#4ade80" },
              ]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RiskDistribution;
