import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useServiceRecommendationsAttributes } from "shared/api/serviceRecommendations/hooks";

export const ServiceRecommendationsAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const ServiceRecommendationsAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useServiceRecommendationsAttributes({});

  return (
    <ServiceRecommendationsAttributeContext.Provider value={data}>
      {children}
    </ServiceRecommendationsAttributeContext.Provider>
  );
};

export default ServiceRecommendationsAttributesContextWrapper;
