import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import MetricsCards from "./MetricsCards";
import SignalEventsChart from "./SignalEventsChart";
import SignalEventsTable from "./SignalEventsTable";

const SignalEvents = ({
  signalEventsFilters,
  vehiclesFilters,
  onBadRequest,
}: SignalEventsFiltersProps) => (
  <div className="mt-5">
    <div className="flex flex-col lg:flex-row space-y-5 lg:space-y-0 lg:space-x-5">
      <div className="grow">
        <SignalEventsChart
          signalEventsFilters={signalEventsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      </div>
      <MetricsCards
        signalEventsFilters={signalEventsFilters}
        vehiclesFilters={vehiclesFilters}
        onBadRequest={onBadRequest}
      />
    </div>
    <SignalEventsTable
      signalEventsFilters={signalEventsFilters}
      vehiclesFilters={vehiclesFilters}
      onBadRequest={onBadRequest}
    />
  </div>
);

export default SignalEvents;
