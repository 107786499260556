import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useDealerAttributes } from "shared/api/signalEvents/hooks";

export const DealerAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const DealerAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useDealerAttributes({});

  return (
    <DealerAttributeContext.Provider value={data}>
      {children}
    </DealerAttributeContext.Provider>
  );
};

export default DealerAttributesContextWrapper;
