import { useState } from "react";
import { CgSpinnerTwo as LoadingIcon } from "react-icons/cg";
import { generatePath, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { mutate } from "swr";

import {
  CustomSignalEventDefinition,
  deleteCustomSignalEventDefinition,
  newCustomSignalEventDefinition,
  updateCustomSignalEventDefinition,
} from "shared/api/customSignalEvents/api";

import { ERROR_STATUSES } from "pages/CustomSignalEvents/constants";
import PublishSignalEventAction from "pages/CustomSignalEvents/PublishCustomSignalEventAction";
import { getCustomSignalEventState } from "pages/CustomSignalEvents/utils";
import { SAVE_CSE, UPDATE_CSE } from "pages/SignalEventStudio/constants";
import { SingnalEventStudioState } from "pages/SignalEventStudio/hooks";
import {
  getCreateCustomSignalEventRequestBody,
  getIdFromName,
} from "pages/SignalEventStudio/utils";

import Button from "features/ui/Button";
import DeleteAction from "features/ui/DeleteAction";
import Input from "features/ui/Input";

import { routes } from "services/routes";

import ErrorSection from "./ErrorSection";
import StatusSection from "./StatusSection";

interface Props {
  signalEventStudioState: SingnalEventStudioState;
  customSignalEventDefinition?: CustomSignalEventDefinition;
  requestKey?: string;
}

const SignalEventOverview = ({
  signalEventStudioState,
  customSignalEventDefinition,

  requestKey,
}: Props) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    id,
    setId,
    isIdSet,
    setIsIdSet,
    name,
    setName,
    description,
    setDescription,
    selectedVIN,
    selectedEventTypeOption,
    filterBuilderState,
    customAttributeFilterBuilderState,
    customAttributes,
    selectedDownsamplingOption,
    editMode,
    isValidForm,
  } = signalEventStudioState;
  const ctaIcon =
    (isSubmitting && <LoadingIcon className="animate-spin" />) || undefined;
  // if the user sets its own id, we do not change it automatically unless they clear it
  const handleIdSet = (value: string) => {
    setIsIdSet(value !== "");
    setId(value);
  };

  const handleNameSet = (value: string) => {
    if (value !== "" && !isIdSet) {
      setId(getIdFromName(value));
    }
    // unset ID only if custom signal event definition is not saved yet
    if (value === "" && !customSignalEventDefinition) {
      setId("");
      setIsIdSet(false);
    }
    setName(value);
  };

  const {
    actionsState: { canEditID, canEditDefinition, canPublish, canDelete },
  } = getCustomSignalEventState(customSignalEventDefinition?.status, editMode);

  const disableSubmit = isSubmitting || !isValidForm || !canEditDefinition;
  const disablePublish = isSubmitting || !isValidForm || !canPublish;

  // mbencina: we will have to handle create and update
  const handleOnSubmit = () => {
    if (disableSubmit) {
      return;
    }
    setIsSubmitting(true);

    const callback = customSignalEventDefinition
      ? updateCustomSignalEventDefinition
      : newCustomSignalEventDefinition;

    const requestBody = getCreateCustomSignalEventRequestBody(
      id,
      name,
      description,
      selectedVIN!,
      selectedEventTypeOption,
      filterBuilderState,
      customAttributeFilterBuilderState,
      customAttributes,
      selectedDownsamplingOption
    );

    // unset error status if set during update
    const body = customSignalEventDefinition
      ? {
          ...requestBody,
          status: ERROR_STATUSES.includes(customSignalEventDefinition.status)
            ? "ready_for_validation"
            : customSignalEventDefinition.status,
        }
      : requestBody;

    callback(body)
      .then(({ data: { ID } }) => {
        toast.success(
          `Custom Signal Event ${customSignalEventDefinition ? "updated" : "created"}`
        );
        requestKey && mutate(requestKey);
        navigate(generatePath(routes.customSignalEvent, { id: ID }));
      })
      .catch(() => {
        toast.error(
          `${customSignalEventDefinition ? "Updating" : "Creating"} Custom Signal Event failed`
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="w-[65rem] mt-5">
      <div className="mb-5 flex">
        <div className="flex w-full">
          <div className="mr-4 leading-9 text-right w-[82px]">Name</div>
          <Input
            testId="cse-name-input"
            value={name}
            onChange={({ target: { value } }) => handleNameSet(value)}
            disabled={!canEditDefinition}
            fullWidth={false}
            className="w-80"
            tabIndex={0}
            characterLimit={50}
          />
          <div className="flex items-center ml-auto">
            <div className="mr-4 ml-auto">
              <DeleteAction
                data={{
                  ID: customSignalEventDefinition
                    ? customSignalEventDefinition.ID
                    : "",
                  name,
                }}
                entityName="custom signal event"
                deleteCallback={deleteCustomSignalEventDefinition}
                iconOnly={true}
                onSuccessNavigateToRoute={routes.customSignalEvents}
                onSuccessDeleteText="Succesfully scheduled for deletion"
                disabled={!canDelete}
              />
            </div>
            <Button
              color="primary"
              variant="contained"
              label={customSignalEventDefinition ? UPDATE_CSE : SAVE_CSE}
              onClick={handleOnSubmit}
              isLoading={isSubmitting}
              endIcon={ctaIcon}
              disabled={disableSubmit}
              size="medium"
              className="!mr-4"
              tabIndex={-1}
            />
            <PublishSignalEventAction
              data={customSignalEventDefinition}
              entityRequestKey={requestKey}
              disablePublish={disablePublish}
              setIsSubmitting={setIsSubmitting}
            />
          </div>
        </div>
      </div>
      <div className="mb-5 flex">
        <div className="mr-4 leading-9 text-right shrink-0 w-[82px]">ID</div>
        <div className="w-full">
          <Input
            testId="cse-id-input"
            value={id}
            onChange={({ target: { value } }) => handleIdSet(value)}
            disabled={!canEditID}
            fullWidth={false}
            className="w-80"
            tabIndex={0}
            characterLimit={30}
          />
        </div>
      </div>
      <div className="mb-5 flex">
        <div className="mr-4 leading-9 text-right shrink-0 w-[82px]">
          Description
        </div>
        <div className="w-full">
          <Input
            value={description || ""}
            onChange={({ target: { value } }) => setDescription(value)}
            disabled={!canEditDefinition}
            tabIndex={1}
            characterLimit={300}
          />
        </div>
      </div>
      {customSignalEventDefinition && (
        <div className="mb-5 flex">
          <div className="mr-4 leading-9 text-right shrink-0 w-[82px]">
            Status
          </div>
          <div className="w-full">
            <StatusSection
              customSignalEventDefinition={customSignalEventDefinition}
            />
          </div>
        </div>
      )}
      {customSignalEventDefinition &&
        ERROR_STATUSES.includes(customSignalEventDefinition.status) && (
          <div className="mb-5 w-full">
            <ErrorSection
              customSignalEventDefinition={customSignalEventDefinition}
            />
          </div>
        )}
      <div className="text-gray-600 text-sm mb-4 max-w-4xl">
        Your custom signal event can be based on any event type. It is defined
        by the combination of filters on base event type attributes, and filters
        on derived attributes you create. You see how your custom signal
        performs by viewing it in the vehicle timeline for any vehicle you
        choose.
      </div>
    </div>
  );
};

export default SignalEventOverview;
