import { useState } from "react";

import { useListCalculatedAttributes } from "shared/api/calculatedAttributes/hooks";
import { SIGNAL_EVENTS_GENERIC_FILTER } from "shared/filterDefinitions";
import useVehiclesFailureModesSchema from "shared/schemas/vehiclesFailureModesSchema";

import { CALCULATED_ATTRIBUTES_TITLE } from "pages/CalculatedAttributes/constants";
import { DEFAULT_RULE_STATE } from "pages/ServicePlans/constants";
import { ServicePlanRuleState } from "pages/ServicePlans/types";
import { getFilterStateFromRecommendation } from "pages/ServicePlans/utils";
import RecommendationCount from "pages/ServiceRecommendations/RecommendationCount";

import DynamicTabs from "features/ui/DynamicTabs";
import { FilterSchemaItem } from "features/ui/Filters/types";
import { Tab } from "features/ui/StatefulTabs";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell";

import * as config from "config/config";

import RuleTab from "./RuleTab";

interface Props {
  servicePlanId?: string;
  rules: ServicePlanRuleState[];
  setRules: (rules: ServicePlanRuleState[]) => void;
  disabledInput: boolean;
}

const Rules = ({ servicePlanId, rules, setRules, disabledInput }: Props) => {
  const {
    pages: { failureModes },
  } = config.get();
  const addFMColumns = failureModes?.enabled;

  const { data: calculatedAttributes } = useListCalculatedAttributes({});

  const vehicleFMAttributesSchema = useVehiclesFailureModesSchema(!addFMColumns)
    .filter((entry) => entry.filter)
    .map((schemaEntry) => ({
      ...schemaEntry,
      dropdownGroup: "Failure Modes",
    }));

  const calculatedAttributesSchema: SchemaEntry[] =
    calculatedAttributes?.map((attribute) => ({
      label: attribute.name,
      accessor: attribute.ID,
      dataType: "number" as DataType,
      filter: {
        label: attribute.name,
        fieldName: attribute.ID,
        filterType: "number",
        disableSelectFilters: true,
      },
      dropdownGroup: CALCULATED_ATTRIBUTES_TITLE,
    })) || [];

  const relatedSignalEventsSchemaEntry = {
    label: "Signal Events",
    accessor: "relatedSignalEventOccurrences",
    dataType: "string" as DataType,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      filterType: "relates",
      label: "Signal Events",
      fieldName: "relatedSignalEventOccurrences",
      disableFiltering: true,
      baseEntityText: "",
      disableSelectingWindowDirection: true,
      search: true,
      fieldNameForAPI: "ID",
    }),
    dropdownGroup: "Signal Events",
  };

  const combinedSchema = [
    ...calculatedAttributesSchema,
    relatedSignalEventsSchemaEntry,
    ...vehicleFMAttributesSchema,
  ].map((schemaEntry) => ({
    // We want to disable "NOT FILTERED" here for all attributes
    ...schemaEntry,
    filter: {
      ...schemaEntry.filter,
      disableIsNotFilteredFilters: true,
    } as FilterSchemaItem,
  }));

  const [activeTab, setActiveTab] = useState(0);

  const onTabAdd = () => {
    const updatedRules = [...rules, DEFAULT_RULE_STATE];
    setRules(updatedRules);
    setActiveTab(updatedRules.length - 1);
  };

  const onTabRemove = (index: number) => {
    const rulesAfterRemove = rules.filter((_, i) => i !== index);
    setRules(rulesAfterRemove);
    // we either keep the same index or go to last tab if we removed the last one
    setActiveTab(Math.min(activeTab, rulesAfterRemove.length - 1));
  };

  const onRuleChange = (rule: ServicePlanRuleState, index: number) =>
    setRules(rules.map((r, i) => (i === index ? rule : r)));

  const tabs: Tab[] = rules.map((r, index) => ({
    key: `recommendation-${index}`,
    title: `Recommendation ${index + 1}`,
    content: (
      <RuleTab
        schema={combinedSchema}
        key={index}
        rule={r}
        onRuleChange={(rule) => onRuleChange(rule, index)}
        disabledInput={disabledInput}
        servicePlanId={servicePlanId}
      />
    ),
  }));

  const showCount = servicePlanId && disabledInput;

  return (
    <DynamicTabs
      tabs={tabs}
      onTabAdd={onTabAdd}
      onTabRemove={onTabRemove}
      onTabChange={setActiveTab}
      activeTab={activeTab}
      disabledInput={disabledInput}
      extraComponent={
        showCount && (
          <RecommendationCount
            filterState={getFilterStateFromRecommendation(null, servicePlanId)}
          />
        )
      }
    />
  );
};

export default Rules;
