import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { useFailureMode } from "shared/api/hooks";

import StatusBadge from "pages/FailureModes/StatusBadge";

import APIError from "features/ui/APIError";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import FailureModeTabs from "./FailureModeTabs";

type FailureModeParams = {
  id: string;
};

const FailureMode = () => {
  const { id } = useParams<FailureModeParams>();
  const {
    data: failureMode,
    isLoading,
    error,
  } = useFailureMode({
    id: id as string,
  });

  if (error) {
    return <APIError error={error} />;
  }

  if (!isLoading && !failureMode) {
    return (
      <div className="py-4 text-gray-400 text-sm">
        No data for Failure Mode {id}.
      </div>
    );
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      <PageHeaderWrapper>
        <div>
          <Title text={failureMode?.name as string} />
          <h5 className="text-gray-500 text-sm max-w-xl">
            {failureMode?.description}
          </h5>
        </div>
        <PageHeaderActionsWrapper>
          <StatusBadge status={failureMode?.status as string} />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <FailureModeTabs id={id as string} />
    </>
  );
};

export default FailureMode;
