import Skeleton from "react-loading-skeleton";

import FormSection from "./FormSection";

const SignalEventPreviewVehicleEvents = () => {
  return (
    <FormSection
      data-testId="vehicle-preview-events"
      title="Events for selected Vehicle"
    >
      <Skeleton height={200} />
    </FormSection>
  );
};

export default SignalEventPreviewVehicleEvents;
