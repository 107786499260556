import { useState } from "react";

import { useCustomLocalStorageState } from "shared/hooks";

import Button from "features/ui/Button";
import FilterBuilder from "features/ui/Filters/FilterBuilder";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  areFilterStatesEqual,
  isAdvancedFilterState,
  isDefaultAdvancedFilterState,
  isFilterBuilderStateValid,
  updateGroupAnyAllByID,
} from "features/ui/Filters/FilterBuilder/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import {
  getUpdatedDeletedAttributes,
  resetFilters,
} from "features/ui/Filters/utils";
import InfoIcon from "features/ui/Icons/Info/Info";
import { SchemaEntry } from "features/ui/Table";

import BasicFilters from "./BasicFilters";
import { ADVANCED_EDITOR_NAME, BASIC_EDITOR_NAME } from "./constants";

const TITLE = "Select filter attributes";

interface Props {
  schema: SchemaEntry[];
  filterSortState: UseFilterSortState;
  title?: string;
  testId: string;
  pendingFiltersKey: string;
  defaultFilters?: FilterGroupState;
  baseEntityText?: string;
  initialIsAdvancedFilter?: boolean;
  onCloseFilters?: () => void;
}

const FilterSelector = ({
  schema,
  filterSortState,
  title = TITLE,
  testId,
  pendingFiltersKey,
  defaultFilters,
  baseEntityText,
  initialIsAdvancedFilter,
  onCloseFilters,
}: Props) => {
  const isAdvancedEditor =
    initialIsAdvancedFilter || filterSortState.isAdvancedFilterEditor;

  const filters = filterSortState.filters;

  const [filterBuilderState, setFilterBuilderState] =
    useState<FilterGroupState>(filters);

  const TOGGLE_BUTTON_TEXT = `Use ${
    isAdvancedEditor ? BASIC_EDITOR_NAME : ADVANCED_EDITOR_NAME
  } Editor`;

  const canTransferAdvancedToStandard = !isAdvancedFilterState(filters);
  const TOGGLE_ADVANCED_EDITOR_CTA_DISABLED = isAdvancedEditor
    ? !canTransferAdvancedToStandard
    : false;

  const advancedEditorInfoIconText = canTransferAdvancedToStandard
    ? "By switching to Standard editor you will lose any unapplied changes made in the Advanced editor."
    : "You cannot switch back to Standard editor because your filters are not compatible with it. Please adjust or clear/reset the filters first.";

  const TOGGLE_BUTTON_INFO_TEXT = isAdvancedEditor
    ? advancedEditorInfoIconText
    : "By switching to Advanced editor you will lose any unapplied changes made in the Standard editor.";

  const toggleAdvancedEditor = () => {
    const newIsAdvanced = !isAdvancedEditor;

    // clear pending filters when switching to advanced editor + make sure to propagate current filters from filterSortState to advanced editor
    if (newIsAdvanced) {
      clearPendingFilters();
      setFilterBuilderState(filterSortState.filters);
    } else {
      // if we can transfer advanced to standard, set pending filters to current filters before switching
      // - but first make sure that top-level group anyAll is set to "all" (Standard filter only supports ALL - "AND" filter so we should switch to "AND")
      const modifiedFilters = updateGroupAnyAllByID(filters, "group-0", "all");

      if (canTransferAdvancedToStandard) {
        setPendingFilters(modifiedFilters);
      }

      // clear unapplied advanced filter state when switching to standard editor
      setFilterBuilderState(modifiedFilters);
    }

    filterSortState.setIsAdvancedFilterEditor(newIsAdvanced);

    // We want the Menu in which this FilterSelector lives in most cases to resize as it's internal content
    // becomes wider for advanced filter editor and it does so automatically on screen resize.
    // If there's a better way to do this, please update this.
    window.dispatchEvent(new Event("resize"));
  };

  const [pendingFilters, setPendingFilters] =
    useCustomLocalStorageState<FilterGroupState>(pendingFiltersKey, {
      defaultValue: filters,
    });

  const { updated: keysUpdatedOrAdded, deleted: keysRemoved } =
    getUpdatedDeletedAttributes(filters, pendingFilters);

  const applyCancelCTAsEnabledStandardEditor =
    keysUpdatedOrAdded.length + keysRemoved.length > 0;

  const applyCancelCTAsEnabledAdvancedEditor =
    !areFilterStatesEqual(filterBuilderState, filters) &&
    (isDefaultAdvancedFilterState(filterBuilderState) ||
      isFilterBuilderStateValid(filterBuilderState));

  const applyCancelCTAsEnabled = isAdvancedEditor
    ? applyCancelCTAsEnabledAdvancedEditor
    : applyCancelCTAsEnabledStandardEditor;

  const clearPendingFilters = (accessors?: string[]) => {
    if (!accessors || accessors.length === 0) {
      setPendingFilters(filters);
      return;
    }

    const newPendingFilters = resetFilters(
      pendingFilters,
      accessors,
      keysRemoved,
      filters
    );

    setPendingFilters(newPendingFilters);
  };

  const handleOnCancel = () => {
    if (isAdvancedEditor) {
      setFilterBuilderState(filters);
    } else {
      clearPendingFilters();
    }
    onCloseFilters && onCloseFilters();
  };

  const handleOnApply = () => {
    if (isAdvancedEditor) {
      filterSortState.updateFilters(
        filterBuilderState || DEFAULT_FILTER_BUILDER_STATE
      );
    } else {
      filterSortState.updateFilters(pendingFilters);
    }
    onCloseFilters && onCloseFilters();
  };

  return (
    <div
      className="flex flex-col w-full"
      data-testid={`${testId}-filter-selector`}
    >
      <div className="flex items-center justify-between text-sm py-2 px-3">
        <div className="flex items-center space-x-5">
          {title && <div>{title}</div>}
          <div>
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={toggleAdvancedEditor}
              disabled={TOGGLE_ADVANCED_EDITOR_CTA_DISABLED}
              testId="toggle-advanced-editor"
            >
              {TOGGLE_BUTTON_TEXT}
            </Button>
            <InfoIcon
              text={
                <div className="text-left text-viaduct-black">
                  {TOGGLE_BUTTON_INFO_TEXT}
                </div>
              }
            />
          </div>
        </div>
        <div className="ml-auto flex space-x-3">
          <Button
            testId="clear-pending-filters"
            color="secondary"
            variant="outlined"
            size="small"
            onClick={handleOnCancel}
            disabled={!applyCancelCTAsEnabled}
          >
            Cancel
          </Button>
          <Button
            testId="apply-pending-filters"
            color="primary"
            variant="contained"
            size="small"
            onClick={handleOnApply}
            disabled={!applyCancelCTAsEnabled}
          >
            Apply
          </Button>
        </div>
      </div>
      {isAdvancedEditor ? (
        <FilterBuilder
          filterBuilderState={filterBuilderState}
          filtersSchema={schema}
          defaultFilters={defaultFilters}
          onChange={setFilterBuilderState}
        />
      ) : (
        <BasicFilters
          filters={filters}
          pendingFilters={pendingFilters}
          pendingFilterKeysRemoved={keysRemoved}
          pendingFilterKeysUpdatedOrAdded={keysUpdatedOrAdded}
          schema={schema}
          filterSortState={filterSortState}
          defaultFilters={defaultFilters}
          baseEntityText={baseEntityText}
          onPendingFiltersChange={setPendingFilters}
          onPendingFiltersClear={clearPendingFilters}
          testId={testId}
        />
      )}
    </div>
  );
};

export default FilterSelector;
