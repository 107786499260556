import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { formatNumber } from "shared/utils";

import { ByVehicleAgeData } from "pages/types";
import { formatDateBasedOnGranularity } from "pages/utils";

interface ActiveLabelProps {
  activeLabel?: number | string;
  yAxisKey: string;
  yAxisLabel: string;
}

const ChartTooltip = ({
  active,
  payload,
  label,
  activeLabel,
  yAxisKey,
  yAxisLabel,
}: TooltipProps<ValueType, NameType> & ActiveLabelProps) => {
  if (!active || !activeLabel) return null;

  const data = payload?.[0]?.payload;
  if (!data) return null;

  let activeData = data[activeLabel];
  if (!activeData) return null;

  const { granularity, byVehicleAgeBirthdayValue, byVehicleAgeExposureValue } =
    data.byVehicleAgeData as ByVehicleAgeData;
  const date = formatDateBasedOnGranularity(label, granularity);

  return (
    <div className="flex flex-col bg-white shadow px-2 py-3 text-sm">
      <div>
        {byVehicleAgeBirthdayValue}: {date}
      </div>
      <div>
        {byVehicleAgeExposureValue}: {activeLabel}
      </div>
      <div>
        {yAxisLabel}: {formatNumber(activeData[yAxisKey] as number)}
      </div>
      <div>Vehicles: {activeData.numVehicles}</div>
    </div>
  );
};

export default ChartTooltip;
