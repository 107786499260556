import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";

export type YAxisVal = "IPTV" | "numInspections" | "numVehicles";

export const getTopContributorsChartYAxisOptions =
  (): DropdownSelectOption<YAxisVal>[] => {
    return [
      {
        id: "IPTV",
        value: "Incidents per thousand vehicles",
        label: "Incidents per thousand vehicles",
      },
      {
        id: "numInspections",
        value: "Total Inspections",
        label: "Total number of Inspections",
      },
      {
        id: "numVehicles",
        value: "Affected Vehicles",
        label: "Number of affected vehicles",
      },
    ].filter(Boolean) as DropdownSelectOption<YAxisVal>[];
  };
