import { Navigate, useParams } from "react-router-dom";

import MetabaseDashboard from "pages/MetabaseDashboardPage/MetabaseDashboard";

import * as config from "config/config";

type DashboardRouteParams = {
  id: string;
};

const Dashboard = () => {
  const { id } = useParams<DashboardRouteParams>();
  const { pages } = config.get();

  const dashboard = pages.dashboards?.dashboards.find((d) => d.id === id);

  if (!dashboard || !id) {
    return <Navigate to="/" replace />;
  }

  return (
    <MetabaseDashboard
      dashboardId={id!}
      title={dashboard.name}
      usesLegacyUrlEmbeedingService={false}
    />
  );
};
export default Dashboard;
