import { SingnalEventStudioState } from "pages/SignalEventStudio/hooks";

import { UseFilterSortState } from "features/ui/Filters/types";

import SignalEventPreviewVehicleEvents from "./SignalEventPreviewVehicleEvents";
import SignalEventPreviewVehicleSummary from "./SignalEventPreviewVehicleSummary";
import SignalEventPreviewVehicleTimeline from "./SignalEventPreviewVehicleTimeline";

interface Props {
  signalEventStudioState: SingnalEventStudioState;
  vehiclesFilterSortState: UseFilterSortState;
}

const SignalEventPreviewData = ({
  signalEventStudioState,
  vehiclesFilterSortState,
}: Props) => {
  return (
    <>
      <SignalEventPreviewVehicleSummary />
      <SignalEventPreviewVehicleEvents />
      <SignalEventPreviewVehicleTimeline />
    </>
  );
};

export default SignalEventPreviewData;
