import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { ClaimAssociatedSignalEvent } from "shared/api/claims/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import {
  BaseIssueRequest,
  getIssueClaimBreakdownByCalendarTimeURI,
  getIssueClaimBreakdownVehicleAgeURI,
  getIssueClaimByCalendarTimeURI,
  getIssueClaimsByVehicleAgeURI,
  getIssueClaimStatisticsURI,
  getIssueCumulativeSignalEventsByVehicleAgeURI,
  GetIssuePopulationByVehicleAgeRequest,
  GetIssuePopulationPaginatedRequest,
  GetIssuePopulationRequest,
  GetIssuePopulationWithGroupByAndByVehicleAgeRequest,
  GetIssuePopulationWithGroupByRequest,
  getIssueRelationshipAssociatedSignalEventsURI,
  getIssueRelationshipByCalendarTimeTimelineURI,
  getIssueRelationshipByVehicleAgeTimelineURI,
  getIssueRelationshipStatisticsURI,
  getIssueRepairEfficacyReoccurrenceRateByAttributeURI,
  getIssueRepairEfficacyReoccurrenceRateByClaimAttributeURI,
  getIssueRepairEfficacyReoccurrenceRateByPopulationURI,
  getIssueRepairEfficacyStatisticsURI,
  getIssueRepairEfficacyVINTimelineURI,
  GetIssueRequest,
  getIssueRequestURI,
  getIssueSignalEventByVehicleAgeURI,
  getIssueSignalEventsByCalendarTimeURI,
  getIssueSignalEventStatisticsURI,
  getIssueSignalEventTopSignalEventsURI,
  GetIssuesOverviewRequest,
  getIssuesOverviewRequestURI,
  GetSuggestedIssuesOverviewRequest,
  getSuggestedIssuesOverviewRequestURI,
  Issue,
  IssueClaim,
  IssueClaimBreakdownByCalendarTime,
  IssueClaimBreakdownByVehicleAge,
  IssueClaimByCalendarTime,
  IssueClaimByVehicleAge,
  IssueClaimStatistics,
  IssueCumulativeSignalEventsByVehicleAge,
  IssueRelationshipByCalendarTimeTimeline,
  IssueRelationshipByVehicleAgeTimeline,
  IssueRelationshipStatistics,
  IssueRepairEfficacyReoccurrenceRateByAttribute,
  IssueRepairEfficacyReoccurrenceRateByClaimAttribute,
  IssueRepairEfficacyReoccurrenceRateByPopulation,
  IssueRepairEfficacyStatistics,
  IssueRepairEfficacyVINTimeline,
  IssueSignalEvent,
  IssueSignalEventByVehicleAge,
  IssueSignalEventsByCalendarTime,
  IssueSignalEventStatistics,
  IssueSignalEventTopSignalEvents,
  IssuesOverview,
  ListIssueClaimsCountRequest,
  listIssueClaimsCountURI,
  ListIssueClaimsRequest,
  listIssueClaimsURI,
  listIssuesAttributesRequestURI,
  listIssuesCountRequestURI,
  listIssueSignalEventsCountURI,
  listIssueSignalEventsURI,
  ListIssuesRequest,
  listIssuesRequestURI,
  SuggestedIssuesOverview,
} from "./api";

export const useIssue = (args: GetIssueRequest) =>
  useAPI<typeof args, Issue>(getIssueRequestURI, args);

export const useListIssues = (args: ListIssuesRequest) =>
  usePaginatedAPI<typeof args, Issue[]>(listIssuesRequestURI, args);

export const useListIssuesCount = (args: ListIssuesRequest) =>
  useAPI<typeof args, CountResponse>(listIssuesCountRequestURI, args);

export const useListIssueClaims = (args: ListIssueClaimsRequest) =>
  usePaginatedAPI<typeof args, IssueClaim[]>(listIssueClaimsURI, args);

export const useListIssueClaimsCount = (args: ListIssueClaimsCountRequest) =>
  useAPI<typeof args, CountResponse>(listIssueClaimsCountURI, args);

export const useListIssueSignalEvents = (args: BaseIssueRequest) =>
  usePaginatedAPI<typeof args, IssueSignalEvent[]>(
    listIssueSignalEventsURI,
    args
  );

export const useListIssueSignalEventsCount = (args: BaseIssueRequest) =>
  useAPI<typeof args, CountResponse>(listIssueSignalEventsCountURI, args);

export const useGetIssueClaimStatistics = (args: GetIssuePopulationRequest) =>
  useAPI<typeof args, IssueClaimStatistics>(getIssueClaimStatisticsURI, args);

export const useGetIssueSignalEventsStatistics = (
  args: GetIssuePopulationRequest
) =>
  useAPI<typeof args, IssueSignalEventStatistics>(
    getIssueSignalEventStatisticsURI,
    args
  );

export const useGetIssueRelationshipStatistics = (
  args: GetIssuePopulationRequest
) =>
  useAPI<typeof args, IssueRelationshipStatistics>(
    getIssueRelationshipStatisticsURI,
    args
  );

export const useGetIssueClaimsByVehicleAge = (
  args: GetIssuePopulationByVehicleAgeRequest
) =>
  useAPI<typeof args, IssueClaimByVehicleAge[]>(
    getIssueClaimsByVehicleAgeURI,
    args
  );

export const useGetIssueSignalEventByVehicleAge = (
  args: GetIssuePopulationByVehicleAgeRequest
) =>
  useAPI<typeof args, IssueSignalEventByVehicleAge[]>(
    getIssueSignalEventByVehicleAgeURI,
    args
  );

export const useGetIssueSignalEventTopSignalEvents = (
  args: GetIssuePopulationRequest
) =>
  useAPI<typeof args, IssueSignalEventTopSignalEvents[]>(
    getIssueSignalEventTopSignalEventsURI,
    args
  );

export const useGetIssueClaimByCalendarTime = (
  args: GetIssuePopulationRequest
) =>
  useAPI<typeof args, IssueClaimByCalendarTime>(
    getIssueClaimByCalendarTimeURI,
    args
  );

export const useGetIssueClaimBreakdownByCalendarTime = (
  args: GetIssuePopulationWithGroupByRequest
) =>
  useAPI<typeof args, IssueClaimBreakdownByCalendarTime[]>(
    getIssueClaimBreakdownByCalendarTimeURI,
    args
  );

export const useGetIssueClaimBreakdownByVehicleAge = (
  args: GetIssuePopulationWithGroupByAndByVehicleAgeRequest
) =>
  useAPI<typeof args, IssueClaimBreakdownByVehicleAge[]>(
    getIssueClaimBreakdownVehicleAgeURI,
    args
  );

export const useGetIssueRelationshipByVehicleAgeTimeline = (
  args: GetIssuePopulationByVehicleAgeRequest
) =>
  useAPI<typeof args, IssueRelationshipByVehicleAgeTimeline[]>(
    getIssueRelationshipByVehicleAgeTimelineURI,
    args
  );

export const useGetIssueRelationshipByCalendarTimeTimeline = (
  args: GetIssuePopulationRequest
) =>
  useAPI<typeof args, IssueRelationshipByCalendarTimeTimeline[]>(
    getIssueRelationshipByCalendarTimeTimelineURI,
    args
  );

export const useGetIssueSignalEventsByCalendarTime = (
  args: GetIssuePopulationRequest
) =>
  useAPI<typeof args, IssueSignalEventsByCalendarTime[]>(
    getIssueSignalEventsByCalendarTimeURI,
    args
  );

export const useGetIssueCumulativeSignalEventsByVehicleAge = (
  args: GetIssuePopulationByVehicleAgeRequest
) =>
  useAPI<typeof args, IssueCumulativeSignalEventsByVehicleAge[]>(
    getIssueCumulativeSignalEventsByVehicleAgeURI,
    args
  );

export const useGetIssueRepairEfficacy = (args: GetIssuePopulationRequest) =>
  useAPI<typeof args, IssueRepairEfficacyStatistics>(
    getIssueRepairEfficacyStatisticsURI,
    args
  );

export const useGetIssueRepairEfficacyVINTimeline = (
  args: GetIssuePopulationRequest
) =>
  useAPI<typeof args, IssueRepairEfficacyVINTimeline[]>(
    getIssueRepairEfficacyVINTimelineURI,
    args
  );

export const useGetIssueRepairEfficacyReoccurrenceRateByClaimAttribute = (
  args: GetIssuePopulationWithGroupByRequest
) =>
  useAPI<typeof args, IssueRepairEfficacyReoccurrenceRateByClaimAttribute[]>(
    getIssueRepairEfficacyReoccurrenceRateByClaimAttributeURI,
    args
  );

export const useGetIssueRepairEfficacyReoccurrenceRateByPopulation = (
  args: GetIssuePopulationRequest
) =>
  useAPI<typeof args, IssueRepairEfficacyReoccurrenceRateByPopulation[]>(
    getIssueRepairEfficacyReoccurrenceRateByPopulationURI,
    args
  );

export const useGetIssueRepairEfficacyReoccurrenceRateByAttribute = (
  args: GetIssuePopulationWithGroupByRequest
) =>
  useAPI<typeof args, IssueRepairEfficacyReoccurrenceRateByAttribute[]>(
    getIssueRepairEfficacyReoccurrenceRateByAttributeURI,
    args
  );

export const useIssueRelationshipAssociatedSignalEvents = (
  args: GetIssuePopulationPaginatedRequest
) =>
  usePaginatedAPI<typeof args, ClaimAssociatedSignalEvent[]>(
    getIssueRelationshipAssociatedSignalEventsURI,
    args
  );

export const useIssuesAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(listIssuesAttributesRequestURI, args);

export const useIssuesOverview = (args: GetIssuesOverviewRequest) =>
  useAPI<typeof args, IssuesOverview[]>(getIssuesOverviewRequestURI, args);

export const useSuggestedIssuesOverview = (
  args: GetSuggestedIssuesOverviewRequest
) =>
  useAPI<typeof args, SuggestedIssuesOverview[]>(
    getSuggestedIssuesOverviewRequestURI,
    args
  );
