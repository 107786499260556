import { useMemo } from "react";

import CardHeader from "pages/Issues/Details/CardHeader";

import Card from "features/ui/Card";

interface WithJSON {
  data: Record<string, string | undefined>;
}

export interface Props {
  title: string;
  data: WithJSON;
}

const DEFAULT_JSON_FIELD = "data";

const CardWithJSONData = ({ title, data }: Props) => {
  const jsonData = useMemo(() => {
    return data?.[DEFAULT_JSON_FIELD] ?? null;
  }, [data]);

  if (!jsonData) return null;

  return (
    <Card>
      <CardHeader title={title} showActions={false} />
      {Object.keys(jsonData).map((key) => (
        <div key={key} className="flex space-x-3 border-b py-1 text-sm">
          <div className="w-1/2 md:text-right text-gray-500">{key}:</div>
          <div className="break-words w-1/2 text-left">{jsonData[key]}</div>
        </div>
      ))}
    </Card>
  );
};

export default CardWithJSONData;
