import { useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import { Group } from "shared/api/rbac/api";
import { useListGroups, useListGroupsCount } from "shared/api/rbac/hooks";
import { getSortFilter } from "shared/api/utils";
import { SHORT_DATE_FORMAT } from "shared/constants";
import {
  CREATED_AT_FILTER,
  GROUPS_GENERIC_FILTER,
  UPDATED_AT_FILTER,
} from "shared/filterDefinitions";
import { SortBy } from "shared/types";
import { formatDate } from "shared/utils";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell";
import TableCount from "features/ui/Table/TableCount";
import Title from "features/ui/Title";

import { routes } from "services/routes";

import CreateGroup from "./CreateGroup/CreateGroup";

const formatRow = (group: Group) => {
  const { ID, name, createdAt } = group;

  return {
    ...group,
    name: (
      <Link
        to={{
          pathname: generatePath(routes.group, {
            id: encodeURIComponent(ID),
          }),
        }}
        className="text-metabase-blue hover:underline"
      >
        {name}
      </Link>
    ),
    createdAt: formatDate(createdAt, SHORT_DATE_FORMAT, true),
  };
};

const ROWS_PER_PAGE = 10;
const PAGE_KEY = "groups";

const DEFAULT_SORT: SortBy = { name: "asc" };

const schema: SchemaEntry[] = [
  {
    label: "Group Name",
    accessor: "name",
    dataType: DataType.JSX,
    sortable: true,
    filter: GROUPS_GENERIC_FILTER({
      label: "Name",
      fieldName: "name",
    }),
  },
  {
    label: "Description",
    accessor: "description",
    dataType: DataType.STRING,
    limitedWidthClass: "max-w-xs",
    sortable: true,
  },
  {
    label: "Updated",
    accessor: "updatedAt",
    dataType: DataType.DATE_WITH_TIME_UTC,
    sortable: true,
    filter: UPDATED_AT_FILTER,
  },

  {
    label: "Updated By",
    accessor: "updatedBy",
    dataType: DataType.STRING,
    sortable: true,
    filter: GROUPS_GENERIC_FILTER({
      label: "Updated By",
      fieldName: "updatedBy",
    }),
  },
  {
    label: "Created",
    accessor: "createdAt",
    dataType: DataType.DATE_WITH_TIME_UTC,
    sortable: true,
    filter: CREATED_AT_FILTER,
  },
  {
    label: "Created By",
    accessor: "createdBy",
    dataType: DataType.STRING,
    sortable: true,
    filter: GROUPS_GENERIC_FILTER({
      label: "Created By",
      fieldName: "createdBy",
    }),
  },
];

const Groups = () => {
  const {
    manageOnFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const { data, isLoading, headers, error, ...paginationData } = useListGroups({
    filter: getFiltersQuery(filters),
    sort: getSortFilter(sort),
    limit: ROWS_PER_PAGE,
  });

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListGroupsCount({ filter: getFiltersQuery(filters) });

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  return (
    <>
      <PageHeaderWrapper>
        <Title text="Groups" docsSectionId="groups" />
        <PageHeaderActionsWrapper>
          <CreateGroup action="create" />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <div className="flex items-center my-3">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="group"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageOnFilterChange}
          filters={filters}
          pageKey={PAGE_KEY}
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
    </>
  );
};

export default Groups;
