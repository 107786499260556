import { useContext } from "react";

import { VehicleAttributeContext } from "shared/contexts/VehicleAttributesContextWrapper";
import {
  TRANSPORT_CATEGORY_FILTER,
  VEHICLE_TAGS_GENERIC_FILTER,
  VEHICLES_GENERIC_FILTER,
} from "shared/filterDefinitions";

import { DataType } from "features/ui/Table/TableBodyCell";

import useDealersSchema from "./dealerSchema";
import { SchemaEntryOverride, SchemaRelations, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useVehiclesSchema = (accessorsToHide: string[] = []): UseSchema => {
  const attributes = useContext(VehicleAttributeContext);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const dealerSchema = useDealersSchema();

  const getFilter = getFilterFunction(VEHICLES_GENERIC_FILTER, attributeMap);

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const relations: SchemaRelations = {
    "/v1/dealers": dealerSchema,
  };

  const overrides: SchemaEntryOverride = {
    tags: {
      schemaOverride: {
        filter: VEHICLE_TAGS_GENERIC_FILTER({
          label: getDisplayLabel("tags", "Tags"),
          fieldName: "tags",
          filterType: "string",
          fieldNameForAPI: "ID",
          disableFiltering: true,
          search: true,
        }),
      },
    },
    predictions: {
      ignore: true,
    },
    transportCategoryID: {
      schemaOverride: {
        filter: TRANSPORT_CATEGORY_FILTER,
      },
    },
    vehicleModelYear: {
      schemaOverride: {
        dataType: DataType.STRING,
      },
    },
    engineModelYear: {
      schemaOverride: {
        dataType: DataType.STRING,
      },
    },
  };

  const schema = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides,
    relations,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaForAccessor,
    getSchemaEntry,
  };
};

export default useVehiclesSchema;
