import { PermissionID } from "shared/types";

import Select from "features/ui/Select";

import { GROUP_MEMER_PERMISSION_TYPES, isHigherPermission } from "./utils";

const DESCRIPTION = "Control permissions for members of the group.";

interface Props {
  access: PermissionID;
  generalAccess: PermissionID;
  onUpdateAccess: (access: PermissionID) => void;
}

const MemberPermission = ({ access, generalAccess, onUpdateAccess }: Props) => {
  // Membership access options should be same as generalAccess or higher
  const groupMemberPermissionTypes = GROUP_MEMER_PERMISSION_TYPES.filter(
    (x) => !isHigherPermission(generalAccess, x.id as PermissionID)
  );

  return (
    <div className="flex flex-col">
      <Select
        label="Member Access"
        options={groupMemberPermissionTypes}
        selected={
          groupMemberPermissionTypes.find(({ id }) => id === access) ||
          groupMemberPermissionTypes[0]
        }
        onSelect={(selectedOption) =>
          onUpdateAccess(selectedOption.id as PermissionID)
        }
        testId="member-permission-dropdown"
      />
      <p className="text-xs text-gray-500 ml-1">{DESCRIPTION}</p>
    </div>
  );
};

export default MemberPermission;
