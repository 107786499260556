import Skeleton from "react-loading-skeleton";

import { TestProps } from "shared/types";

import Card from "features/ui/Card";

export interface MetricCardProps extends TestProps {
  title: string;
  value?: number | string;
  isLoading?: boolean;
  subtitle?: string;
}

const MetricCard = ({
  title,
  value,
  isLoading,
  subtitle,
  testId = "metric-card",
}: MetricCardProps) => {
  if (isLoading) {
    return <Skeleton height={100} width={210} />;
  }

  if (value === undefined) {
    return null;
  }

  return (
    <Card key={`${title}-${value}`} classNames="text-center" testId={testId}>
      <div
        className="text-3xl font-semibold mb-1 whitespace-nowrap"
        data-testid={`${testId}-value`}
      >
        {value}
      </div>
      <div className="text-gray-500 text-xl" data-testid={`${testId}-title`}>
        {title}
      </div>
      {subtitle && <div className="text-gray-400 text-sm">{subtitle}</div>}
    </Card>
  );
};

export default MetricCard;
