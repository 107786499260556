import { useContext } from "react";

import { SensorReadingAttributeContext } from "shared/contexts/SensorReadingAttributesContextWrapper";
import {
  SENSOR_ID_FILTER,
  SENSOR_READINGS_GENERIC_FILTER,
  VEHICLES_GENERIC_FILTER,
} from "shared/filterDefinitions";

import { SchemaEntry } from "features/ui/Table";

import { SchemaOverride, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useSensorReadingsSchema = (accessorsToHide: string[] = []): UseSchema => {
  const attributes = useContext(SensorReadingAttributeContext);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(
    SENSOR_READINGS_GENERIC_FILTER,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);
  const overrides: Record<string, SchemaOverride> = {
    VIN: {
      schemaOverride: {
        filter: VEHICLES_GENERIC_FILTER({
          fieldName: "VIN",
          label: getDisplayLabel("VIN", "VIN"),
          search: true,
          disableFiltering: true,
        }),
      },
    },
    sensorID: {
      schemaOverride: {
        filter: SENSOR_ID_FILTER({
          fieldName: "sensorID",
          fieldNameForAPI: "ID",
          label: getDisplayLabel("sensorID", "Sensor ID"),
          search: true,
        }),
      },
    },
  };

  const schema: SchemaEntry<string>[] = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    overrides,
    undefined,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    whitelist: Object.keys(attributeMap || {}),
    getSchemaForAccessor,
    getSchemaEntry,
  };
};
export default useSensorReadingsSchema;
