import classNames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";

interface Props {
  uniqueExposureValues: Set<number>;
  label: string;
  stickyFirstColumn?: boolean;
}

const NUMBER_OF_NON_EXPOSURE_COLUMNS = 1;
const DEFAULT_EXPOSURE_COLSPAN = 4;

const MISColumnHeaders = ({
  uniqueExposureValues,
  label,
  stickyFirstColumn = false,
}: Props) => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();

  const EXPOSURE_COLSPAN = warrantyClaimsCostFF
    ? DEFAULT_EXPOSURE_COLSPAN
    : DEFAULT_EXPOSURE_COLSPAN - 1;

  return (
    <>
      <th
        colSpan={NUMBER_OF_NON_EXPOSURE_COLUMNS}
        className={classNames({
          "sticky left-0 bg-white z-10": stickyFirstColumn,
        })}
      ></th>
      {Array.from(uniqueExposureValues).map((exposureBucket, index) => {
        return (
          <th
            key={exposureBucket + index}
            colSpan={EXPOSURE_COLSPAN}
            className="p-2 font-semibold text-gray-500 tracking-wider uppercase whitespace-nowrap cursor-default "
            style={{ fontSize: "0.6rem" }}
          >
            {label} {exposureBucket}
          </th>
        );
      })}
    </>
  );
};

export default MISColumnHeaders;
