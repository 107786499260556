import { useCustomLocalStorageState } from "shared/hooks";
import { IssueTypes } from "shared/types";

import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import IssueClaimsTab from "./IssueClaimsTab";
import IssueRelationshipTab from "./IssueRelationshipTab";
import IssueRepairEfficacyTab from "./IssueRepairEfficacyTab";
import IssueSignalEventsTab from "./IssueSignalEventsTab";
import { isSuggestedIssue } from "./utils";

const ISSUE_CLAIMS_TAB = "issue_claims_tab";
const ISSUE_SIGNAL_EVENTS_TAB = "issue_signal_events_tab";
const ISSUE_RELATIONSHIP_TAB = "issue_relationship_tab";
const ISSUE_REPAIR_EFFICACY_TAB = "issue_repair_efficacy_tab";
const WITH_COMPARISON_POPULATION_KEY =
  "issuesWithComparisonPopulationSelection";

interface Props {
  issue: IssueTypes;
}

const IssueTabs = ({ issue }: Props) => {
  const [withComparisonPopulation, setWithComparisonPopulation] =
    useCustomLocalStorageState(WITH_COMPARISON_POPULATION_KEY, {
      defaultValue: false,
    });

  const {
    comparisonPopulationFilter,
    claimFilter,
    signalEventOccurrencesFilter,
  } = issue;

  const claimsTabDisabled = !Boolean(claimFilter);
  const signalEventsTabDisabled = !Boolean(signalEventOccurrencesFilter);
  const relationshipsTabDisabled = claimsTabDisabled || signalEventsTabDisabled;
  const repairEfficacyTabDisabled = relationshipsTabDisabled;

  const suggestedIssue = isSuggestedIssue(issue);

  const claimsTabNoCriteriaText = suggestedIssue
    ? "Claims criteria must be defined to enable claim analysis"
    : "Add claims criteria to enable claim analysis";

  const signalEventsTabNoCriteriaText = suggestedIssue
    ? "Signal events criteria must be defined to enable signal event analysis"
    : "Add signal event criteria to enable signal event analysis";

  let missingCriteria = "";
  if (claimsTabDisabled) {
    missingCriteria = "Claims criteria";
  }
  if (signalEventsTabDisabled) {
    missingCriteria = "Signal event criteria";
  }
  if (claimsTabDisabled && signalEventsTabDisabled) {
    missingCriteria = "Both claims criteria and signal events";
  }
  const relationshipTabNoCriteriaText = `${missingCriteria} must be defined to enable relationship analysis`;
  const repairEfficacyTabNoCriteriaText = `${missingCriteria} must be defined to enable repair efficacy analysis`;

  const onToggleWithComparisonPopulation = () =>
    setWithComparisonPopulation(!withComparisonPopulation);

  const showWithComparisonPopulation =
    withComparisonPopulation && Boolean(comparisonPopulationFilter);

  const commonProps = {
    issue,
    withComparisonPopulation: showWithComparisonPopulation,
    onToggleWithComparisonPopulation,
  };

  const tabsItems: (Tab | undefined | boolean)[] = [
    {
      key: ISSUE_CLAIMS_TAB,
      title: "Claim Analysis",
      content: <IssueClaimsTab {...commonProps} />,
      testId: ISSUE_CLAIMS_TAB,
      disabled: claimsTabDisabled,
      disabledText: claimsTabNoCriteriaText,
    },
    {
      key: ISSUE_SIGNAL_EVENTS_TAB,
      title: "Signal Event Analysis",
      content: <IssueSignalEventsTab {...commonProps} />,
      testId: ISSUE_SIGNAL_EVENTS_TAB,
      disabled: signalEventsTabDisabled,
      disabledText: signalEventsTabNoCriteriaText,
    },
    {
      key: ISSUE_RELATIONSHIP_TAB,
      title: "Relationship Analysis",
      content: <IssueRelationshipTab {...commonProps} />,
      testId: ISSUE_RELATIONSHIP_TAB,
      disabled: relationshipsTabDisabled,
      disabledText: relationshipTabNoCriteriaText,
    },
    {
      key: ISSUE_REPAIR_EFFICACY_TAB,
      title: "Repair Efficacy",
      content: <IssueRepairEfficacyTab {...commonProps} />,
      testId: ISSUE_REPAIR_EFFICACY_TAB,
      disabled: repairEfficacyTabDisabled,
      disabledText: repairEfficacyTabNoCriteriaText,
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return <StatefulTabs tabs={tabs} tabPanelClassName="py-3" />;
};

export default IssueTabs;
