import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useServicePlanAttributes } from "shared/api/servicePlans/hooks";

export const ServicePlanAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const ServicePlanAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useServicePlanAttributes({});

  return (
    <ServicePlanAttributeContext.Provider value={data}>
      {children}
    </ServicePlanAttributeContext.Provider>
  );
};

export default ServicePlanAttributesContextWrapper;
