import Skeleton from "react-loading-skeleton";

import { useSignalEventsOccurrencesMetricsHistory } from "shared/api/signalEvents/hooks";
import { useCustomLocalStorageState } from "shared/hooks";

import { prepareMetricsHistoryDataByTimestamp } from "pages/ClaimAnalytics/tabPages/Claims/utils";
import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import {
  cumulativeAffectedVINs,
  cumulativeDPTV,
  cumulativeDPTVDV,
  cumulativeOccurrences,
  rollingAffectedVINs,
  rollingDPTV,
  rollingDPTVDV,
  rollingOccurrences,
  yAxis,
} from "features/ui/charts/actions";
import ChartActions, {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import LineChart from "features/ui/charts/LineChart";
import {
  getAxisLabel,
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { SelectOption } from "features/ui/Select";

import { ChartTooltip } from "./ChartTooltip";

const CHART_HEIGHT_PX = 400;
const CHART_OPTIONS_KEY = "signalEventsAnalyticsSignalEventsChartOptions";
const Y_AXIS_OPTIONS: SelectOption[] = [
  rollingDPTVDV,
  cumulativeDPTVDV,
  rollingDPTV,
  cumulativeDPTV,
  rollingOccurrences,
  cumulativeOccurrences,
  rollingAffectedVINs,
  cumulativeAffectedVINs,
];

const actions: ChartAction[] = [
  {
    id: "y",
    title: yAxis,
    type: "dropdownSelect",
    options: Y_AXIS_OPTIONS,
  },
];

const SignalEventsChart = ({
  signalEventsFilters,
  vehiclesFilters,
  onBadRequest,
}: SignalEventsFiltersProps) => {
  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_OPTIONS_KEY, { defaultValue: getDefaultActions(actions) });

  const { data, isLoading, error } = useSignalEventsOccurrencesMetricsHistory({
    signalEventOccurrencesFilter: getFiltersQuery(signalEventsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
  });

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={CHART_HEIGHT_PX} />
      </Card>
    );
  }

  if (!data) {
    return (
      <Card>
        <div className="text-gray-500">No data available</div>
      </Card>
    );
  }

  const formattedChartData = prepareMetricsHistoryDataByTimestamp(data);

  const yAxisKey = getSelectedOptionId(selectedOptions, "y");
  const yAxisLabel = getAxisLabel(actions, "y", yAxisKey);

  const chartLines = [
    {
      key: yAxisKey,
      label: yAxisLabel,
    },
  ];

  return (
    <Card>
      <ChartActionsWrap>
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      <LineChart
        enableZoom={true}
        height={400}
        width="100%"
        data={formattedChartData}
        xAxisKey="ts"
        yAxisLines={chartLines}
        xAxisLabel="Occurrence Date"
        tooltipProps={{
          content: (props: any) => (
            <ChartTooltip {...props} yAxisOptions={Y_AXIS_OPTIONS} />
          ),
        }}
      />
    </Card>
  );
};

export default SignalEventsChart;
