import { createContext } from "react";

import { EntityAttribute } from "shared/api/api";
import { useSignalEventsOccurrencesAttributes } from "shared/api/signalEvents/hooks";

export const SignalEventOccurrenceAttributeContext = createContext<
  EntityAttribute[] | undefined
>(undefined);

const SignalEventOccurrenceAttributesContextWrapper = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { data } = useSignalEventsOccurrencesAttributes({});

  return (
    <SignalEventOccurrenceAttributeContext.Provider value={data}>
      {children}
    </SignalEventOccurrenceAttributeContext.Provider>
  );
};

export default SignalEventOccurrenceAttributesContextWrapper;
