import ConfirmationModalIssueUpdate from "pages/ClaimAnalytics/ConfirmationModalIssueUpdate";
import BackToIssueBreadcrumb from "pages/Issues/BackToIssueBreadcrumb";
import {
  ISSUE_ID_QUERY_KEY,
  ISSUE_IS_SUGGESTED_QUERY_KEY,
  ISSUE_NAME_QUERY_KEY,
  ISSUE_RUN_DATE_QUERY_KEY,
} from "pages/Issues/constants";

import { filterStateToFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFiltersKey } from "features/ui/Filters/utils";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Title from "features/ui/Title";

import * as config from "config/config";

import {
  PAGE_TITLE,
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "./constants";
import SignalEventsAnalyticsFilters from "./SignalEventsAnalyticsFilters";
import SignalEventsAnalyticsTabs from "./SignalEventsAnalyticsTabs";

// Ideally this would be part of constants.ts, but we want to avoid possible circular dependencies
export const PENDING_FILTERS_SE_LS_KEY = getPendingFiltersKey(
  SIGNAL_EVENTS_PAGE_KEY
);

export const PENDING_FILTERS_VEHICLES_LS_KEY =
  getPendingFiltersKey(VEHICLES_PAGE_KEY);

const QUERY_PARAMS_TO_PERSIST_THROUGH_TABS = [
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
]
  .map(getFiltersKey)
  .concat([
    ISSUE_ID_QUERY_KEY,
    ISSUE_NAME_QUERY_KEY,
    ISSUE_IS_SUGGESTED_QUERY_KEY,
    ISSUE_RUN_DATE_QUERY_KEY,
  ]);

const SignalEventsAnalytics = () => {
  const {
    pages: { signalEventsAnalytics },
  } = config.get();

  const defaultSignalEventFilters = filterStateToFilterGroupState(
    signalEventsAnalytics?.defaultSignalEventFilters
  );
  const signalEventsFiltersFilterSortState = useFilterSortState({
    pageKey: SIGNAL_EVENTS_PAGE_KEY,
    defaultFilterValues: defaultSignalEventFilters,
    pendingFiltersLocalStorageKey: PENDING_FILTERS_SE_LS_KEY,
  });

  const defaultVehicleFilters = filterStateToFilterGroupState(
    signalEventsAnalytics?.defaultVehicleFilters
  );
  const vehiclePopulationFiltersFilterSortState = useFilterSortState({
    pageKey: VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters,
    pendingFiltersLocalStorageKey: PENDING_FILTERS_VEHICLES_LS_KEY,
  });

  const resetState = () => {
    vehiclePopulationFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <ConfirmationModalIssueUpdate
        page="signalEventAnalytics"
        vehicleFilters={vehiclePopulationFiltersFilterSortState.filters}
        signalEventFilters={signalEventsFiltersFilterSortState.filters}
      />
      <PageHeaderWrapper>
        <div className="flex flex-col">
          <BackToIssueBreadcrumb />
          <Title text={PAGE_TITLE} docsSectionId="signal-events-analytics" />
        </div>
      </PageHeaderWrapper>
      <SignalEventsAnalyticsFilters
        signalEventsFilterSortState={signalEventsFiltersFilterSortState}
        vehiclesFilterSortState={vehiclePopulationFiltersFilterSortState}
        defaultSignalEventFilters={defaultSignalEventFilters}
        defaultVehicleFilters={defaultVehicleFilters}
      />
      <SignalEventsAnalyticsTabs
        signalEventsFiltersFilterSortState={signalEventsFiltersFilterSortState}
        vehiclesFiltersFilterSortState={vehiclePopulationFiltersFilterSortState}
        queryParamsToPersist={QUERY_PARAMS_TO_PERSIST_THROUGH_TABS}
        onBadRequest={resetState}
      />
    </>
  );
};

export default SignalEventsAnalytics;
