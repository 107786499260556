import { useListVehiclesECUs } from "shared/api/hooks";
import { APIFilter, getSortFilter } from "shared/api/utils";
import useVehicleECUsSchema from "shared/schemas/vehicleECUsSchema";
import { SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";

interface Props {
  vin: string;
  staticFilters?: APIFilter[];
}

const ROWS_PER_PAGE = 15;
const DEFAULT_SORT: SortBy = { ECUID: "desc" };

const ECUs = ({ vin, staticFilters }: Props) => {
  const { schema } = useVehicleECUsSchema();
  const PAGE_KEY = `ecu_${vin}`;

  const {
    manageOnFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const { data, isLoading, headers, error, ...paginationData } =
    useListVehiclesECUs({
      filter: getFiltersQuery(filters, staticFilters),
      sort: getSortFilter(sort),
      limit: ROWS_PER_PAGE,
    });

  return (
    <>
      <div className="flex items-center my-3">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={data}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageOnFilterChange}
          filters={filters}
          staticFilters={staticFilters}
          pageKey={PAGE_KEY}
        />
      )}
      {/* We get empty [] back when using filters and there's no results */}
      {(!data || !data.length) && !isLoading && !error && (
        <div className="py-4 text-gray-400 text-sm">No ECUs found.</div>
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
    </>
  );
};
export default ECUs;
