import { useFlags } from "launchdarkly-react-client-sdk";
import Skeleton from "react-loading-skeleton";

import { ClaimVehicleAgeTimeline } from "shared/api/claims/api";
import { useListClaimsByVehicleAgeTimeline } from "shared/api/claims/hooks";
import { NO_DATA } from "shared/constants";

import { ClaimsByVehicleAgeChartProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import {
  generateByVehicleAgeYAxisLines,
  prepareByVehicleAgeDataByTimestamp,
} from "pages/utils";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/ChartActionsWrap";
import LineChart from "features/ui/charts/LineChart";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

import ChartTooltip from "./ChartTooltip";

const ByVehicleAgeChart = ({
  claimsFilters,
  vehiclesFilters,
  onBadRequest,
  actions,
  byVehicleAgeData,
  selectedOptions,
  setSelectedOptions,
}: ClaimsByVehicleAgeChartProps) => {
  const { warrantyClaimsCost } = useFlags();

  const {
    byVehicleAgeBirthday,
    byVehicleAgeBirthdayValue,
    byVehicleAgeExposure,
    granularity,
    yAxisValue,
  } = byVehicleAgeData;

  const { data, isLoading, error } = useListClaimsByVehicleAgeTimeline({
    claimsFilter: getFiltersQuery(claimsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    byVehicleAgeBirthday,
    byVehicleAgeExposure,
    granularity,
    hideCosts: !warrantyClaimsCost,
  });

  if (isLoading) {
    return (
      <Card>
        <Skeleton height={400} width="100%" />
      </Card>
    );
  }

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  if (!data) return NO_DATA;

  const chartData = prepareByVehicleAgeDataByTimestamp<ClaimVehicleAgeTimeline>(
    data,
    byVehicleAgeData
  );
  const yAxisLines = generateByVehicleAgeYAxisLines<ClaimVehicleAgeTimeline>(
    data,
    byVehicleAgeData
  );

  const chartTitle = `${yAxisValue} by ${byVehicleAgeBirthdayValue}`;

  return (
    <Card>
      <ChartActionsWrap chartTitle={chartTitle} contentClassName="w-[350px]">
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      {data.length === 0 && NO_DATA}
      {data.length > 0 && (
        <LineChart
          height={400}
          width="100%"
          data={chartData}
          xAxisKey="ts"
          yAxisLines={yAxisLines}
          yAxisLabel={yAxisValue}
          xAxisLabel={byVehicleAgeBirthdayValue}
          connectNulls={true}
          tooltipProps={{
            content: ChartTooltip,
          }}
          toggleableLines={true}
        />
      )}
    </Card>
  );
};

export default ByVehicleAgeChart;
