import { Issue } from "./api/issues/api";
import { SuggestedIssue } from "./api/suggestedIssues/api";

export interface TestProps {
  testId?: string;
}

export interface ReoccurrenceProps {
  seOccurrenceWithin: number;
  seReoccurrenceStartingFrom: number;
  seReoccurrenceUpTo: number;
}

export interface JWT {
  [x: string]: string | number | boolean | string[] | number[];
}

export type PermissionID = "none" | "read" | "edit";
export type PermissionType = "user" | "group";
export type EntityWithPermissions =
  | "servicePlan"
  | "collection"
  | "claimCollection"
  | "issue"
  | "group"
  | "alertDefinition";

export type ChartActionID =
  | "x"
  | "y"
  | "legend"
  | "MIS"
  | "signalEvents"
  | "granularity"
  | "exposure"
  | "exposureBucket"
  | "groupBy"
  | "bucketBy"
  | "splitByIssueSource"
  | "measure";
export type ChartActionType =
  | "dropdownSelect"
  | "label"
  | "attrNameValue"
  | "boolean";

export type IssueTypes = Issue | SuggestedIssue;
export type IssueVehiclePopulation = "atRisk" | "comparison";
export type IssueClaimGroupBy =
  | "failedPartNumber"
  | "laborCode"
  | "mentionedSignalEvents";

export type IssueChartType = "VehicleAge" | "CalendarTime" | "DaysSinceClaim";

// chart names are defined as "<tab-on-issue-details-page>_<type-of-chart>"
export type IssueChart =
  // Claims Tab
  | "Claims_OccurrencesByCalendarTime"
  | "Claims_OccurrencesByVehicleAge"
  | "Claims_TopXByCalendarTime"
  | "Claims_TopXByVehicleAge"
  // Signal Events Tab
  | "SignalEvents_OccurrencesByCalendarTime"
  | "SignalEvents_OccurrencesByVehicleAge"
  | "SignalEvents_RateByCalendarTime"
  | "SignalEvents_RateByVehicleAge"
  // Relationships Tab
  | "Relationships_CombinedOccurrencesByCalendarTime"
  | "Relationships_CombinedOccurrencesByVehicleAge"
  | "Relationships_AssociatedSignalEvents"
  // Repair Efficacy Tab
  | "RepairEfficacy_ReoccurrenceByAttribute"
  | "RepairEfficacy_ReoccurrenceByPopulation"
  | "RepairEfficacy_ReoccurrenceBar";

export type IssueChartGroupBy = "signalEventID" | "groupByAttributeValue";

export type AccordionArrowPosition = "left" | "right";

export type MileageUnit = "mi" | "km";

export type Sort = "asc" | "desc";
export type SortBy = Record<string, Sort>;

export enum EventTypeEnum {
  SIGNAL_EVENT = "SignalEvent",
  CLAIM = "Claim",
  SENSOR_READING = "SensorReading",
  INSPECTION = "Inspection",
  REPAIR = "Repair",
}

export type GroupByIssuesEnum =
  | "status"
  | "severity"
  | "age"
  | "assigned_group"
  | "assignee"
  | "created_by";

export type BucketByIssuesEnum =
  | "assigned_group"
  | "severity"
  | "assignee"
  | "created_by"
  | "age"
  | "none";

export type GroupBySuggestedIssuesEnum = "status" | "assigned_group" | "age";

export type BucketBySuggestedIssuesEnum =
  | "status"
  | "assigned_group"
  | "age"
  | "none";
