import { CustomSignalEventDefinition } from "shared/api/customSignalEvents/api";
import { toTitleCase } from "shared/utils";

import {
  ACTIVE_STATUS_LIST,
  ERROR_STATUSES,
} from "pages/CustomSignalEvents/constants";

interface Props {
  customSignalEventDefinition?: CustomSignalEventDefinition;
}

const DISABLED_COLOR = "text-gray-400";
const ERROR_COLOR = "text-red-600";
const BASE_COLOR = "text-viaduct-black";

const StatusSection = ({ customSignalEventDefinition }: Props) => {
  const cseStatus = customSignalEventDefinition?.status || "";

  return (
    <div className="h-full pt-2 text-sm">
      <>
        {ACTIVE_STATUS_LIST.map((status, idx) => {
          const statusColor = ERROR_STATUSES.includes(status)
            ? ERROR_COLOR
            : BASE_COLOR;
          const textColor = status === cseStatus ? statusColor : DISABLED_COLOR;
          return (
            <>
              <span className={textColor}>{toTitleCase(status)}</span>{" "}
              {idx !== ACTIVE_STATUS_LIST.length - 1 && (
                <span className={DISABLED_COLOR}>{" > "}</span>
              )}
            </>
          );
        })}
      </>
    </div>
  );
};

export default StatusSection;
