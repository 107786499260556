import classNames from "classnames";
import { format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FiExternalLink as LinkIcon } from "react-icons/fi";
import Skeleton from "react-loading-skeleton";

import { useGetCustomRecord } from "shared/api/serviceRecords/hooks";
import { SHORT_DATE_FORMAT } from "shared/constants";
import { formatNumber, parseDateOnly } from "shared/utils";

import { RecordProps } from "./EventDetail";
import InformationWrapper from "./InformationWrapper";
import Notes from "./Notes";

const CustomRecordDetail = ({ serviceRecord, onClose }: RecordProps) => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();

  const { data, isLoading } = useGetCustomRecord({
    ID: serviceRecord.ID || "",
  });

  if (isLoading) {
    return <Skeleton count={3} />;
  }

  if (!data?.data) {
    return <div></div>;
  }

  const costInfo = {
    Cost:
      data.cost !== null &&
      `${formatNumber(data.cost, 2)} ${data.currencyCode}`,
  };

  const dataObj =
    typeof data.data === "object" ? data.data : { Data: data.data };

  const information: Record<
    string,
    number | string | boolean | undefined | JSX.Element
  > = {
    Type: data.type,
    Date: format(parseDateOnly(serviceRecord.date), SHORT_DATE_FORMAT),
    Label: data.label,
    Notes: <Notes notes={data.notes} />,
    Status: data.status,
    "Dealer Name": data?.dealer?.name,
    "Dealer Code": data?.dealerID,
    ...(warrantyClaimsCostFF && costInfo),
    ...dataObj,
  };

  return (
    <InformationWrapper
      externalID={serviceRecord.externalID || ""}
      onClose={onClose}
    >
      {Object.keys(information)
        .filter((item) => information[item])
        .map((key, i, { length }) => {
          return (
            <div
              key={key}
              className={classNames(
                "grid grid-cols-[180px_auto] gap-x-3 py-2",
                {
                  "border-b": i < length - 1,
                }
              )}
            >
              <span className="text-gray-400">{key}</span>
              <span className="break-words">{information[key]}</span>
            </div>
          );
        })}
      {serviceRecord.externalURL && (
        <a
          href={serviceRecord.externalURL}
          target="_blank"
          rel="noreferrer"
          className="border-t pt-2 text-blue-500 hover:text-blue-700 visited:text-blue-600"
        >
          <span className="align-middle">View More Details</span>
          <LinkIcon className="inline-block ml-1 align-middle" />
        </a>
      )}
    </InformationWrapper>
  );
};

export default CustomRecordDetail;
