import Button from "features/ui/Button";
import { getTopLevelRowFromFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { SIGNAL_EVENT_ID_FIELD_NAME } from "features/ui/Filters/FilterTypes/RelatesFilter/constants";
import { addRelatesFilterToSignalEventFilter } from "features/ui/Filters/FilterTypes/RelatesFilter/utils";
import {
  FilterOperator,
  RelatesFilterState,
  UseFilterSortState,
} from "features/ui/Filters/types";
import Label from "features/ui/Label";

import AddAsRelatesFilterAction from "./AddAsRelatesFilterAction";

interface Props {
  filterSortState?: UseFilterSortState;
  relatesFilter: RelatesFilterState;
  selectedSignalEvents: Set<string>;
  filterActionLabel?: string;
  onAddToFilter?: () => void;
  onExploreInSEAnalyticsActionClick?: () => void;
  onExploreInClaimAnalyticsActionClick?: () => void;
}

const EXPLORE_IN_CLAIM_ANALYTICS_LABEL = "Explore in Claim Analytics";
const EXPLORE_IN_SE_ANALYTICS_LABEL = "Explore in Signal Event Analytics";

const SelectedRowsActions = ({
  filterSortState,
  relatesFilter,
  selectedSignalEvents,
  onAddToFilter,
  filterActionLabel,
  onExploreInSEAnalyticsActionClick,
  onExploreInClaimAnalyticsActionClick,
}: Props) => {
  const filters = filterSortState?.filters;

  const relatedSEOccurrencesFilter = getTopLevelRowFromFilterGroupState(
    "relatedSignalEventOccurrences",
    filters
  );

  const onFilterActionClick = () => {
    const newRelatesFilter: RelatesFilterState = {
      operator: "occurs",
      // use options from relates filter row
      options: relatesFilter.options,
      filters: {
        id: "group-0",
        type: "group",
        anyAll: "all",
        children: [
          {
            id: "row-0",
            type: "row",
            attribute: SIGNAL_EVENT_ID_FIELD_NAME,
            operator: FilterOperator.IN,
            values: Array.from(selectedSignalEvents),
          },
        ],
      },
    };

    const updatedSignalEventFilter = addRelatesFilterToSignalEventFilter(
      relatedSEOccurrencesFilter,
      newRelatesFilter
    );

    filterSortState?.manageOnFilterChange({
      key: "relatedSignalEventOccurrences",
      op_id: FilterOperator.RELATES,
      relates: updatedSignalEventFilter.relates,
      values: updatedSignalEventFilter.values,
    });

    onAddToFilter && onAddToFilter();
  };

  const FiltersAction = () => (
    <AddAsRelatesFilterAction
      onClick={onFilterActionClick}
      label={filterActionLabel}
    />
  );

  if (selectedSignalEvents.size === 0) return null;

  return (
    <div data-testid="add-as-related-signal-event-div">
      <div className="flex items-center">
        <Label text="Selected Row Actions:" className="text-gray-500 !mb-0" />
        {onAddToFilter && <FiltersAction />}
        {onExploreInSEAnalyticsActionClick && (
          <Button
            label={EXPLORE_IN_SE_ANALYTICS_LABEL}
            variant="text"
            color="primary"
            testId="explore-in-signal-event-analytics-cta"
            onClick={onExploreInSEAnalyticsActionClick}
          />
        )}
        {onExploreInClaimAnalyticsActionClick && (
          <Button
            label={EXPLORE_IN_CLAIM_ANALYTICS_LABEL}
            variant="text"
            color="primary"
            testId="explore-in-claim-analytics-cta"
            onClick={onExploreInClaimAnalyticsActionClick}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedRowsActions;
