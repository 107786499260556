import classnames from "classnames";

import { RowData, SchemaEntry } from "features/ui/Table";
import { formatValue } from "features/ui/Table/TableBodyCell/TableBodyCell";
import { getValueByAccessor } from "features/ui/Table/utils";

interface Props<T> {
  schema: SchemaEntry<keyof T>[];
  data: T;
  fields: string[];
  hasNarratives?: boolean;
}

const LabelValuePairs = <T,>({
  schema,
  data,
  fields = [],
  hasNarratives = false,
}: Props<T>) => (
  <div>
    {fields.map((accessor) => {
      const schemaEntry = schema.find((entry) => entry.accessor === accessor);
      if (!schemaEntry) return null;
      const value = formatValue({
        value: getValueByAccessor(
          accessor as string,
          data as RowData
        ) as string,
        dataType: schemaEntry.dataType,
      });

      if (!value) return null;

      return (
        <div
          key={accessor as string}
          className={classnames({
            "flex space-x-3 border-b py-1 text-sm": !hasNarratives,
          })}
        >
          <div
            className={classnames(
              {
                "w-1/2 md:text-right": !hasNarratives,
              },
              "text-gray-500"
            )}
          >
            {schemaEntry.label}:
          </div>
          <div
            className={classnames("break-words", {
              "w-1/2 text-left": !hasNarratives,
              "text-sm max-h-36 overflow-y-auto mt-1 mb-3 border border-gray-300":
                hasNarratives,
              "px-3 py-2": hasNarratives && typeof value === "string",
            })}
          >
            {value}
          </div>
        </div>
      );
    })}
  </div>
);

export default LabelValuePairs;
